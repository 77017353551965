import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useTranslation } from 'react-i18next'

// import { Container } from './styles';

function ViewMore({ setIsExpand, isExpand, less, more }) {
    const { t } = useTranslation()
    const lessText = less ?? t('venue.showLess')
    const moreText = more ?? t('venue.showMore')
    return (
        <div
            className='w-24 flex items-center justify-between cursor-pointer gap-1'
            onClick={() => setIsExpand(!isExpand)}
        >
            <div className='text-sm font-semiboldFamily text-[#0000EE]'>{isExpand ? lessText : moreText}</div>
            <div className='text-secondary-midnightBlue'>
                {isExpand ? <div className='icon-arrow_up' /> : <div className='icon-arrow_down' />}
            </div>
        </div>
    )
}

export default ViewMore
