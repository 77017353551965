import React, { useState, useEffect, useMemo } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import BannerSwiper from 'components/detailComponents/BannerSwiper'
import TopIntro from '../components/TopIntro'
import { TabContainer } from 'components/TabContainer'
import Loading from 'components/Loading'
import Tags from 'page/EventsTickets/components/Tags'
import Content from 'components/Content'
import ExperienceHighlight from './ExperienceHighlight'
import { precinctTourService } from 'service/precinctTourService'
import { formatDateRange } from 'utils/util'

export default function Detail() {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id') + ''

    const query = {
        lang: language,
        programId: id,
    }
    const [isLoading, setIsLoading] = useState(false)

    const { data: items = {}, isPending } = useQuery({
        queryKey: ['getPrecinctToursDetail', query, language],
        queryFn: () => precinctTourService.getPrecinctToursDetail(query),
    })

    const { data: btnDatas } = useQuery({
        queryKey: ['getPrecinctToursStatus', language, id],
        queryFn: () => precinctTourService.getActivitiesStatus({ ids: id, lang: language }),
        placeholderData: keepPreviousData,
    })
    const btnConfig = btnDatas?.list[0] ?? btnDatas?.list
    const buttonStatus = {
        type: btnConfig?.enrlMethod || '',
        ctaButtonUrl: btnConfig?.actionLink || '',
        isFull: btnConfig?.actionLink === 'disable' || btnConfig?.actionLink === 'disabled',
        ballotLink: items?.ballotResult,
    }

    useEffect(() => {
        if (!!items) setIsLoading(false)
    }, [items])

    const topIntroData = useMemo(
        () => ({
            eventName: items.activityName,
            specialRemarkTitle: items.specialRemarkTitle,
            specialRemarkDescription: items.specialRemarkDescription,
            date: !!items.startDate && !!items.endDate && formatDateRange(items.startDate, items.endDate, language),
            time: !!items.startTime && !!items.endTime && `${items.startTime} - ${items.endTime}`,
            enquiries: items.phone,
            language: items.languages,
        }),
        [items],
    )

    const highlightList = useMemo(() => {
        return items?.experienceHighlightImages?.map((item) => item.image)
    }, [items.experienceHighlightImages])

    if (isPending || isLoading) return <Loading />
    if (items?.result === null) return null
    return (
        <div>
            {items && items?.images?.length > 0 && <BannerSwiper items={items.images} />}
            {items && (
                <>
                    <div>
                        <div className='bg-container'>
                            <div className='text-secondary-midnightBlue'>
                                <TopIntro items={topIntroData} btnDatas={buttonStatus} isShowBtn={!!btnConfig} />
                            </div>
                        </div>
                        <section>
                            <TabContainer
                                components={[
                                    items.overviewDescription && {
                                        key: 'expect',
                                        title: t('precinctTour.detail.about'),
                                        component: () => (
                                            <div className='bg-container text-secondary-midnightBlue'>
                                                <div className='max-w-[750px] pt-8 md:pt-[60px]'>
                                                    <Content data={items.overviewDescription} className='text-body1' />
                                                </div>
                                            </div>
                                        ),
                                    },
                                    highlightList.length > 0 && {
                                        key: 'experienceHighlight',
                                        title: t('precinctTour.detail.experienceHighlight'),
                                        component: () => (
                                            <div className='bg-container text-secondary-midnightBlue mt-[60px]'>
                                                <div className='text-[30px] font-[700] mb-6'>
                                                    {t('precinctTour.detail.experienceHighlight')}
                                                </div>
                                                <ExperienceHighlight highlightList={highlightList} />
                                            </div>
                                        ),
                                    },
                                ].filter((i) => i)}
                            />
                        </section>
                    </div>
                    {items?.tags && items?.tags?.length > 0 ? (
                        <div className='bg-container text-secondary-midnightBlue mb-8 pt-8'>
                            <div className='max-w-[750px] space-y-8 md:space-y-[60px]'>
                                {items?.tags && items?.tags?.length > 0 && <Tags items={items} />}
                            </div>
                        </div>
                    ) : (
                        <div className='pt-[60px]' />
                    )}
                </>
            )}
        </div>
    )
}
