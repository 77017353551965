import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { promotion } from 'service/promotionService'
import Banner from 'components/shopDine/Banner'
import proTop from 'resources/images/promotionsBanner.jpg'
import BannerSwiper from 'components/shopDine/BannerSwiper'
// import FeaturedShop from 'components/shopDine/FeaturedShop'
import AllPromotion from 'page/Promotions/components/AllPromotion'
function Promotions() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const banner = {
        image: proTop,
        title: t('promotion.title'),
        about: t('promotion.bannerAbout'),
    }
    const { data: bannerSwiperImgs } = useQuery({
        queryKey: ['promotionHeroBanner', language],
        queryFn: () => promotion.banner({ lang: language }),
    })
    return (
        <div className='bg-white'>
            <section>
                <Banner banner={banner} />
            </section>
            <section>
                {bannerSwiperImgs && bannerSwiperImgs.length > 0 && (
                    <BannerSwiper type='promotion' bannerSwiperImgs={bannerSwiperImgs} />
                )}
            </section>
            {/* <section>
                <FeaturedShop name='Featured Shop' />
            </section> */}
            <section>
                <AllPromotion
                    firstTypeName={t('promotion.lookingFor')}
                    secondTypeName={t('promotion.relatedTo')}
                    promotionTypeName={t('promotion.type')}
                    name={t('promotion.allPromotion')}
                    isFilter={true}
                />
            </section>
        </div>
    )
}

export default Promotions
