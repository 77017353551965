import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import PaginationButton from 'components/PaginationButton'
import { ticketServices } from 'service/ticketsService'
import Select from 'page/UserSettings/MyRecord/components/Select'
import MyRecordCard from 'page/UserSettings/MyRecord/components/MyRecordCard'
import NoDatas from 'page/UserSettings/MyRecord/components/NoDatas'
import { Box, Wrap } from 'page/UserSettings/components/CommonBox'
import Loading from 'components/Loading'
import ButtonsTab from 'components/ButtonsTab'
import Tickets from 'page/UserSettings/MyRecord/components/Tickets'
import MyBookings from 'page/UserSettings/MyRecord/components/MyBookings'
import MyProgrammes from 'page/UserSettings/MyRecord/components/MyProgrammes'
import { configDataStore } from 'store/authAtom'
function MyRecord() {
    const { configDataValue } = configDataStore()
    const { t } = useTranslation()
    const [name, setName] = useState({ name: 'Upcoming', val: 'upcoming' })
    const [value, setValue] = useState(name.val)
    const btnVals = [
        configDataValue && configDataValue?.ttlSwitch && { text: t('myProfile.myTicket'), val: 'myTicket' },
        configDataValue && configDataValue?.cspSwitch && { text: t('myProfile.programme'), val: 'myProgramme' },
        configDataValue && configDataValue?.cchSwitch && { text: t('myProfile.myBooking'), val: 'myBooking' },
        // { text: 'My order', val: 'myOrder' },
    ].filter((i) => i)
    const [type, setType] = useState('')
    const [isDragging, setIsDragging] = useState(false)
    const [startX, setStartX] = useState(0)
    const [scrollLeft, setScrollLeft] = useState(0)

    const handleDragStart = (e) => {
        setIsDragging(true)
        setStartX(e.pageX - e.currentTarget.offsetLeft)
        setScrollLeft(e.currentTarget.scrollLeft)
    }

    const handleDragEnd = () => {
        setIsDragging(false)
    }

    const handleDragMove = (e) => {
        if (!isDragging) return
        e.preventDefault()
        const x = e.pageX - e.currentTarget.offsetLeft
        const walk = (x - startX) * 3
        e.currentTarget.scrollLeft = scrollLeft - walk
    }
    useEffect(() => {
        if (configDataValue) {
            setType(
                configDataValue?.ttlSwitch
                    ? 'myTicket'
                    : configDataValue?.cspSwitch
                    ? 'myProgramme'
                    : configDataValue?.cchSwitch
                    ? 'myBooking'
                    : '',
            )
        }
    }, [configDataValue])
    return (
        <Wrap>
            <div className='text-4xl font-semiboldFamily uppercase'>{t('myProfile.myRecord')}</div>
            <Box>
                <div className='flex items-center justify-between gap-3'>
                    <ButtonsTab
                        className='w-3/5 lg:w-fit py-0 justify-start p-0 mb-0'
                        list={btnVals.map((item) => ({
                            ...item,
                            selected: type === item.val,
                        }))}
                        onItemClick={(btnVal) => {
                            setType(btnVal.val)
                            console.log(btnVal.val)
                        }}
                    />
                    <Select type={type} value={value} setValue={setValue} name={name} setName={setName} />
                </div>
                {type === 'myTicket' ? (
                    <Tickets name={name} value={value} />
                ) : type === 'myProgramme' ? (
                    <MyProgrammes name={name} value={value} />
                ) : type === 'myBooking' ? (
                    <MyBookings name={name} value={value} />
                ) : null}
            </Box>
            {/* <Box>
                <div className='flex justify-between items-center'>
                    <div className='text-secondary-midnightBlue'>
                        <div className='text-[22px] font-semiboldFamily leading-[26.4px]'>{t('myProfile.contact')}</div>
                        <div className='text-base font-medium'>{t('myProfile.addPlayer')}</div>
                    </div>
                    <Button
                        className='mt-2 text-white normal-case bg-website-gradation text-sm font-semiboldFamily rounded-[15px] px-4 py-1'
                        variant='contained'
                        size='large'
                    >
                        {t('myProfile.addUser')}
                        <ExpandMoreIcon />
                    </Button>
                </div>
            </Box> */}
        </Wrap>
    )
}

export default MyRecord
