import { set } from 'js-cookie'
import { create } from 'zustand'

/**
 * 用戶信息
 */
export const useUserInfoAtom = create((set) => ({
    profileInfo: null,
    setProfileInfo: (v) => set({ profileInfo: v }),
}))

export const configDataStore = create((set) => ({
    configDataValue: {},
    setConfigDataValue: (v) => set({ configDataValue: v }),
}))

export const useModalStore = create((set) => ({
    isOpen: false,
    modalComponent: <div></div>,
    colorCloseIcon: '#231448',
    closeFn: null,
    setColorCloseIcon: (item) => set(() => ({ colorCloseIcon: item })),
    setModalComponent: (component, color = '#231448') =>
        set(() => ({ modalComponent: component, colorCloseIcon: color })),
    onClose: () => set(() => ({ isOpen: false })),
    onOpen: () => set(() => ({ isOpen: true })),
    setCloseFn: (closeFn) => set(() => ({ closeFn })),
}))

export const useSpacialTrafficNews = create((set) => ({
    isOpenSpacial: true,
    data: '',
    setData: (item) => set(() => ({ data: item })),
    onCloseSpacial: () => set(() => ({ isOpenSpacial: false })),
    onOpenSpacial: () => set(() => ({ isOpenSpacial: true })),
}))

export const useSimpleBackdropStore = create((set) => ({
    isOpen: false,
    onCloseSimpleBackdrop: () => set(() => ({ isOpen: false })),
    onOpenSimpleBackdrop: () => set(() => ({ isOpen: true })),
}))
export const useFooterShow = create((set) => ({
    isOpen: false,
    hiddenFooter: () => set(() => ({ isOpen: false })),
    showFooter: () => set(() => ({ isOpen: true })),
}))

export const imageViewer = create((set) => ({
    isOpen: false,
    hiddenImage: () => set(() => ({ isOpen: false, type: '' })),
    showImage: () => set(() => ({ isOpen: true })),
    imageSrc: '',
    setImage: (src) => set(() => ({ imageSrc: src })),
    type: '',
    setType: (type) => set(() => ({ type })),
}))

export const useIASMStore = create((set) => ({
    tokenInfo: null,
    setTokenInfo: (v) => set({ tokenInfo: v }),
}))
