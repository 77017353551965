import React from 'react'
import tw from 'twin.macro'
import packageJson from "../../../package.json"

const Wrap = tw.div`w-full h-full flex flex-col items-center justify-center space-y-6`

export default function AppVersion() {
  
  return (
    <Wrap>
        <div dangerouslySetInnerHTML={{ __html: `appVersion: WebSite ${packageJson.version}` }}></div>
    </Wrap>
  )
}
