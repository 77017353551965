// Package imports
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Grid, Tab, Tabs, tabsClasses } from '@mui/material'
// Project imports
import { filterName } from 'utils/filter'
import corporateSuitesImage from 'resources/images/pearl-club/CorporateSuites.jpg'
import logeBoxesImage from 'resources/images/pearl-club/LogeBoxes.jpg'
import { TabContainer } from 'components/TabContainer'
import ConnectCard from './ConnectCard'

// Folder imports

const FeaturedSectionItem = ({ data, showDivider = false }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const { id, title, image, description, benefits } = data
    // console.log("🚀 ~ FeaturedSectionItem ~ benefits:", benefits)

    return (
        <div id={id} className='bg-container'>
            <div className='text-h2 mt-[40px] mb-[24px] '>{title}</div>
            <ConnectCard key='0' isReverse={false} image={image} about={description} />
            {/* <div class="flex flex-col md:flex-row items-center md:h-[369px]">
					<div class="w-full md:w-[696px] aspect-[696/369]">
						<img
							src={image}
							alt={title}
							// className="w-full h-full"
							className="w-full h-auto aspect-[696/369]" 
						/>
					</div>
					<div class="shadow-[0_1px_5px_0_rgba(0,0,0,0.1),0_1px_9px_0_rgba(0,0,0,0.1)] md:shadow-[0_0_1px_0_rgba(0,0,0,0.1),0_1px_5px_0_rgba(0,0,0,0.1),0_1px_9px_0_rgba(0,0,0,0.1)] 
                flex-1 
								md:px-10 md:py-auto px-[24px] py-[22px]
                h-full w-full md:max-w-[444px] 
								flex items-center">
						<p className="text-body2 line-clamp-8">{description}</p>
					</div>
				</div> */}
            <div className='text-h3 mt-[40px] mb-[24px]'>
                <div>{benefits.title}</div>
            </div>
            <div>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        {benefits.items.map((benefit, index) => {
                            return (
                                <Grid key={index} item xs={12} md={6} sx={{ marginBottom: { xs: '24px', sm: '28px' } }}>
                                    <Box className='flex items-start'>
                                        {/* <div className="icon-tickCarPark mr-2"> */}
                                        <div className='mt-2 mr-[6px]'>
                                            <svg
                                                width='13'
                                                height='10'
                                                viewBox='0 0 18 13'
                                                fill='none'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <path
                                                    d='M17 1.125L6 12.125L1 7.125'
                                                    stroke='#D6093B'
                                                    stroke-width='1.5'
                                                    stroke-linecap='round'
                                                    stroke-linejoin='round'
                                                />
                                            </svg>
                                        </div>

                                        <div>
                                            <div className='text-h5 mb-[8px]'>{benefit.title}</div>
                                            {benefit.description && (
                                                <div style={{ fontSize: '16px' }}>{benefit.description}</div>
                                            )}
                                        </div>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </div>
            <div className='mb-[40px]'>
                {benefits.terms.map((term, index) => {
                    return (
                        <div key={index}>
                            <div>{term}</div>
                        </div>
                    )
                })}
            </div>
            {showDivider && <Divider className='border-[#B1B1B1]' />}
        </div>
    )
}

const FeaturedSection = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const tabData = [
        {
            id: 'Corporate_Suites',
            index: 0,
            title: t('pearlClub.corporateSuites'),
            image: corporateSuitesImage,
            description: t('pearlClub.corporateSuitesDesc'),
            benefits: {
                title: t('pearlClub.benefits'),
                items: [
                    {
                        title: t('pearlClub.bestSeats'),
                        description: t('pearlClub.bestSeatsDesc'),
                    },
                    {
                        title: t('pearlClub.guaranteedAccess'),
                        description: t('pearlClub.guaranteedAccessDesc'),
                    },
                    {
                        title: t('pearlClub.customizable'),
                        description: t('pearlClub.customizableDesc'),
                    },
                    {
                        title: t('pearlClub.vipSeats'),
                        description: t('pearlClub.vipSeatsDesc'),
                    },
                    {
                        title: t('pearlClub.vipEntranceReception'),
                    },
                    {
                        title: t('pearlClub.priorityCarParking'),
                    },
                    {
                        title: t('pearlClub.inSuitFood'),
                    },
                    {
                        title: t('pearlClub.pearlClubServiceManager'),
                    },
                    {
                        title: t('pearlClub.membersOnlyEvents'),
                    },
                ],
                terms: [t('pearlClub.ticketingInclusion'), t('pearlClub.ticketingInclusion1')],
            },
        },
        {
            id: 'Loge_Boxes',
            index: 1,
            title: t('pearlClub.logeBoxes'),
            image: logeBoxesImage,
            description: t('pearlClub.logeBoxesDesc'),
            benefits: {
                title: t('pearlClub.benefits'),
                items: [
                    {
                        title: t('pearlClub.primePosition'),
                        // description: 'Private balcony seating and indoor entertaining area',
                    },
                    {
                        title: t('pearlClub.guaranteedAccess'),
                        description: t('pearlClub.guaranteedAccessDesc'),
                    },
                    {
                        title: t('pearlClub.vipSeats'),
                        description: t('pearlClub.vipSeatsDesc'),
                    },
                    {
                        title: t('pearlClub.vipEntranceReception'),
                    },
                    {
                        title: t('pearlClub.priorityCarParking'),
                    },
                    {
                        title: t('pearlClub.inSeatFood'),
                    },
                    {
                        title: t('pearlClub.pearlClubServiceManager'),
                    },
                    {
                        title: t('pearlClub.membersOnlyEvents'),
                    },
                ],
                terms: [t('pearlClub.ticketingInclusion'), t('pearlClub.ticketingInclusion1')],
            },
        },
    ]

    return (
        <div className='bg-white pt-16'>
            <TabContainer
                fullWidth={true}
                components={tabData
                    .map((data, index) => {
                        return {
                            key: data.id,
                            title: data.title,
                            component: () => (
                                <FeaturedSectionItem
                                    key={data.id}
                                    data={data}
                                    showDivider={index !== tabData.length - 1}
                                />
                            ),
                        }
                    })
                    .filter((i) => i)}
            />
        </div>
    )
}

export default FeaturedSection
