import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules'
import { useMediaQuery, Button } from '@mui/material'
import { useMeasure } from 'react-use'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'

import { isImage } from 'utils/filter'
import Steps from 'components/swiperComponent/Steps'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import line from 'resources/images/bannerLine.png'

function BannerSwiper({ bannerSwiperImgs, type }) {
    const [ref, { width }] = useMeasure()
    const navigate = useNavigate()
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const [isPlay, setIsPlay] = useState(true)
    const screen = useMediaQuery('(min-width:1280px)')
    const lgScreen = useMediaQuery('(min-width:1024px)')
    const mdScreen = useMediaQuery('(min-width:768px)')
    const space = screen ? 520 : lgScreen ? 480 : mdScreen ? 638 : 300
    const goToNextSlide = () => {
        swiper.slideNext()
    }

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }
    const buttonLink = (action, url, id, path) => {
        action ? navigate(path + '?id=' + id) : window.open(url)
    }
    const buttonLinkVenue = (action, url, id, path) => {
        if (action === 2) {
            navigate('/promotionsDetail?id=' + id)
        } else if (action === 3) {
            navigate(path + '?id=' + id)
        } else {
            window.open(url, '_blank')
        }
    }
    const buttonLinkShop = (action, url, id, path) => {
        if (action === 3) {
            navigate('/promotionsDetail?id=' + id)
        } else if (action === 4) {
            navigate(path + '?id=' + id)
        } else {
            window.open(url, '_blank')
        }
    }
    const buttonLinkRestaurant = (action, url, id, path) => {
        if (action === 3) {
            navigate('/promotionsDetail?id=' + id)
        } else if (action === 5) {
            navigate(path + '?id=' + id)
        } else {
            window.open(url, '_blank')
        }
    }
    const buttonLinkStadium = (action, url, id, path) => {
        if (action === 3) {
            navigate('/promotionsDetail?id=' + id)
        } else if (action === 10) {
            navigate(path + '?id=' + id)
        } else {
            window.open(url, '_blank')
        }
    }

    const buttonLinkTour= (action, url, id, path) => {
        if (action === 3) {
            navigate('/promotionsDetail?id=' + id)
        } else {
            window.open(url, '_blank')
        }
    }

    const isLoopFix = bannerSwiperImgs.length > 1 && bannerSwiperImgs.length < 4
    const showSlides = isLoopFix ? [...bannerSwiperImgs, ...bannerSwiperImgs] : bannerSwiperImgs
    const showSlidesIndex = isLoopFix ? slideIndex % bannerSwiperImgs.length : slideIndex
    const buttonMethod = (img) => {
        type === 'event' && buttonLink(img.existEvent, img.externalLink, img.eventUuid, '/eventsTicketsDetail')
        type === 'venue' && buttonLinkVenue(img.buttonAction, img.externalLink, img.actionUuid, '/venueDetail')
        type === 'newsroom' && buttonLink(img.buttonActionSwitch, img.buttonAction, img.buttonAction, '/newsRoomDetail')
        type === 'promotion' && buttonLink(img.buttonAction, img.externalLink, img.identityUuid, '/promotionsDetail')
        type === 'sports' &&
            buttonLink(img.buttonAction, img.externalLink, img.facilitiesBookingUuid, '/facilitiesBookingDetail')
        type === 'programmes' &&
            buttonLink(img.buttonAction === '1', img.externalLink, img.programmeUuid, '/programmesDetail')
        type === 'shop' && buttonLinkShop(img.buttonAction, img.externalLink, img.relatedUuid, '/shopsDetail')
        type === 'restaurant' && buttonLinkRestaurant(img.buttonAction, img.externalLink, img.relatedUuid, '/restaurantsDetail')
        type === 'stadium' && buttonLinkStadium(img.buttonAction, img.externalLink, img.relatedUuid, '/stadiumBitesDetail')
        type === 'tour' && buttonLinkTour(img.buttonAction, img.externalLink, img.redirectUuid)
    }
    useEffect(() => {
        const video = !isImage(bannerSwiperImgs[showSlidesIndex]?.webVisual)
        if (video && swiper) {
            setIsPlay(true)
            swiper?.autoplay.stop()
            // setTimeout(() => {
            //     goToNextSlide()
            // }, 2000)
        } else {
            swiper && swiper?.autoplay.start()
        }
    }, [showSlidesIndex])

    return (
        <div className='pt-8 md:pt-16'>
            <Swiper
                loop={true}
                // effect={'coverflow'}
                allowTouchMove={false}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={3}
                initialSlide={0}
                spaceBetween={-space}
                // coverflowEffect={{
                //     rotate: 1,
                //     stretch: -space,
                //     depth: space,
                // }}
                onSwiper={(swiper) => {
                    setSwiper(swiper)
                }}
                onActiveIndexChange={(swiper) => {
                    setSlideIndex(swiper.realIndex)
                }}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                className='mySwiper'
            >
                {showSlides.map((img, index) => {
                    return (
                        <SwiperSlide className={slideIndex !== index && 'mt-5 -z-10 md:mt-[50px] lg:mt-9 2xl:mt-12'} key={index}>
                            {slideIndex !== index && (
                                <div className='absolute z-20 top-0 left-0 bottom-0 right-0 opacity-50 bg-white'></div>
                            )}
                            <div
                                ref={slideIndex === index ? ref : null}
                                className={
                                    ' relative ' +
                                    (index === slideIndex
                                        ? ' aspect-video'
                                        : ' h-[150px] sm:h-[180px] md:h-[286px] lg:h-[300px] xl:h-[358px] 2xl:h-[400px]')
                                }
                            >
                                {slideIndex === index && (
                                    <div
                                        onClick={() => {
                                            img?.webVisual && !isImage(img?.webVisual) && setIsPlay(!isPlay)
                                        }}
                                        className={
                                            'absolute top-0 left-0 right-0 bottom-0 z-20' +
                                            (img?.webVisual && !isImage(img?.webVisual) ? ' cursor-pointer' : '')
                                        }
                                    >
                                        <img alt='' src={line} className='w-full h-full object-fill' />
                                    </div>
                                )}

                                <>
                                    {isImage(img.webVisual) ? (
                                        <img
                                            className={'h-full w-full object-cover textarea-content'}
                                            alt={img.altText}
                                            src={img.webVisual}
                                        />
                                    ) : (
                                        <div className={' h-full w-full bg-black cursor-pointer z-40 '}>
                                            <ReactPlayer
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            style: {
                                                                width: '100%',
                                                                height: '100%',
                                                                objectFit: 'cover',
                                                            },
                                                        },
                                                    },
                                                }}
                                                muted
                                                playsinline={true}
                                                className=''
                                                playing={slideIndex === index && isPlay}
                                                // controls={true}
                                                width='100%'
                                                height='100%'
                                                onEnded={() => goToNextSlide()}
                                                url={img.webVisual}
                                            />
                                        </div>
                                    )}
                                </>
                                {index === slideIndex ? (
                                    <div className='absolute flex z-10 text-white top-0 bottom-0 left-0 w-[41%]'>
                                        <div className='absolute -z-10 top-0 bottom-0 left-0 w-full bg-website-gradation opacity-90 '></div>
                                        <div className='flex z-30 flex-col pb-[64px] px-4 overflow-hidden pt-6 md:pt-[48px] md:pl-8'>
                                            <div className='font-semiboldFamily text-xl break-words line-clamp-3 md:line-clamp-2  md:text-[28px] md:leading-[33.6px] lg:line-clamp-3 xl:line-clamp-5'>
                                                {img.title ?? img.webTitle}
                                            </div>
                                            <div className='text-sm font-medium textarea-content line-clamp-2 pt-4 mb-3 hidden md:visible md:line-clamp-3 md:mb-10 xl:mb-15'>
                                                {img.description ?? img.webDescription}
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className=' absolute top-0 left-0 right-0 bottom-0 bg-[#000000] opacity-10'></div>
                                )}
                                {img.buttonTitle && index === slideIndex && (
                                    <div className='z-30 absolute bottom-6 left-4 md:left-8 md:bottom-[64px] lg:bottom-[80px]'>
                                        <Button
                                            onClick={() => {
                                                buttonMethod(img)
                                                setIsPlay(false)
                                            }}
                                            className='z-20 py-0 px-[10px] h-fit group font-semiboldFamily overflow-hidden border-[1px] max-w-[150px] md:min-w-[150px] md:max-w-[200px] md:py-1 md:px-[15px] lg:py-2 lg:px-[30px] xl:md:max-w-[248px]'
                                            variant='primary'
                                            size='medium'
                                        >
                                            <span className='w-full bg-website-gradation inline-block text-transparent hover:text-white bg-clip-text group-hover:text-white'>
                                                {img.buttonTitle}
                                            </span>
                                        </Button>
                                    </div>
                                )}
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>

            {bannerSwiperImgs.length > 1 && (
                <div style={{ width: width }} className='pt-6 flex justify-between items-center mx-auto'>
                    <Steps
                        swiperLength={bannerSwiperImgs}
                        slideIndex={showSlidesIndex}
                        stepTextColor={'text-secondary-midnightBlue'}
                        stepActiveColor={'bg-secondary-midnightBlue'}
                    />
                    <IconButtonChange
                        goToNextSlide={goToNextSlide}
                        goToPrevSlide={goToPrevSlide}
                        buttonColor={'#231448'}
                        borderColor={'border-secondary-midnightBlue'}
                        length={bannerSwiperImgs.length}
                    />
                </div>
            )}
        </div>
    )
}

export default BannerSwiper
