import React, { useRef, useState, useEffect } from 'react'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { map } from 'ramda'

import { eventServices } from 'service/eventService'
import BannerSwiper from 'components/detailComponents/BannerSwiper'
import TopIntro from 'page/EventsTickets/components/TopIntro'
import AboutTabs from 'page/EventsTickets/components/AboutTabs'
import PromotionCards from 'page/EventsTickets/components/PromotionCards'
import RecommendedCards from 'page/EventsTickets/components/RecommendedCards'
import { TabContainer } from 'components/TabContainer'
import TabAbout from '../components/TabAbout'
import Loading from 'components/Loading'
import Tags from '../components/Tags'
import {
    TabSeatingMap,
    TabSeatingMapAndThingsToNote,
    TabThingsToNote,
} from '../components/TabSeatingMapAndThingsToNote'
import useNotAvailableAutoLeave from 'utils/useNotAvailableAutoLeave'
import { notAvailableErrorCode } from 'utils/filter'

export default function Detail() {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const promotionRef = useRef(null)
    const youMayLikeRef = useRef(null)
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id') + ''

    const [query, setQuery] = useState({
        lang: language,
        eventId: id,
        alsoLikeSize: 4,
        promoSize: 5,
        alsoLikeUuid: [],
        promoUuid: [],
    })
    const [isLoading, setIsLoading] = useState(false)

    const { data: items, isPending } = useQuery({
        queryKey: ['getEventDetail', query],
        queryFn: () => eventServices.getEventDetail(query),
        placeholderData: keepPreviousData,
    })

    useEffect(() => {
        if (!!items && items?.result !== null && language) {
            setQuery((obj) => ({
                ...obj,
                lang: language,
                alsoLikeUuid: map((item) => item?.uuid, items?.alsoLike) ?? [],
                promoUuid: map((item) => item?.identityUuid, items?.promo) ?? [],
            }))
        }
    }, [language])

    useEffect(() => {
        if (id) {
            setIsLoading(true)
            setQuery((obj) => ({
                ...obj,
                eventId: id,
                alsoLikeUuid: [],
                promoUuid: [],
            }))
        }
    }, [id])
    useEffect(() => {
        if (!!items) setIsLoading(false)
    }, [items])
    useNotAvailableAutoLeave(items?.errorCode, notAvailableErrorCode.event)

    if (isPending || isLoading) return <Loading />
    if (items?.result === null) return null
    return (
        <div>
            {items && items?.banner?.length > 0 && <BannerSwiper items={items.banner} />}
            {items && (
                <div>
                    <div className='bg-container'>
                        <div className='text-secondary-midnightBlue'>
                            <TopIntro items={items} />
                        </div>
                    </div>
                    <section>
                        <TabContainer
                            components={[
                                items.description && {
                                    key: 'about',
                                    title: t('eventPage.about'),
                                    component: () => <TabAbout items={items} />,
                                },
                                // only seatingMap
                                (items.seatingFloorPlan || items.seatingFloorPlanText) &&
                                    (items?.hideThingsToNote ||
                                        (items?.thingsToNote && items?.thingsToNote?.length === 0)) && {
                                        key: 'seatingPlan',
                                        title: t('eventPage.seatingPlan'),
                                        component: () => <TabSeatingMap items={items} />,
                                    },

                                // only thingsToNote
                                !items.hideThingsToNote &&
                                    items.thingsToNote &&
                                    items.thingsToNote?.length > 0 &&
                                    !items?.seatingFloorPlan &&
                                    !items.seatingFloorPlanText && {
                                        key: 'thingsToNote',
                                        title: t('eventPage.thingsToNote'),
                                        component: () => <TabThingsToNote items={items} />,
                                    },
                                // both seatingMap & thingsToNote
                                (items.seatingFloorPlan || items.seatingFloorPlanText) &&
                                    !items.hideThingsToNote &&
                                    items.thingsToNote &&
                                    items.thingsToNote?.length > 0 && {
                                        key: 'thingsToNote&seatingPlan',
                                        title: `${t('eventPage.seatingPlan')} & ${t('eventPage.thingsToNote')}`,
                                        component: () => <TabSeatingMapAndThingsToNote items={items} />,
                                    },

                                ((items?.tags && items?.tags?.length > 0) ||
                                    (items?.dynamicContent && items?.dynamicContent?.length > 0)) && {
                                    key: 'tags&dynamicContent',
                                    title: '',
                                    component: () => (
                                        <div className='bg-container text-secondary-midnightBlue'>
                                            <div className='max-w-[750px] space-y-8 pt-8 md:space-y-[60px] md:pt-[60px]'>
                                                {items?.tags && items?.tags?.length > 0 && <Tags items={items} />}
                                                {items?.dynamicContent && items?.dynamicContent?.length > 0 && (
                                                    <div className='flex flex-col'>
                                                        {items.dynamicContent.map((item, index) => {
                                                            return (
                                                                <a
                                                                    key={index}
                                                                    href={item.content}
                                                                    className='text-lg font-boldFamily underline cursor-pointer active:opacity-50 mb-[11px]'
                                                                >
                                                                    {item.title}
                                                                </a>
                                                            )
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ),
                                },
                                items?.promo &&
                                    items?.promo?.length > 0 && {
                                        key: 'promotion',
                                        title: t('visitList.promotions'),
                                        component: () => (
                                            <div className='pt-8 md:pt-[69px]'>
                                                <PromotionCards promotionRef={promotionRef} items={items.promo} />
                                            </div>
                                        ),
                                    },
                                items?.alsoLike?.length > 0 && {
                                    key: 'recommend',
                                    title: t('eventPage.uMayAlsoLike'),
                                    component: () => (
                                        <div className='text-secondary-midnightBlue'>
                                            <RecommendedCards youMayLikeRef={youMayLikeRef} items={items.alsoLike} />
                                        </div>
                                    ),
                                },
                            ].filter((i) => i)}
                        />
                    </section>
                </div>
            )}
        </div>
    )
}
