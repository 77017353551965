import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation, Autoplay } from 'swiper/modules'
import { useMediaQuery } from '@mui/material'
import ReactGA from 'react-ga4';

import Steps from 'components/swiperComponent/Steps'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'
function MagicSwiper({ items = [] }) {
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const mdScreen = useMediaQuery('(min-width:768px)')
    // const [adverisement, setAdversement] = useState([])
    const goToNextSlide = () => {
        swiper.slideNext()
    }

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }
    return (
        <>
            {
                <div className='pt-8 bg-container md:pt-16'>
                    <Swiper
                        loop={true}
                        modules={[Navigation, Autoplay]}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        onSwiper={(swiper) => {
                            setSwiper(swiper)
                        }}
                        onActiveIndexChange={(swiper) => {
                            setSlideIndex(swiper.realIndex)
                        }}
                        initialSlide={0}
                        className='mySwiper'
                    >
                        {items.map((a, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <img
                                        className=' w-full object-cover cursor-pointer aspect-[38/7] '
                                        onClick={() => {
                                            if (index < 3) {
                                                ReactGA.event(`Home_Click_AdTop${slideIndex + 1}`);
                                            }
                                            window.open(a.webUrl)
                                        }}
                                        src={a.image}
                                        alt={a.altText}
                                    />
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                    {items.length > 1 && (
                        <div className='pt-[16px] w-full flex justify-between items-center'>
                            <Steps
                                swiperLength={items}
                                slideIndex={slideIndex}
                                stepTextColor={'text-secondary-midnightBlue'}
                                stepActiveColor={'bg-secondary-midnightBlue'}
                            />
                            <IconButtonChange
                                goToNextSlide={goToNextSlide}
                                goToPrevSlide={goToPrevSlide}
                                buttonColor={'#231448'}
                                borderColor={'border-secondary-midnightBlue'}
                            />
                        </div>
                    )}
                </div>
            }
        </>
    )
}

export default MagicSwiper
