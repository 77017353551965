import React from 'react'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import { Box, useMediaQuery } from '@mui/material'

import { imageViewer } from 'store/authAtom'
import { cn } from 'utils/cn'
function ImageViewer() {
    const { isOpen, hiddenImage, imageSrc, type } = imageViewer()
    const isXl = useMediaQuery('(min-width:1440px)')
    const isSm = useMediaQuery('(max-width:500px)')

    if (type === 'transport') {
        return (
            <Modal
                open={isOpen}
                onClose={hiddenImage}
                sx={{
                    '.MuiBox-root:focus-visible': {
                        outline: 'none',
                        border: 'none',
                    },
                }}
                slotProps={{
                    root: { className: 'shadow-card' },
                    backdrop: {
                        className: 'backdrop-blur-[10px] bg-[#000] sm:bg-[#333] sm:bg-opacity-20',
                    },
                }}
            >
                <>
                    <div className={cn('w-full flex p-5', isSm ? 'justify-start' : 'justify-end')}>
                        <div onClick={hiddenImage} className='text-white icon-close cursor-pointer' />
                    </div>
                    <Box className='absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 w-full h-auto sm:w-2/3 max-w-[860px]'>
                        <div className={cn('w-full aspect-[4/3]', !isXl && 'absolute top-1/2 -translate-y-1/2')}>
                            <img alt='' src={imageSrc} className='w-full' />
                        </div>
                    </Box>
                </>
            </Modal>
        )
    }
    return (
        <Modal
            open={isOpen}
            onClose={hiddenImage}
            sx={{
                '.MuiBox-root:focus-visible': {
                    outline: 'none',
                    border: 'none',
                },
            }}
        >
            <Box className='absolute left-1/2 -translate-x-1/2 w-[95%] rounded-[10px] bg-black flex justify-center items-center h-auto top-1/2 -translate-y-1/2 max-h-[100vh] md:h-[774px] md:w-[80%]'>
                <div className='mx-auto w-full h-full md:w-4/5'>
                    <CloseIcon
                        onClick={hiddenImage}
                        className='text-white text-2xl absolute cursor-pointer top-4 right-4 hidden md:block'
                    />
                    <img alt='' src={imageSrc} className='w-full h-full object-contain' />
                </div>
            </Box>
        </Modal>
    )
}

export default ImageViewer
