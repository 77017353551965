import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Button } from '@mui/material'
import tw from 'twin.macro'
import { ChevronRightOutlined } from '@mui/icons-material'

import OverviewEn_Tc from 'resources/images/transportation_overview_TC.jpg'
import OverviewSc from 'resources/images/transportation_general_SC.jpg'
import WrapBox from 'page/Transportation/components/WrapBox'
import { information } from 'service/informationService'

const WrapHour = tw.div`text-lg`

export default function AllTransport() {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const { data } = useQuery({
        queryKey: ['getOpenHour', language],
        queryFn: () =>
            information.getOpenHour({
                lang: language,
            }),
    })

    return (
        <WrapBox
            image={language === 'zh_CN' ? OverviewSc : OverviewEn_Tc}
            component={
                <div className='space-y-6 text-base'>
                    <div className=' space-y-2 font-semiboldFamily'>
                        <div className='text-[20px]'>{t('transport.kaiTakSportPark')}</div>
                        <div className=' font-semiboldFamily'>{t('visitorTip.openingHour')}</div>
                        {data?.map((item, i) => (
                            <WrapHour className='font-regular' key={i}>
                                {item?.openHour}
                            </WrapHour>
                        ))}
                        <div className=' font-semiboldFamily'>{t('transport.address')}</div>
                        <div className=' text-lg font-regular'>{t('transport.specificAddress')}</div>
                    </div>
                    {/* <Button variant='secondaryBlue' endIcon={<ChevronRightOutlined />}>
                        {t('transport.goToGoogleMap')}
                    </Button> */}
                </div>
            }
            bomComponent={<div className='font-medium whitespace-pre-line pt-8 md:pt-12 text-secondary-midnightBlue'>{t('transport.allIntro')}</div>}
        />
    )
}
