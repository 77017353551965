import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from '@mui/material'

import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import SwiperEventCards from 'components/detailComponents/SwiperEventCards'
import RestaurantCard from './RestaurantCard'
function RecommendedRestaurantCards({ items = [], youMayLikeRef }) {
    const { t } = useTranslation()
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const lgScreen = useMediaQuery('(min-width:1024px)')
    const goToNextSlide = () => {
        swiper.slideNext()
    }   

    const goToPrevSlide = () => {
        swiper.slidePrev()
    }
    return (
        <div ref={youMayLikeRef} className='py-[60px] bg-container'>
            <div className='flex justify-between items-center pb-4 md:pb-8'>
                <div className='text-h3 text-secondary-midnightBlue font-semiboldFamily'>
                    {t('shop.YouMayAlsoLike')}
                </div>
                <div
                    className={
                        'block xl:hidden ' + (items.length <= 3 && ' lg:hidden ') + (items.length <= 2 && ' hidden ')
                    }
                >
                    <IconButtonChange
                        slideIndex={slideIndex}
                        num={lgScreen ? 3 : 2}
                        length={items.length}
                        goToNextSlide={goToNextSlide}
                        goToPrevSlide={goToPrevSlide}
                        buttonColor={'#231448'}
                        borderColor={'border-secondary-midnightBlue'}
                    />
                </div>
            </div>
            <SwiperEventCards
                setSlideIndex={setSlideIndex}
                items={items}
                setSwiper={setSwiper}
                CardType={RestaurantCard}
            />
        </div>
    )
}

export default RecommendedRestaurantCards
