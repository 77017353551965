import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'

import { footer } from 'service/footerService'
import phone from 'resources/images/phone.png'
import camera from 'resources/images/camera.png'

import PhoneSwiper from 'components/landingComponents/PhoneSwiper'
import m2 from 'resources/images/ticket.svg'
import m3 from 'resources/images/booking.svg'
import m4 from 'resources/images/loyalty.png'
import m5 from 'resources/images/programme3.svg'
import { filterDownLoadAppIcon } from 'utils/filter'
import { Button } from '@mui/material'
import { cn } from 'utils/cn'

function DownApp() {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const navigate = useNavigate()
    const iconsList = [
        // { icon: m1, about: t('landing.indoorNavigation') },
        { icon: m2, about: t('landing.ticketing') },
        { icon: m5, about: t('landing.communityProgrammes') },
        { icon: m3, about: t('landing.facilitiesBooking') },
        { icon: m4, about: t('landing.loyalty') },
    ]
    const { data: menu } = useQuery({
        queryKey: ['getFooter'],
        queryFn: () =>
            footer.getFooter({
                lang: 'en_US',
                platForm: 'Web',
            }),
        select: (res) => res?.menu,
    })

    return (
        <div className='w-full flex justify-center pt-[156px]  md:pt-[140px] xl:pt-[148px]'>
            <div className='bg-circleBg z-0 bg-bottom bg-cover  w-full bg-no-repeat max-w-[2360px] lg:pt-4 xl:pt-20 '>
                <div className='bg-container w-full lg:flex lg:gap-10 lg:pb-[81px] xl:gap-[80px] 2xl:pb-[200px]'>
                    <div className='w-[200px] relative mx-auto h-[411.77px] mt-[-84px] lg:mt-[-46px] lg:mx-0 lg:ml-2 lg:w-[360px] lg:h-[741px] xl:lg:mt-[-106px] xl:ml-[50px]'>
                        <img alt='phone' draggable={false} src={phone} className='w-full object-fill h-full' />
                        <div className=' absolute z-40 left-1/2 -translate-x-1/2 w-[49px] h-[13px] top-[10px] lg:top-5 lg:w-[88.2px] lg:h-6'>
                            <img alt='phone' draggable={false} src={camera} className='w-full object-cover h-full' />
                        </div>
                        <div className='absolute bg-white z-10 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[187px] h-[395.7px] rounded-[20px] lg:w-[336px] lg:h-[714px] overflow-hidden lg:rounded-[35px]'>
                            <PhoneSwiper />
                        </div>
                    </div>
                    <div className='pt-[33px] pb-[34px] w-[335px]  h-fit  mx-auto md:pb-[60px] md:w-[380px] lg:mx-0 lg:pt-[101.6px] lg:w-[486px]'>
                        <div className='font-boldFamily text-center text-white text-[20px] md:text-[36px] lg:text-start lg:text-[48px] leading-[120%] whitespace-pre text-wrap'>
                            {t('landing.downloadAppTitle')}
                        </div>
                        <div className='w-full grid gap-5 grid-cols-2 md:grid-cols-4 pt-5 md:pt-8 lg:pt-[37px]'>
                            {iconsList.map((icon, index) => {
                                return (
                                    <div className='flex flex-col items-center flex-1' key={index}>
                                        <img
                                            alt=''
                                            src={icon.icon}
                                            className={cn(
                                                index === iconsList.length - 1 ? 'size-[72px]' : 'size-[48px]',
                                                ' object-contain  flex-1',
                                            )}
                                        />
                                        <div className='flex items-center justify-center min-h-14'>
                                            <p className='text-white text-center text-lg md:leading-[22px]'>
                                                {icon.about}
                                            </p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='w-full px-[23.15px] flex gap-5 pt-8 md:pt-[37px] lg:px-0 lg:pt-[37px]'>
                            {menu &&
                                menu?.downloadApp.length > 0 &&
                                menu.downloadApp.map((img, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className='cursor-pointer active:opacity-70 w-auto h-10 md:h-[60px]'
                                        >
                                            <img
                                                onClick={() => window.open(img.link)}
                                                key={index}
                                                alt=''
                                                src={filterDownLoadAppIcon(img, language)}
                                                className='object-contain w-full h-full'
                                            />
                                        </div>
                                    )
                                })}
                        </div>
                        <Button
                            onClick={() => navigate('/visitorTips?val=KTSPAPPIntroduction')}
                            className='mt-8 md:mt-[37px] border-[1px] text-button1 px-5 h-fit py-[7px] w-full lg:min-w-[240px] lg:w-fit group'
                            variant='primary'
                            size='large'
                        >
                            <span className='bg-website-gradation inline-block text-transparent hover:text-white bg-clip-text group-hover:text-white'>
                                {t('landing.learnMore')}
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownApp
