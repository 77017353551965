import React, { useMemo } from 'react'
import { Button, useMediaQuery } from '@mui/material'

import { cn } from 'utils/cn'
import Content from 'components/Content'
import { updateUrlLang } from 'utils/util'
import { Trans, useTranslation } from 'react-i18next'
import balloting from 'resources/images/balloting.svg'
const BuyNowBtn = ({ btnDatas }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const isPC = useMediaQuery('(min-width: 1024px)')
    const { type, isFull, ctaButtonUrl, ballotLink } = btnDatas

    const btnText = useMemo(() => {
        let btn = t('precinctTour.detail.enrol')
        if (type?.includes('BALLOT')) {
            btn = t('precinctTour.detail.ballot')
            if (type?.includes('FCFS')) {
                btn = t('precinctTour.detail.enrol')
            }
        }
        if (isFull) {
            btn = t('precinctTour.detail.full')
        }
        return btn
    }, [type, isFull, language])

    return (
        <div
            className={cn(
                'sticky bottom-0 left-0 right-0 bg-white z-40 pt-4 flex-1 lg:static lg:pt-[96px]',
                isPC ? '' : 'shadow-card',
            )}
        >
            <div className='flex flex-col-reverse px-5 gap-4 md:px-[60px] lg:gap-6 lg:min-h-[317px] lg:flex-col lg:border-[#0A173D] lg:pl-8 lg:pr-0 lg:border-solid lg:border-l-[1px] '>
                {type?.includes('FCFS') ? (
                    <>
                        <div className='flex gap-4 flex-col pb-4 md:items-start md:justify-between lg:items-start lg:gap-6 lg:pb-0'>
                            <Button
                                disabled={isFull}
                                onClick={() => {
                                    if (ctaButtonUrl) {
                                        window.open(updateUrlLang(ctaButtonUrl))
                                    }
                                }}
                                className='text-white flex-1 text-lg leading-8 font-semiboldFamily h-fit py-2 lg:w-[332px]'
                                variant={!isFull ? 'primaryRed' : 'unselected'}
                                fullWidth
                                size='large'
                            >
                                {btnText}
                            </Button>
                        </div>
                    </>
                ) : (
                    <div className='flex gap-4 flex-col pb-4'>
                        <div className='w-full flex gap-4 lg:gap-6 flex-col md:flex-row lg:flex-col items-start md:items-center lg:items-start'>
                            <Button
                                disabled={isFull}
                                onClick={() => {
                                    if (ctaButtonUrl) {
                                        window.open(updateUrlLang(ctaButtonUrl))
                                    }
                                }}
                                className='text-white flex-1 text-lg leading-8 font-semiboldFamily h-fit py-2 lg:w-[332px]'
                                variant={!isFull ? 'primaryRed' : 'unselected'}
                                fullWidth
                                size='large'
                            >
                                {btnText}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default BuyNowBtn
