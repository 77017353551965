import React from 'react'
function ConnectCard({ isReverse, image, title, about }) {
    return (
        <div
            className={
                'w-full my-8 lg:flex lg:h-[338px] xl:h-[392px] shadow-card' + (isReverse ? ' lg:flex-row-reverse' : '')
            }
        >
            <div className='w-auto h-full'>
                <img alt='' src={image} className='h-full object-cover aspect-[16/9]' />
            </div>
            <div className='flex-1 flex flex-col justify-center p-[40px] text-secondary-midnightBlue w-full'>
                {title && <div className='font-semiboldFamily pb-8 text-xl md:text-2xl lg:text-4xl '>{title}</div>}
                <div className='font-medium text-sm lg:text-base textarea-content'>{about}</div>
            </div>
        </div>
    )
}

export default ConnectCard
