import React from 'react'
import pearlClubLogImage from 'resources/images/pearl-club/PearlClubLogo.jpg'
import pearlClubHeroImage from 'resources/images/pearl-club/PearlClubHero.jpg'
import { useTranslation } from 'react-i18next'

const HeroSection = () => {
    const { t } = useTranslation()

    return (
        <section className='flex flex-col lg:flex-row bg-white pt-16 bg-container justify-between items-start gap-x-6'>
            <div className='flex-1 w-full lg:min-w-[368px] flex flex-col flex-start items-center lg:items-start'>
                <img src={pearlClubLogImage} alt='Pearl Club Logo' className='max-w-[280px] aspect-[16/9] object-cover' />
                <p className='mb-2 text-body1 w-full'>{t('pearlClub.description')}</p>
            </div>
            {/* <div className='md:block md:min-w-[24px]'></div> */}
            <div className='w-full lg:w-[754px] grow-0'>
                <img src={pearlClubHeroImage} alt='Description' className='w-full object-cover aspect-[16/9]' />
            </div>
        </section>
    )
}

export default HeroSection
