import React from 'react'
import { useTranslation } from 'react-i18next'

import { formatStartDateEndDate } from 'utils/filter'
import NavigationPoint from 'components/NavigationPoint'
import ExclusiveOffer from 'components/detailComponents/ExclusiveOffer'
import dayjs from 'dayjs'
const ProductionAbout = ({ item }) => {
    return (
        <>
            {item.abouts.length > 0 && (
                <div className='flex gap-4'>
                    <div className='text-base font-semiboldFamily w-[116px]'>{item.title}</div>
                    <div className='flex-1'>
                        {item.abouts.map((about, index) => {
                            return (
                                <div key={index} className='text-base font-medium'>
                                    {about}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </>
    )
}
function NavigationAndIntro({ items }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const {
        startDate,
        endDate,
        startTime,
        endTime,
        specialRemarkTitle,
        specialRemarkDescription,
        registerButton,
        addressOne,
        addressTwo,
        promotionType,
    } = items
    const labels = [
        { title: t('eventPage.date'), abouts: [formatStartDateEndDate(startDate, endDate, language)] },
        {
            title: t('eventPage.time'),
            abouts:
                startTime && endTime ? [dayjs(startTime).format('HH:mm') + ' - ' + dayjs(endTime).format('HH:mm')] : [],
        },
        {
            title: t('venue.location'),
            abouts: [addressOne, addressTwo].filter(Boolean),
        },
        // { title: t('eventPage.type'), abouts: type ? [type] : [] },
    ].filter((item) => item)

    return (
        <div className='relative pt-8 w-full max-w-[800px] md:w-[90%] lg:w-[70%] pr-4'>
            <NavigationPoint
                firstNav={promotionType === 1 ? t('promotion.promotions') : t('promotion.precinct')}
                lastNav={items.promotionTitle}
                firstNavLink={promotionType === 1 ? '/promotions?promotionType=KaiTaiMallPromotion' : '/promotions?promotionType=PrecinctPromotion'}
            />
            <h1 className='py-4 text-h3 text-secondary-midnightBlue'>{items.promotionTitle}</h1>
            {(specialRemarkTitle || specialRemarkDescription) && (
                <ExclusiveOffer title={specialRemarkTitle} about={specialRemarkDescription} />
            )}
            <div className='py-4 space-y-2 text-secondary-midnightBlue'>
                {labels.map((item, index) => {
                    return <ProductionAbout key={index} item={item} />
                })}
            </div>
        </div>
    )
}

export default NavigationAndIntro
