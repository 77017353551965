import React, { forwardRef } from 'react'
import { cn } from 'utils/cn'
import './ckeditor5.css'

/**
 * @typedef {Object} ContentProps
 * @property {string} className - The class name of the content.
 * @property {string} data - The HTML content to be rendered.
 */

/**
 * Button component that can be used with refs.
 *
 * @param {ContentProps} props - The props for the content.
 * @param {React.Ref<HTMLDivElement>} ref - The forwarded ref to the content element.
 * @returns {JSX.Element} The content element.
 */
const Content = forwardRef(({ className, data, isNeedCk = true }, ref) => {
    return (
        <div
            ref={ref}
            className={cn(
                'text-secondary-midnightBlue ck-content html-content font-barlow [overflow-wrap:anywhere] ',
                className,
                isNeedCk ? '' : 'description-html',
            )}
            dangerouslySetInnerHTML={{ __html: data }}
        />
    )
})

export default Content
