import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { Navigation, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/swiper-bundle.css'
import phone1 from 'resources/images/phone1.png'
import phonezh from 'resources/images/phone1zh.png'
import phonech from 'resources/images/phone1ch.png'
import phone2 from 'resources/images/phoneProgammeEn.jpg'
import phone2zh from 'resources/images/phoneProgrammeZh.jpg'
import phone2ch from 'resources/images/phoneProgrammeCh.jpg'
import phone3 from 'resources/images/phoneTransportEn.png'
import phone3zh from 'resources/images/phoneTransportZh.jpg'
import phone3ch from 'resources/images/phoneTransportCh.jpg'

function PhoneSwiper() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const showSlides = useMemo(() => {
        return language === 'en_US'
            ? [phone1, phone2, phone3]
            : language === 'zh_HK'
            ? [phonezh, phone2zh, phone3zh]
            : [phonech, phone2ch, phone3ch]
    }, [language])
    return (
        <Swiper
            loop
            modules={[Navigation, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            // onSwiper={(swiper) => {
            //     setSwiper(swiper)
            // }}
            // onActiveIndexChange={(swiper) => {
            //     setSlideIndex(swiper.realIndex)
            // }}
            // autoplay={{
            //     delay: 5000,
            //     disableOnInteraction: false,
            // }}
            initialSlide={0}
            className='w-full h-full'
        >
            {showSlides.map((item, index) => {
                return (
                    <SwiperSlide className='cursor-pointer' key={index}>
                        <img alt='phone' src={item} className='w-full h-full object-cover' />
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

export default PhoneSwiper
