import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useMultiTranslation = () => {
    const { i18n } = useTranslation();

    return useMemo(() => ({
        current: i18n.t,
        en_US: i18n.getResourceBundle('en_US'),
        zh_CN: i18n.getResourceBundle('zh_CN'),
        zh_HK: i18n.getResourceBundle('zh_HK')
    }), [i18n]);
};