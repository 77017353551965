import React, { useState } from 'react'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import { createPortal } from 'react-dom'

import { dateRangeFormat } from 'utils/filter'
import NavigationPoint from 'components/NavigationPoint'
import ExclusiveOffer from 'components/detailComponents/ExclusiveOffer'
import BuyNowBtn from './BuyNowBtn'

const Title = tw.div`w-[116px] text-sm md:text-base`
function TopIntro({ items, btnDatas, isShowBtn }) {
    const {
        eventName,
        dateRange,
        specialRemarkDescription,
        specialRemarkTitle,
        enquiries,
        language: lang,
        time,
        date,
    } = items
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const isPC = useMediaQuery('(min-width: 1024px)')

    const datesFormat = dateRange && dateRangeFormat(dateRange, language)

    return (
        <div className='w-full flex gap-7'>
            {/* <div className=' bg-secondary-grey -z-10 absolute top-0 h-[100px] left-0 right-0'></div> */}
            <div className=' w-full space-y-4 py-6 max-w-[800px] md:pt-10 md:pb-[60px]'>
                <NavigationPoint
                    firstNav={t('precinctTour.detail.title')}
                    lastNav={eventName}
                    firstNavLink={'/precinctTour'}
                />
                <h1 className='text-h3 w-full text-secondary-midnightBlue py-8'>{eventName}</h1>
                {(specialRemarkTitle || specialRemarkDescription) && (
                    <ExclusiveOffer
                        title={specialRemarkTitle}
                        about={specialRemarkDescription}
                        text={t('precinctTour.detail.seeMore')}
                    />
                )}
                {date && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2 shrink-0'>{t('precinctTour.detail.date')}</Title>
                        <div>
                            <div className='text-body1'>{date}</div>
                        </div>
                    </div>
                )}

                {time && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2 shrink-0'>{t('precinctTour.detail.time')}</Title>
                        <div>
                            <div className='text-body1'>{time}</div>
                        </div>
                    </div>
                )}
                {enquiries && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2 shrink-0'>{t('precinctTour.detail.enquiries')}</Title>
                        <div className='text-body1 textarea-content'>{enquiries}</div>
                    </div>
                )}
                {lang && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2 shrink-0'>{t('precinctTour.detail.language')}</Title>
                        <div className='text-body1 textarea-content'>{lang}</div>
                    </div>
                )}
            </div>
            {isShowBtn && (
                <>
                    {isPC ? (
                        <BuyNowBtn items={items} times={dateRange} datesFormat={datesFormat} btnDatas={btnDatas} />
                    ) : (
                        createPortal(
                            <BuyNowBtn items={items} times={dateRange} datesFormat={datesFormat} btnDatas={btnDatas} />,
                            document.getElementById('main-container'),
                        )
                    )}
                </>
            )}
        </div>
    )
}

export default TopIntro
