import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button } from '@mui/material'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

import { useSimpleBackdropStore } from 'store/authAtom'
import { registerBtnFun } from 'utils/util'
import { useSnackbar } from 'notistack'
import { myProfile } from 'service/crmService'
import { GetCookie } from 'utils/cookieLombok'
import { footer } from 'service/footerService'
import FooterLabel from 'components/FooterLabel'
import BrandSwiper from 'components/BrandSwiper'
import { filterName, filterDownLoadAppIcon } from 'utils/filter'

const FooterDisclamier = tw.div`py-4 text-secondary-midnightBlue border-t-[1px] border-solid border-secondary-midnightBlue block text-xs md:text-sm lg:flex lg:items-center lg:flex-row-reverse lg:justify-end`
const FooterPolicy = tw.div`px-4 border-l-0 cursor-pointer my-2 border-solid border-secondary-midnightBlue lg:py-0`
const App = tw.img`w-auto cursor-pointer h-8 object-contain active:opacity-30`

function Footer({ profileData }) {
    const { onCloseSimpleBackdrop, onOpenSimpleBackdrop } = useSimpleBackdropStore()
    const { enqueueSnackbar } = useSnackbar()
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const [isENewIndicator, setIsENewIndicator] = useState(false)
    const [refreshToken, setRefreshToken] = useState(GetCookie('ktspSsoRefreshToken'))

    const updateRefreshToken = () => {
        setRefreshToken(GetCookie('ktspSsoRefreshToken'))
    }

    const { data: menu } = useQuery({
        queryKey: ['getFooter'],
        queryFn: () =>
            footer.getFooter({
                lang: 'en_US',
                platForm: 'Web',
            }),
        select: (res) => res?.menu,
    })

    const { isPending: isLandingPending } = useQuery({
        queryKey: ['landingPage', language],
    })

    const updateSSOProfile = useMutation({
        mutationFn: myProfile.updateSSOProfile,
        onSuccess: () => {
            setIsENewIndicator(true)
            enqueueSnackbar(t('footer.subscribeSuccess'), { variant: 'success' })
            onCloseSimpleBackdrop()
        },
        onError: () => {
            enqueueSnackbar(t('footer.subscribeFailed'), { variant: 'error' })
            onCloseSimpleBackdrop()
        },
    })

    useEffect(() => {
        updateRefreshToken()
    }, [document.cookie])

    if (isLandingPending) return null

    return (
        <footer
            onClick={() => {
                updateRefreshToken()
            }}
            className='w-full bg-[#F5F5F5] overflow-hidden pt-3 md:pt-8'
            id='main-footer'
        >
            {menu && (
                <div className='bg-container'>
                    <BrandSwiper menu={menu} />
                    <div className='w-full pb-5 lg:py-10 mt-2 lg:flex lg:flex-1 lg:flex-wrap lg:justify-between'>
                        <div className='px-2'>
                            {menu.eventTicketing && <FooterLabel menuFirst={menu.eventTicketing} isPoint={true} />}
                            {menu.bookingProgramme && <FooterLabel menuFirst={menu.bookingProgramme} />}
                            {menu.shopDine && <FooterLabel menuFirst={menu.shopDine} />}
                        </div>
                        <div className='px-2'>
                            {menu.visitExperience && <FooterLabel menuFirst={menu.visitExperience} />}
                            {menu.pearlClub && <FooterLabel menuFirst={menu.pearlClub} isPoint={true} />}
                        </div>
                        <div className='px-2'>
                            {menu.hireOurVenues && <FooterLabel menuFirst={menu.hireOurVenues} />}
                            {menu.connectKtsp && <FooterLabel menuFirst={menu.connectKtsp} />}
                        </div>
                        <div className='px-2 w-full md:w-[363px] space-y-8 mt-7 lg:mt-0'>
                            {refreshToken && profileData && profileData.eNewIndicator === 'false' && (
                                <div className='flex justify-between items-center'>
                                    <div className='text-body2 leading-8 text-secondary-midnightBlue mb-2'>
                                        {t('footer.becomeKTSPMember')}
                                    </div>
                                    <Button
                                        disabled={profileData.eNewIndicator !== 'false' || updateSSOProfile.isPending}
                                        onClick={() => {
                                            onOpenSimpleBackdrop()
                                            updateSSOProfile.mutate({
                                                ...profileData,
                                                eNewIndicator: true,
                                                openIdUUID: '',
                                                province: profileData?.province ?? '',
                                                district: profileData?.district ?? '',
                                                campaignCode: profileData?.campaignCode ?? '',
                                                referralCode: profileData?.referralCode ?? '',
                                            })
                                        }}
                                        className={
                                            'h-8 text-white normal-case text-sm font-semiboldFamily w-[117px] px-4 py-1' +
                                            (updateSSOProfile.isPending || isENewIndicator
                                                ? ' bg-secondary-grey'
                                                : ' bg-website-gradation ')
                                        }
                                        variant='contained'
                                    >
                                        {refreshToken ? t('footer.subscribe') : t('footer.registerNow')}
                                    </Button>
                                </div>
                            )}
                            {!GetCookie('ktspSsoRefreshToken') && (
                                <div>
                                    <div className='flex justify-between'>
                                        <div className='text-body2 leading-8 text-secondary-midnightBlue mb-2'>
                                            {t('footer.becomeKTSPMember')}
                                        </div>
                                        <Button
                                            onClick={() => registerBtnFun(language, true)}
                                            className='normal-case text-base font-semiboldFamily w-[117px] px-4 py-1 shadow-none'
                                            variant='primaryRed'
                                            size='small'
                                        >
                                            {t('footer.registerNow')}
                                        </Button>
                                    </div>
                                    <div className='text-sm font-medium text-secondary-midnightBlue'>
                                        {t('footer.memberAbout')}
                                    </div>
                                </div>
                            )}
                            <div>
                                <div className='text-body2 text-secondary-midnightBlue mb-4'>
                                    {t('footer.stayConnected')}
                                </div>
                                <div className='flex gap-2'>
                                    {menu?.socialMediaLinks.length > 0 &&
                                        menu.socialMediaLinks.map((icon, index) => {
                                            return (
                                                <img
                                                    onClick={() => icon.link && window.open(icon.link)}
                                                    key={index}
                                                    alt='loading'
                                                    className='w-6 h-6 cursor-pointer object-contain active:opacity-30'
                                                    src={icon.iconUrl}
                                                />
                                            )
                                        })}
                                </div>
                            </div>

                            <div>
                                <div className='text-body2 text-secondary-midnightBlue mb-4'>
                                    {t('footer.downloadOurApp')}
                                </div>
                                <div className='flex gap-4'>
                                    {menu?.downloadApp.length > 0 &&
                                        menu.downloadApp.map((app, index) => {
                                            return (
                                                <App
                                                    key={index}
                                                    onClick={() => window.open(app.link)}
                                                    alt='loading'
                                                    src={filterDownLoadAppIcon(app, language)}
                                                />
                                            )
                                        })}
                                </div>
                            </div>

                            <div>
                                <div className='text-body2 text-secondary-midnightBlue mb-4'>
                                    {t('footer.awardOfExcellent')}
                                </div>
                                <div className='flex gap-4 flex-wrap'>
                                    {menu.award &&
                                        menu.award.length > 0 &&
                                        menu.award.map((item, index) => {
                                            return (
                                                <div
                                                    key={index}
                                                    className={'h-[52px] ' + (index === 0 ? 'w-[140px]' : 'w-[95px]')}
                                                >
                                                    <img
                                                        alt='loading'
                                                        src={item.iconUrl}
                                                        className='w-full h-full object-contain'
                                                    />
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterDisclamier className='font-medium'>
                        <div className='flex'>
                            <FooterPolicy onClick={() => (window.location.href = menu?.privacy)}>
                                {t('footer.privacyPolicy')}
                            </FooterPolicy>
                            <FooterPolicy className='border-l-[1px]' onClick={() => (window.location.href = menu?.tnc)}>
                                {t('footer.termsConditions')}
                            </FooterPolicy>
                            <FooterPolicy
                                className='border-l-[1px]'
                                onClick={() => (window.location.href = menu?.disclaimer)}
                            >
                                {t('footer.disclaimer')}
                            </FooterPolicy>
                        </div>
                        <div className='px-5'>{filterName(menu?.copyright, language)}</div>
                    </FooterDisclamier>
                </div>
            )}
        </footer>
    )
}

export default Footer
