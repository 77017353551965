import React from 'react'
import { imageViewer } from 'store/authAtom'
function WrapBox({ image, component, bomComponent = null }) {
    const { showImage, setImage, setType } = imageViewer()
    return (
        <div>
            <div className='text-secondary-midnightBlue space-y-6 lg:flex lg:gap-6'>
                <img
                    alt=''
                    onClick={() => {
                        setImage(image)
                        setType('transport')
                        showImage()
                    }}
                    src={image}
                    className='w-full cursor-pointer aspect-[4/3] object-cover lg:w-[66%]'
                />
                <div className='w-full lg:w-[33%] flex flex-col justify-center'>{component}</div>
            </div>
            {bomComponent}
        </div>
    )
}

export default WrapBox
