import React from 'react'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

import NavigationPoint from 'components/NavigationPoint'
import FromContact from 'page/ConnectWithKTSP/ContactUs/components/FromContact'

const Wrap = tw.div`w-full bg-cover bg-center bg-no-repeat pt-6`
const Line = tw.div`border-solid border-b-[1px] flex-1 border-secondary-grey`
const Label = tw.div`py-6 px-5 flex justify-between cursor-pointer items-center text-secondary-midnightBlue text-base active:opacity-50`
function EnquiryForm() {
    const { t } = useTranslation()
    return (
        <Wrap className='bg-exploreBg'>
            <div className='bg-container'>
                <NavigationPoint
                    firstNav={t('navigationBar.connectWithKTSP')}
                    lastNav={t('FAQ.contactUsBtn')}
                    firstNavLink='/connectWithKTSP/aboutKTSP'
                />
                <div className='text-center w-full pt-3 mx-auto pb-8 md:w-[461px] xl:w-[561px]'>
                    <div className='text-secondary-midnightBlue mb-3 text-h2 uppercase'>
                        {t('contactUs.generalEnquiryForm')}
                    </div>
                    <div className='text-secondary-midnightBlue text-sm font-medium pb-8'>
                        {t('contactUs.generalEnquiryFormAbout')}
                    </div>
                    <FromContact />
                    {/* <div>
                    <div className='flex gap-2 items-center'>
                        <Line></Line>
                        <div>Or</div>
                        <Line></Line>
                    </div>
                    <Label>
                        <div>Enquiry for Hire our Venue</div>
                        <ArrowForwardIosIcon />
                    </Label>
                </div> */}
                </div>
            </div>
        </Wrap>
    )
}

export default EnquiryForm
