import tw, { styled } from 'twin.macro'
import { CardMedia, Card, Typography, Chip } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CARD_BOX, RedLine } from 'constants/constant'
import bookingCardshadow from 'resources/images/bookingCardshadow.png'
import { filterName } from 'utils/filter'
import { cn } from 'utils/cn'
const ChipWrap = styled(Chip)`
    ${tw``}
`

export default function FacilitiesBookingCard({ card }) {
    const navigate = useNavigate()
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const isFull =
        card.enrlMethod && card.enrlMethod.includes('FCFS') && card.actionLink && card?.actionLink === 'disabled'
    const labelNow = isFull ? t('facilitiesBooking.full') : card.label
    return (
        <Card
            onClick={() => navigate(`/facilitiesBookingDetail?id=${card.vbsFacilityId}`)}
            className='relative w-full shadow-none cursor-pointer bg-transparent group rounded-none'
        >
            <div className='w-full z-20 absolute bottom-0 h-[80px]'>
                <img alt='' src={bookingCardshadow} className='w-full h-full object-cover' />
            </div>
            <div>
                <div className={CARD_BOX + 'mb-3 w-full aspect-[4/3] xl:h-[267px]'}>
                    {card?.cardImage && (
                        <CardMedia
                            component='img'
                            className='object-cover w-full h-full group-hover:scale-110 transition-all duration-300'
                            image={card?.cardImage}
                            alt='loading'
                            loading='lazy'
                        />
                    )}
                    {labelNow && (
                        <div
                            className={cn(
                                'font-semiboldFamily text-sm leading-[150%] absolute top-0 left-0 px-[15px] py-[6px] text-white',
                                isFull ? 'bg-secondary-grey' : 'bg-primaryRed-ktRed',
                            )}
                        >
                            {labelNow}
                        </div>
                    )}
                    {card.facilityName && (
                        <Typography
                            variant='h6'
                            className='absolute bottom-5 z-30 left-5 text-white text-xl md:text-[28px] md:leading-[28px]'
                        >
                            {card.facilityName}
                        </Typography>
                    )}
                    <RedLine />
                </div>
            </div>
        </Card>
    )
}
