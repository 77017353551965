import React, { useState } from 'react'
import tw from 'twin.macro'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { useTranslation } from 'react-i18next'
import { isNotEmpty } from 'ramda'
import { Icon } from '@mui/material'
import { AddressIcon, EmailIcon, FaxIcon, PhoneIcon } from './Icons'
import { onClickSendEmail } from 'utils/email'
const Label = tw.div`py-6 border-solid border-secondary-grey flex justify-between cursor-pointer gap-2 items-center text-secondary-midnightBlue active:opacity-50`
function MothodLabel({ item }) {
    const { t } = useTranslation()
    const [isExpand, setExpand] = useState(false)

    return (
        <div className='border-b-[1px] w-full break-words'>
            <Label className='font-semiboldFamily text-h4' onClick={() => setExpand(!isExpand)}>
                <div style={{ overflowWrap: 'anywhere' }}>{item.departmentName}</div>
                {!isExpand ? <div className='icon-plus text-sm' /> : <div className='icon-minus text-sm' />}
            </Label>
            {isExpand && (
                <div>
                    {item.contactNumber && (
                        <div className='pb-4'>
                            <div
                                style={{ overflowWrap: 'anywhere' }}
                                className='flex gap-1 text-base font-semiboldFamily'
                            >
                                <PhoneIcon className='mt-1 text-secondary-midnightBlue size-5' />
                                <div>
                                    <div className='pb-2'>{t('contactUs.Phone') + ':'}</div>
                                    <div className='text-body3 '>{item.contactNumber}</div>
                                    {<div className='text-body3 '>{item.hotlineServiceHour}</div>}
                                </div>
                            </div>
                        </div>
                    )}
                    {item.faxNumber && (
                        <div className='pb-4'>
                            <div
                                style={{ overflowWrap: 'anywhere' }}
                                className='flex gap-1 text-base font-semiboldFamily'
                            >
                                <FaxIcon className='mt-1 text-secondary-midnightBlue size-5' />
                                <div>
                                    <div className='pb-2'>{t('contactUs.faxNumber') + ':'}</div>
                                    <div className='text-body3 '>{item.faxNumber}</div>
                                </div>
                            </div>
                        </div>
                    )}
                    {item.email && (
                        <div className='pb-4'>
                            <div
                                style={{ overflowWrap: 'anywhere' }}
                                className='flex gap-1 text-base font-semiboldFamily'
                            >
                                <div className='mt-[2px] size-5 grid place-items-center'>
                                    <EmailIcon className='text-secondary-midnightBlue size-4' />
                                </div>
                                <div>
                                    <div className='pb-2'>{t('contactUs.emailTitle') + ':'}</div>
                                    <div className='text-body3 cursor-pointer' onClick={() => onClickSendEmail(item.email)}>
                                        {item.email}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {item.address && (
                        <div style={{ overflowWrap: 'anywhere' }} className='pb-4 w-full'>
                            <div className='flex gap-1 text-base font-semiboldFamily'>
                                <AddressIcon className='mt-1 text-secondary-midnightBlue size-5' />
                                <div>
                                    <div className='pb-2'>{t('contactUs.address') + ':'}</div>
                                    <div className='text-body3 '>{item.address}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default MothodLabel
