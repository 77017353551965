import React, { useMemo, useState, useEffect } from 'react'
import FilledInput from '@mui/material/FilledInput'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined'
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined'
import { Button } from '@mui/material'

import Title from 'components/shopDine/Title'
import FilterModal from 'components/shopDine/allProductsComponents/FilterModal'
import FeaturedCard from 'components/landingComponents/FeaturedCard'
import Tag from 'components/shopDine/allProductsComponents/Tag'
import TypeMenu from 'components/shopDine/allProductsComponents/TypeMenu'
import { useDisclosure } from 'utils/useDisclosure'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query'
import { datesSelect, filterName, formatStartDateEndDate, newIdArray } from 'utils/filter'
import dayjs from 'dayjs'
import { eventServices } from 'service/eventService'
import { Calendar } from 'components/Calendar'
import Loading from 'components/Loading'
import PaginationButton from 'components/PaginationButton'
import TitleSearchComponent from 'components/shopDine/allProductsComponents/TitleSearchComponent'
import ShopCard from './ShopCard'
import { restaurant, shopDineService } from 'service/restaurantService'
function AllProduct({ name, isFilter, firstTypeName, secondTypeName }) {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const [typesSelected, setTypesSelected] = useState([])
    const [venuesSelected, setVenuesSelected] = useState([])
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [listType, setListType] = useState('grid')
    const [searchVal, setSearchVal] = useState('')
    const [isSearch, setIsSearch] = useState(true)

    const selectedCategories = useMemo(() => {
        return [...typesSelected, ...venuesSelected]
    }, [typesSelected, venuesSelected])

    const { data: filterInfo, isFetching: filterInfoIsFetching } = useQuery({
        queryKey: ['getShopFilterInfo'],
        queryFn: () => shopDineService.getShopDineFilterInfo({ lang: language, type: 'shop' }),
    })

    const getEventConditions = {
        lang: language,
        maxRecord: listType === 'grid' ? 20 : 10,
        searchText: searchVal.trim(),
        cate: newIdArray(typesSelected),
        venue: newIdArray(venuesSelected),
        type: 'shop',
    }
    const { data, fetchNextPage, refetch, isFetching, isPending } = useInfiniteQuery({
        queryKey: ['getShopList', language, typesSelected, venuesSelected, listType], // key
        queryFn: ({ pageParam = 0 }) => shopDineService.getShopDineList({ ...getEventConditions, startRow: pageParam }),
        getNextPageParam: (lastPage, pages) => {
            return pages.length * (listType === 'grid' ? 20 : 10)
        },
    })

    const hasFilter = useMemo(() => {
        return typesSelected.length > 0 || venuesSelected.length > 0
    }, [typesSelected, venuesSelected])
    useEffect(() => {
        refetch()
    }, [])
    if (filterInfoIsFetching) {
        return <Loading />
    }

    return (
        <div className='bg-white pt-16 bg-container'>
            <FilterModal
                firstTypeName={firstTypeName}
                secondTypeName={secondTypeName}
                venues={filterInfo?.venue ?? []}
                types={filterInfo?.cate ?? []}
                typesSelected={typesSelected}
                setTypesSelected={setTypesSelected}
                venuesSelected={venuesSelected}
                setVenuesSelected={setVenuesSelected}
                open={isOpen}
                handleClose={onClose}
                // dateType={dateType}
                setDateType={() => {}}
                // startDate={startDate}
                setStartDate={() => {}}
                // endDate={endDate}
                setEndDate={() => {}}
                // dates={dates}
                resetCw={t('shop.reset')}
                confirmCw={t('shop.confirm')}
            />
            <TitleSearchComponent
                name={name}
                searchVal={searchVal}
                setSearchVal={setSearchVal}
                onBlurFun={() => !isFetching && refetch()}
                setIsSearch={setIsSearch}
            />
            <div className='flex justify-between items-center'>
                <div className='pt-[24px] flex flex-wrap items-center gap-[10px]'>
                    <div className='hidden md:flex md:flex-wrap md:gap-[10px] md:items-center'>
                        <TypeMenu
                            name={firstTypeName}
                            num={typesSelected.length}
                            labels={filterInfo?.cate ?? []}
                            typesSelected={typesSelected}
                            setTypesSelected={setTypesSelected}
                            resetCw={t('shop.clearAll')}
                            confirmCw={t('shop.showResult')}
                        />
                        <TypeMenu
                            name={secondTypeName}
                            num={venuesSelected.length}
                            labels={filterInfo?.venue ?? []}
                            typesSelected={venuesSelected}
                            setTypesSelected={setVenuesSelected}
                            resetCw={t('shop.clearAll')}
                            confirmCw={t('shop.showResult')}
                        />
                    </div>
                    {isFilter && (
                        <div
                            onClick={() => onOpen()}
                            className='md:pl-[10px] md:border-secondary-midnightBlue md:border-solid md:border-l-[1px]'
                        >
                            <div className='w-[160px] py-[6px] pl-[16px] pr-[10px] flex justify-between items-center cursor-pointer rounded-[20px] text-secondary-midnightBlue border-secondary-midnightBlue border-solid border-[1px]'>
                                <div className='text-base font-medium'>{t('eventPage.allFilter')}</div>
                                <div className='flex items-center'>
                                    <div className='icon-filter font-bold' />
                                </div>
                            </div>
                        </div>
                    )}
                    {hasFilter && (
                        <div
                            onClick={() => {
                                setTypesSelected([])
                                setVenuesSelected([])
                            }}
                            className='text-base font-semiboldFamily text-[#0A173D] cursor-pointer underline'
                        >
                            {t('eventPage.reset')}
                        </div>
                    )}
                </div>
                <div className='text-lg text-secondary-grey flex items-center gap-2 cursor-pointer'>
                    <div
                        onClick={() => setListType('grid')}
                        className={listType === 'grid' ? 'text-secondary-midnightBlue' : ''}
                    >
                        <GridOnOutlinedIcon />
                    </div>
                    <div
                        onClick={() => setListType('list')}
                        className={listType === 'list' ? 'text-secondary-midnightBlue' : ''}
                    >
                        <FormatListBulletedIcon />
                    </div>
                    {/* <div
                        onClick={() => setListType('calendar')}
                        className={listType === 'calendar' ? 'text-secondary-midnightBlue' : ''}
                    >
                        <CalendarTodayOutlinedIcon />
                    </div> */}
                </div>
            </div>
            {hasFilter && (
                <>
                    <div className='flex gap-2 flex-wrap mt-6'>
                        {selectedCategories.length > 0 &&
                            selectedCategories.map((item, index) => {
                                return (
                                    <Tag
                                        key={index}
                                        text={filterName(item, language)}
                                        fun={() => {
                                            if (
                                                filterInfo?.cate?.some(
                                                    (itemType) =>
                                                        itemType.id === item.id &&
                                                        itemType.nameEn === item.nameEn &&
                                                        itemType.nameZh === item.nameZh &&
                                                        itemType.nameCn === item.nameCn,
                                                )
                                            ) {
                                                const arr = typesSelected.filter((val) => val !== item)
                                                setTypesSelected([...arr])
                                            } else {
                                                const arr = venuesSelected.filter((val) => val !== item)
                                                setVenuesSelected([...arr])
                                            }
                                        }}
                                    />
                                )
                            })}
                    </div>
                    <div className='text-h4 mt-6 text-secondary-midnightBlue'>
                        {isSearch ? data?.pages[0]?.allDataSize ?? 0 : 0}
                        {t('shop.searchResult')}
                    </div>
                </>
            )}

            {isPending && <Loading />}
            <div className='mt-4 md:mt-8 lg:mt-[60px]'>
                <div
                    className={
                        listType === 'grid' &&
                        'grid gap-x-5 gap-y-8 grid-cols-2 sm:grid-cols-3 lg:gap-y-16 lg:grid-cols-4'
                    }
                >
                    {isSearch &&
                        data &&
                        data?.pages.map((page) => {
                            return page.data.map((card, i) => {
                                return (
                                    <ShopCard
                                        index={i}
                                        key={card + i}
                                        listType={listType}
                                        card={card}
                                        path='/shopsDetail'
                                    />
                                )
                            })
                        })}
                </div>
            </div>
            {isSearch && (
                <PaginationButton
                    isDisabled={
                        data?.pages.reduce((acc, page) => acc + page.data.length, 0) === data?.pages[0]?.allDataSize
                    }
                    fun={() => {
                        fetchNextPage()
                    }}
                    nowCardsLength={data?.pages.reduce((acc, page) => acc + page.data.length, 0) ?? 0}
                    allCardsLength={data?.pages[0]?.allDataSize ?? 0}
                    cw={t('shop.showMore')}
                />
            )}
        </div>
    )
}

export default AllProduct
