import React from 'react'

function TitleGradient({ text, className = '' }) {
    return (
        <h2
            className={
                'pb-3 md:pb-6 line-clamp-5 bg-website-gradation text-transparent bg-clip-text text-h1 inline-block max-w-[756px] xl:max-w-[880px] ' +
                className
            }
        >
            {text}
        </h2>
    )
}

export default TitleGradient
