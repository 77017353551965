import tw from 'twin.macro'
import { useForm, Controller } from 'react-hook-form'
import { Button, TextField, MenuItem } from '@mui/material'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'

import { landing } from 'service/landingService'
import { loginReg } from 'service/authService'
import { user } from 'service/userService'
import { yupResolver } from '@hookform/resolvers/yup'
import { useValidationI18n } from 'utils/validationI18n'
import { getNameByCode } from 'utils/filter'
import { useCallback, useEffect } from 'react'

const From = tw.form`w-full flex flex-col gap-y-4`

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
}

const SUBJECT_KEYS = [
    'generalEnquiries',
    'complaintFeedback',
    'membershipRegistration',
    'websiteApp',
    'corporateEvents',
    'entertainmentEvents',
    'sportsEvents',
    'ticketing',
    'communitySportsFacilityBookings',
    'retail',
    'casualLeasing',
    'corporatePartnership',
    'hospitalityEnquiries',
    'facilityManagement',
    'carParkEnquiries',
    'lostFound',
    'incidentReport',
    'medicalCare',
    'mediaPublicRelations',
    'banquetsfunctions',
    'cateringKTS',
    'cateringKTA',
]

function FromContact() {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const { validationSchema } = useValidationI18n()

    const { enqueueSnackbar } = useSnackbar()

    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
        trigger,
    } = useForm({
        resolver: yupResolver(
            yup.object({
                emailId: validationSchema.emailId,
                countryCode: validationSchema.countryCode,
                message: validationSchema.message,
                name: validationSchema.name,
                subject: validationSchema.subject,
            }),
        ),
        defaultValues: {
            title: '',
            subject: '',
        },
    })

    const { data: countryCodeList } = useQuery({
        queryKey: ['getCountryCodeList', language],
        queryFn: () => user.getCountryCodeList({ lang: language }),
        placeholderData: {
            codeList: [
                {
                    code: 852,
                },
            ],
            countryCodeList: [{ name: '', isoCode: '', area: [] }],
        },
    })
    const { data: getTitles } = useQuery({
        queryKey: ['getTitle', language],
        queryFn: () =>
            loginReg.getTitle({
                lang: language,
            }),
        placeholderData: [],
    })
    const contactUs = useMutation({
        mutationFn: landing.contactUs,
        onSuccess: () => {
            reset()
            enqueueSnackbar(t('contactUs.tipSuccess'), { variant: 'success' })
        },
        onError: (e) => {
            enqueueSnackbar(e, { variant: 'error' })
        },
    })
    const onSubmit = (form) => {
        const title = getTitles?.find((item) => item.code === form.title)?.name || ''

        contactUs.mutate({
            country: form.countryCode,
            email: form.emailId,
            lang: language,
            message: form.message,
            name: form.name,
            phone: form.mobileNo,
            subject: form.subject,
            title,
            internalTitle: form.title,
        })
    }

    const updateErrorMessages = useCallback(() => {
        Object.keys(errors).forEach((fieldName) => {
            trigger(fieldName)
        })
    }, [errors, trigger])

    useEffect(() => {
        updateErrorMessages()
    }, [language, updateErrorMessages])

    return (
        <From className='space-y-3' onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
            <div className='flex space-x-6'>
                <Controller
                    name='title'
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            select
                            variant='outlined'
                            color='secondary'
                            className='w-[100px] md:w-[168px] text-start'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!error}
                            helperText={error ? error?.message : null}
                            SelectProps={{
                                className: 'bg-white',
                                MenuProps,
                                displayEmpty: true,
                                renderValue: (selected) => {
                                    if (selected.length === 0) {
                                        return (
                                            <span className='w-full text-start text-[#A2A2A2]'>
                                                {t('contactUs.title')}
                                            </span>
                                        )
                                    }
                                    return getNameByCode(selected, getTitles)
                                },
                            }}
                        >
                            {getTitles.map((option) => (
                                <MenuItem key={option.code} value={option.code}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />

                <TextField
                    {...register('name', {
                        required: true,
                    })}
                    className='flex-1'
                    variant='outlined'
                    color='secondary'
                    placeholder={t('contactUs.name')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        className: 'bg-white overflow-hidden rounded',
                    }}
                    error={!!errors?.name}
                    helperText={errors?.name && errors?.name?.message}
                />
            </div>
            <TextField
                {...register('emailId', {
                    required: true,
                })}
                fullWidth
                variant='outlined'
                color='secondary'
                placeholder={t('contactUs.email')}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    className: 'bg-white overflow-hidden rounded',
                }}
                error={!!errors?.emailId}
                helperText={errors?.emailId && errors?.emailId?.message}
            />
            <div className='flex space-x-6'>
                <TextField
                    {...register('countryCode', {
                        required: true,
                    })}
                    select
                    variant='outlined'
                    color='secondary'
                    className='w-[100px] md:w-[168px] text-start'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    SelectProps={{
                        className: 'bg-white',
                        defaultValue: '852',
                        MenuProps,
                    }}
                >
                    {countryCodeList?.codeList.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                            + {option.code}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    {...register('mobileNo', {
                        required: true,
                    })}
                    className='flex-1'
                    variant='outlined'
                    color='secondary'
                    placeholder={t('contactUs.contactNumber')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{
                        className: 'bg-white overflow-hidden rounded',
                    }}
                    error={!!errors?.mobileNo}
                    helperText={errors?.mobileNo && errors?.mobileNo?.message}
                />
            </div>
            <Controller
                name='subject'
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <TextField
                        {...field}
                        select
                        variant='outlined'
                        color='secondary'
                        className='w-full text-start'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={!!error}
                        helperText={error ? error?.message : null}
                        SelectProps={{
                            className: 'bg-white',
                            MenuProps,
                            displayEmpty: true,
                            renderValue: (selected) => {
                                if (selected.length === 0) {
                                    return (
                                        <span className='w-full text-start text-[#A2A2A2]'>
                                            {t('contactUs.subject')}
                                        </span>
                                    )
                                }
                                return t(`contactUs.${selected}`)
                            },
                        }}
                    >
                        {SUBJECT_KEYS.map((subject) => (
                            <MenuItem key={subject} value={subject}>
                                {t(`contactUs.${subject}`)}
                            </MenuItem>
                        ))}
                    </TextField>
                )}
            />
            <TextField
                {...register('message', {
                    required: true,
                })}
                fullWidth
                variant='outlined'
                type='text'
                multiline
                rows={3}
                color='secondary'
                placeholder={t('contactUs.message')}
                InputLabelProps={{
                    shrink: true,
                }}
                error={!!errors?.message}
                helperText={errors?.message && errors?.message?.message}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: '#fff',
                    },
                }}
            />

            <Button
                disabled={contactUs.isPending}
                variant='primaryRed'
                size='large'
                className=' mx-auto mt-4 h-fit w-[200px] leading-8 text-lg'
                fullWidth
                type='submit'
            >
                {t('contactUs.submit')}
            </Button>
        </From>
    )
}

export default FromContact
