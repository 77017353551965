// Package imports
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tab, Tabs } from '@mui/material'

// Project imports
import Banner from 'components/shopDine/Banner'
// Folder imports
import HeroSection from './components/HeroSection'
import FeaturedSection from './components/FeaturedSection'
import PhotoGallerySection from './components/PhotoGallerySection'
import ForMoreEnquirySection from './components/ForMoreEnquirySection'
import pearlClubBannerImage from 'resources/images/pearl-club/topBanner.jpg'

function PearlClub() {
	const {
			t,
	} = useTranslation()

	const banner = {
			image: pearlClubBannerImage,
			title: t('pearlClub.mainTitle'),
			about: t('pearlClub.mainAbout'),
	}

	return (
			<div className='bg-white text-secondary-midnightBlue'>
					<section>
						<Banner banner={banner} />
					</section>
					<section>
						<HeroSection />
					</section>
					<section>
						<FeaturedSection />
					</section>
					<section>
						<PhotoGallerySection />
					</section>
					<section>
						<ForMoreEnquirySection />
					</section>
			</div>
	)
}

export default PearlClub
