import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

const ExperienceHighlight = ({ highlightList }) => {
    const imgList = highlightList.slice(1, 5)

    const [isOpen, setIsOpen] = useState(false)
    const [imgViewIndex, setImgViewIndex] = useState(null)

    const handleOpenImgViewModal = (imgIndex) => {
        setImgViewIndex(imgIndex)
        setIsOpen(true)
    }

    const slideNext = () => {
        setImgViewIndex((pre) => {
            if (pre === highlightList.length - 1) {
                return pre
            } else {
                return pre + 1
            }
        })
    }

    const slidePre = () => {
        setImgViewIndex((pre) => {
            if (pre === 0) {
                return pre
            } else {
                return pre - 1
            }
        })
    }

    return (
        <div className='flex w-full md:w-[65%] gap-2 md:gap-4 h-auto'>
            <ImgViewModal
                imgView={highlightList[imgViewIndex]}
                imgViewIndex={imgViewIndex}
                totalNum={highlightList.length}
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                slideNext={slideNext}
                slidePre={slidePre}
            />
            <div className='max-w-[50%] aspect-[4/3]'>
                <img
                    src={highlightList[0]}
                    alt=''
                    className='object-cover h-full w-full cursor-pointer'
                    onClick={() => handleOpenImgViewModal(0)}
                />
            </div>
            {highlightList?.length === 2 ? (
                <div className='flex-1 aspect-[4/3]'>
                    <img
                        src={imgList[0]}
                        alt=''
                        className='object-cover h-full w-full cursor-pointer'
                        onClick={() => handleOpenImgViewModal(1)}
                    />
                </div>
            ) : (
                <div
                    className='flex-1 grid grid-cols-2 grid-rows-2 gap-2 md:gap-4 flex-grow'
                    style={{ alignItems: 'stretch' }}
                >
                    {imgList.map((item, index) => {
                        return (
                            <div className='relative aspect-[4/3] '>
                                <img
                                    className={`w-full h-full shrink-1 object-cover cursor-pointer ${
                                        index === 1 && 'overflow-hidden md:rounded-tr-[60px] rounded-tr-[25px]'
                                    }`}
                                    src={item}
                                    alt=''
                                    onClick={() => handleOpenImgViewModal(index + 1)}
                                />
                                {highlightList.length >= 6 && index === 3 && (
                                    <div
                                        className='bg-[#00000080] w-full h-full absolute top-0 flex items-center justify-center cursor-pointer'
                                        onClick={() => handleOpenImgViewModal(index + 1)}
                                    >
                                        <div className='text-white text-center text-h3 font-[500]'>{`+${
                                            highlightList.length - 5
                                        }`}</div>
                                    </div>
                                )}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

const ImgViewModal = ({ isOpen, onClose, slidePre, slideNext, imgView, imgViewIndex, totalNum }) => {
    const isMobile = useMediaQuery('(max-width: 768px)') //md
    const {
        i18n: { language },
    } = useTranslation()
    return (
        <Modal
            open={isOpen}
            onClose={() => onClose()}
            sx={{
                '.MuiBox-root:focus-visible': {
                    outline: 'none',
                    border: 'none',
                },
            }}
            slotProps={{
                root: { className: 'shadow-card' },
                backdrop: {
                    className:
                        'pt-[82px] md:pt-[108px] backdrop-blur-[10px] bg-[#222222] md:bg-[#333] md:bg-opacity-20',
                },
            }}
        >
            <div className='h-full w-full flex flex-col'>
                {isMobile && (
                    <IconButton
                        className='absolute top-[55px] right-[20px]'
                        disableRipple
                        onClick={() => onClose()}
                        aria-label='close'
                    >
                        <CloseIcon sx={{ color: 'white', fontSize: 28 }} />
                    </IconButton>
                )}
                <div className='flex-1 flex items-center justify-center md:gap-x-[41px]'>
                    <div
                        className={`icon-arrow_left text-2xl text-white sm:text-4xl cursor-pointer md:text-secondary-midnightBlue ${
                            isMobile && 'absolute z-10 left-0'
                        }`}
                        onClick={slidePre}
                    />
                    <div className='md:inline-flex relative w-[100%] lg:w-[54%]'>
                        {!isMobile && (
                            <IconButton
                                className='absolute -translate-y-full right-0 -top-2 p-0'
                                disableRipple
                                onClick={() => onClose()}
                                aria-label='close'
                            >
                                <CloseIcon
                                    sx={{ color: 'white', fontSize: 35 }}
                                    className='md:text-secondary-midnightBlue'
                                />
                            </IconButton>
                        )}
                        <div className='w-full'>
                            <img
                                className='h-auto w-full object-cover shadow-card'
                                id='map'
                                alt='loading'
                                src={imgView}
                            />
                            {!isMobile && (
                                <div
                                    className={`w-full text-secondary-midnightBlue text-center mt-4 text-h5 ${
                                        language === 'en_US' ? 'font-kt-en' : 'font-kt-zh'
                                    }`}
                                >{`${imgViewIndex + 1} / ${totalNum}`}</div>
                            )}
                        </div>
                    </div>
                    <div
                        className={`icon-arrow_right text-2xl text-white sm:text-4xl cursor-pointer md:text-secondary-midnightBlue ${
                            isMobile && 'absolute z-10 right-0'
                        }`}
                        onClick={slideNext}
                    />
                </div>
                {isMobile && (
                    <div
                        className={`w-full text-white text-center mt-4 text-h5 mb-8 text-[20px]  ${
                            language === 'en_US' ? 'font-kt-en' : 'font-kt-zh'
                        }`}
                    >{`${imgViewIndex + 1} / ${totalNum}`}</div>
                )}
            </div>
        </Modal>
    )
}

export default ExperienceHighlight
