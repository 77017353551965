import React, { useState, useEffect } from 'react'

import Lottie from 'react-lottie'
import * as animationData from 'constants/white_dot.json'
function AnUrbanCilcle({ position = '', text = '' }) {
    const [lottieOptions, setLottieOptions] = useState({
        loop: true,
        autoplay: true,
        isStopped: false,
        animationData: animationData,
    })

    useEffect(() => {
        setLottieOptions({
            loop: true,
            autoplay: true,
            isStopped: false,
            animationData: animationData,
        })
    }, [position])

    return (
        <div className={'absolute cursor-pointer group mx-[100px] pt-3 ' + position}>
            <div className='relative'>
                <div
                    className={
                        'absolute left-1/2 -translate-x-1/2  whitespace-nowrap transition-all duration-500 ease-in-out bg-white px-5 py-[9px] text-secondary-midnightBlue font-semiboldFamily text-base leading-[22.4px] rounded-[50px] group-hover:opacity-100 group-hover:-top-12 group-hover:z-30 top-0 opacity-0 z-10'
                    }
                >
                    {text}
                </div>
                {lottieOptions && (
                    <div className={'size-10 absolute -translate-x-1/2 left-1/2 z-30'}>
                        <Lottie options={lottieOptions} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default AnUrbanCilcle
