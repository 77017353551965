import React, { useState, useEffect, useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { useFooterShow } from 'store/authAtom'
import { information } from 'service/informationService'
import NavigationPoint from 'components/NavigationPoint'
import HeritageCard from 'page/ConnectWithKTSP/HeritageMilestone/components/HeritageCard'
import Loading from 'components/Loading'
import { TabButton } from 'components/ButtonsTab'
import { faIR } from 'date-fns/locale'
import { cn } from 'utils/cn'
function HeritageMilestone() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const swiperRef = useRef(null)
    const { showFooter, hiddenFooter } = useFooterShow()
    const [currentIndex, setCurrentIndex] = useState(0)
    const [titleMenuIndex, setTitleMenuIndex] = useState(0)
    const [isScroll, setIsScroll] = useState(false)
    const [searchParams] = useSearchParams()
    const [historyType, setHistoryType] = useState(
        searchParams.get('val') ? 2 > parseInt(searchParams.get('val')) > 0 : 1,
    )

    const { data } = useQuery({
        queryKey: ['getTimeLine', language],
        queryFn: () => information.getTimeLine({ lang: language }),
        select: (data) => data[0],
    })

    const historyTypeBtns = useMemo(() => {
        if (data) {
            const heritage = data.historyTitleMenu?.some((item) => item.type === 1)
            const milestone = data.historyTitleMenu?.some((item) => item.type === 2)
            return heritage && milestone
                ? [t('aboutUs.heriage'), t('aboutUs.milestones')]
                : heritage && !milestone
                ? [t('aboutUs.heriage')]
                : [t('aboutUs.milestones')]
        }
        return []
    }, [data])
    const showFun = () => {
        setTimeout(showFooter, 200)
    }
    const hiddenFun = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setTimeout(hiddenFooter, 200)
    }
    useEffect(() => {
        if (data && data.timeOfhistories && data.timeOfhistories.length > 0) {
            currentIndex === data.timeOfhistories.length - 1 ? showFun() : hiddenFun()
        }
    }, [currentIndex])

    if (!data) {
        return <Loading />
    }
    if (!data.timeOfhistories[currentIndex]?.backgroundImage) {
        return <div className='w-[100vw] h-[100vh] bg-white'></div>
    }
    return (
        <div
            style={{ backgroundImage: `url("${data.timeOfhistories[currentIndex].backgroundImage}")` }}
            className='relative overflow-hidden bg-center transition-all duration-500 ease-in-out bg-no-repeat bg-cover z-10 h-[calc(100vh-81.47px)] min-h-[700px] md:h-[calc(100vh-107.1px)] md:min-h-[800px]'
        >
            <div
                className={
                    ' absolute top-0 left-0 bottom-0 right-0 -z-10 opacity-70' +
                    (historyType === 2 ? ' bg-[#6397C7]' : ' bg-[#222222]')
                }
            ></div>
            <div className='bg-container'>
                <NavigationPoint
                    firstNav={t('navigationBar.connectWithKTSP')}
                    lastNav={t('connectWithKtsp.milestonesHeritage')}
                    firstNavLink='/connectWithKTSP/aboutKTSP'
                    color={'text-white py-4'}
                />
            </div>
            <div className='overflow-hidden mt-8'>
                <h1 className='text-white uppercase bg-container text-h3'>{t('connectWithKtsp.milestonesHeritage')}</h1>
                {data && (
                    <div className='pt-4'>
                        <div className='flex gap-3 bg-container'>
                            {historyTypeBtns.map((btnVal, index) => {
                                return (
                                    <TabButton
                                        key={index}
                                        onClick={async () => {
                                            if (
                                                isScroll ||
                                                (index === 0 && currentIndex === 0) ||
                                                (index === 1 &&
                                                    currentIndex === data.historyTitleMenu[data.secondIndex].startIndex)
                                            ) {
                                                return
                                            }
                                            setIsScroll(true)
                                            setHistoryType(index + 1)
                                            if (index === 0) {
                                                setTitleMenuIndex(0)
                                                setCurrentIndex(0)
                                                swiperRef.current.slideTo(0)
                                            } else {
                                                setTitleMenuIndex(data.secondIndex)
                                                setCurrentIndex(data.historyTitleMenu[data.secondIndex].startIndex)
                                                swiperRef.current.slideTo(
                                                    data.historyTitleMenu[data.secondIndex].startIndex,
                                                )
                                            }
                                            setTimeout(() => setIsScroll(false), 800)
                                        }}
                                        selected={historyType === index + 1}
                                        className={cn(
                                            'min-w-fit w-fit max-w-fit z-30 border-0',
                                            isScroll && 'cursor-wait opacity-80 lg:opacity-100',
                                        )}
                                    >
                                        {btnVal}
                                    </TabButton>
                                )
                            })}
                        </div>
                        <HeritageCard
                            isScroll={isScroll}
                            swiperRef={swiperRef}
                            data={data}
                            currentIndex={currentIndex}
                            setCurrentIndex={setCurrentIndex}
                            titleMenuIndex={titleMenuIndex}
                            setTitleMenuIndex={setTitleMenuIndex}
                            historyType={historyType}
                            setHistoryType={setHistoryType}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}

export default HeritageMilestone
