import React from 'react'
import { useTranslation } from 'react-i18next'

import crossBoundaryEn_Tc from 'resources/images/Escalator_TC.jpg'
import crossBoundarySc from 'resources/images/Escalator_SC.jpg'
import WrapBox from 'page/Transportation/components/WrapBox'
import TaxiFerrryCrossCommon from 'page/Transportation/components/TaxiFerrryCrossCommon'
import WebBtn from './WebBtn'
function CrossBoundary() {
    const { i18n: {language}, t } = useTranslation()
    return (
        <WrapBox
            image={language === 'zh_CN' ? crossBoundarySc : crossBoundaryEn_Tc}
            component={
                <div>
                    <TaxiFerrryCrossCommon firstTitle={t('transport.byCrossBoundary')} firstAbout={t('transport.crossBoundaryAbout')} />
                    <div style={{ gap: '16px', display: 'flex', flexDirection: 'row' }}>
                        <WebBtn text={t('transport.crossBoundary1')} url={"https://www.myeebus.com/eebusfans/"} />
                        <WebBtn text={t('transport.crossBoundary2')} url={"https://m.hkctgbus.com/#/layout/home"} />
                    </div>
                </div>
            }
        />
    )
}

export default CrossBoundary
