import React from 'react'
import { useTranslation } from 'react-i18next'

import WrapBox from 'page/Transportation/components/WrapBox'
import BusCommonComponent from 'page/Transportation/components/BusCommonComponent'
import TextContainer from 'page/Transportation/components/TextContainer'

import {sortedList} from './BusData.js'

import BusEn_Tc from 'resources/images/transportation_bus_TC.jpg'
import BusSc from 'resources/images/transportation_bus_SC.jpg'
import BusStation1 from 'resources/images/1_KowloonCityFerryPierBusTerminus.jpeg'
import BusStation2A from 'resources/images/2A_TheHKSocietyfortheBlind.jpg'
import BusStation2B from 'resources/images/2B_ChinaGasCompany.jpg'
import BusStation3 from 'resources/images/3_KowloonCityBBI-RegalOrientalHotel(A).jpeg'
import BusStation4_a from 'resources/images/4_KowloonCityBBI-RegalOrientalHotel(B).jpeg'
import BusStation4_b from 'resources/images/4_KowloonCityBBI-SungWongToiPark(C).jpeg'
import BusStation5A_a from 'resources/images/5A_Argyle Street Playground(MaTauChungRoad).jpeg'
import BusStation5A_b from 'resources/images/5A_ArgyleStreetPlayground(ArgyleStreetToWest).jpeg'
import BusStation5B from 'resources/images/5B_ArgyleStreetPlayground(ArgyleStreetToEast).jpeg'

function Bus() {
    const { i18n: {language}, t } = useTranslation()
    return (
        <WrapBox
            image={ language === "zh_CN" ? BusSc : BusEn_Tc}
            component={
                <TextContainer
                    title={t('transport.byBus')}
                    link={'https://www.kmb.hk/'}
                    isWeb={t('transport.busWeb')}
                    endWord={t('transport.busAboutEnd')}
                    about={t('transport.busAbout')}
                />
            }
            bomComponent={
                <BusCommonComponent
                    isBus={true}
                    topFun={() => {}}
                    busItems={[
                        {
                            isBus: true,
                            topFun: () => {},
                            image: BusStation1,
                            wording: {
                                routes: checkRoutes('Kowloon City Ferry Bus Terminus', language),
                                busStopTitle: t('transport.kowloonCityFerry'),
                                busStopNumber: "1",
                                walkDistDetail: t('transport.kowloonCityFerryDistance'),
                            },
                        },
                        {
                            isBus: true,
                            topFun: () => {},
                            image: BusStation2A,
                            wording: {
                                routes: checkRoutes('The HK Society for the Blind', language),
                                busStopTitle: t('transport.HKSocietyForTheBlind'),
                                busStopNumber: "2A",
                                walkDistDetail: t('transport.HKSocietyForTheBlindDistance'),
                            },
                        },
                        {
                            isBus: true,
                            topFun: () => {},
                            image: BusStation2B,
                            wording: {
                                routes: checkRoutes('China Gas Company', language),
                                busStopTitle: t('transport.chinaGasCompany'),
                                busStopNumber: "2B",
                                walkDistDetail: t('transport.chinaGasCompanyDistance'),
                            },
                        },
                        {
                            isBus: true,
                            topFun: () => {},
                            image: BusStation3,
                            wording: {
                                routes: checkRoutes('Kowloon City BBI - Regal Oriental Hotel (Platform A)', language),
                                busStopTitle: t('transport.regalOrientalHotelA'),
                                busStopNumber: "3",
                                walkDistDetail: t('transport.regalOrientalHotelADistance'),
                            },
                        },
                        {
                            isBus: true,
                            topFun: () => {},
                            image: BusStation4_a,
                            wording: {
                                routes: checkRoutes('Kowloon City BBI - Regal Oriental Hotel (Platform B)', language),
                                busStopTitle: t('transport.regalOrientalHotelB'),
                                busStopNumber: "4",
                                walkDistDetail: t('transport.regalOrientalHotelBDistance'),
                            },
                        },
                        {
                            isBus: true,
                            topFun: () => {},
                            image: BusStation4_b,
                            wording: {
                                routes: checkRoutes('Kowloon City BBI - Sung Wong Toi Park (Platform C)', language),
                                busStopTitle: t('transport.sungWongToiPark'),
                                busStopNumber: "4",
                                walkDistDetail: t('transport.sungWongToiParkDistance'),
                            },
                        },
                        {
                            isBus: true,
                            topFun: () => {},
                            image: BusStation5A_a,
                            wording: {
                                routes: checkRoutes('Argyle Street Playground (Ma Tau Chung Road outside Argyle Street Playground)', language),
                                busStopTitle: t('transport.maTauChungRoad'),
                                busStopNumber: "5A",
                                walkDistDetail: t('transport.maTauChungRoadDistance'),
                            },
                        },
                        {
                            isBus: true,
                            topFun: () => {},
                            image: BusStation5A_b,
                            wording: {
                                routes: checkRoutes('Argyle Street Playground (Argyle Street outside Argyle Street Playground)', language),
                                busStopTitle: t('transport.argyleStreet'),
                                busStopNumber: "5A",
                                walkDistDetail: t('transport.argyleStreetDistance'),
                            },
                        },
                        {
                            isBus: true,
                            topFun: () => {},
                            image: BusStation5B,
                            wording: {
                                routes: checkRoutes('Argyle Street Playground (Argyle Street outside Katherine Building)', language),
                                busStopTitle: t('transport.katherineBuilding'),
                                busStopNumber: "5B",
                                walkDistDetail: t('transport.katherineBuildingDistance'),
                            },
                        },
                        
                    ]}
                />
            }
        />
    )
}

const checkRoutes = (StationName, language) => { 
    const filteredList = sortedList.filter(item => item.BusStop1 === StationName || item.BusStop2 === StationName || item.BusStop3 === StationName );
    return filteredList.map(({routeNo, EN, TC, SC}) => ({busRoute: routeNo, busRouteAbout: language === "en_US"? EN : (language === "zh_HK" ? TC : SC)}))
}

export default Bus
