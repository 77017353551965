import React from 'react'
import { useTranslation } from 'react-i18next'

import NavigationPoint from 'components/NavigationPoint'
import Loading from 'components/Loading'
import Content from 'components/Content'

function TCPrivacyDisclaimerComponent({ isPending, content, title }) {
    const { t } = useTranslation()
    return (
        <div className='pt-[53px] pb-6 text-secondary-midnightBlue bg-container'>
            {/* home need to right copy righting */}
            <NavigationPoint firstNav={t('common.Home')} lastNav={title} firstNavLink={'/'} />
            <h1 className='text-h3 pt-6 pb-10 font-semiboldFamily'>{title}</h1>
            {isPending && <Loading />}
            {content && <Content data={content} />}
        </div>
    )
}

export default TCPrivacyDisclaimerComponent
