import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'

import NavigationPoint from 'components/NavigationPoint'
import ExclusiveOffer from 'components/detailComponents/ExclusiveOffer'
import ImageMap from 'components/detailComponents/ImageMap'
import MapBtn from 'components/detailComponents/MapBtn'
import { CategoryBridge_zh_CN, CategoryBridge_en_US } from 'utils/config'

const Title = tw.div`w-[116px] text-sm md:text-base`

function TopIntro({ items }) {
    const {
        name,
        merchantLogo,
        category,
        location,
        subLocal,
        specialRemarkDescription,
        specialRemarkTitle,
        mainBuilding,
        locationMap: venueImage,
        venueLocationUrl,
        contactNumber: enquiries,
        website,
        // acceptKDollar,
        openHours,
    } = items
    const [isExpand, setIsExpand] = useState(false)
    const {
        i18n: { language },
        t,
    } = useTranslation()
    
    const bridge = useMemo(() => {
        return language === 'en_US' ? CategoryBridge_en_US : CategoryBridge_zh_CN
    }, [language])

    return (
        <div className='w-full flex gap-7'>
            {/* <div className=' bg-secondary-grey -z-10 absolute top-0 h-[100px] left-0 right-0'></div> */}
            <div className=' w-full space-y-4 py-6 max-w-[750px] md:pt-10 md:pb-[60px]'>
                <NavigationPoint firstNav={t('landing.dine')} lastNav={name} firstNavLink={'/restaurants'} />
                <div className='flex items-center py-8'>
                    {merchantLogo && (
                        <div className='w-20 h-20 rounded-full mr-3.5'>
                            <img src={merchantLogo} alt='logo' className='w-full h-full rounded-full' />
                        </div>
                    )}
                    {name && <h1 className='text-h3 flex-1 text-secondary-midnightBlue break-all'>{name}</h1>}
                </div>
                {(specialRemarkTitle || specialRemarkDescription) && (
                    <ExclusiveOffer
                        title={specialRemarkTitle}
                        about={specialRemarkDescription}
                        text={t('shop.showMore')}
                    />
                )}

                <div className='flex gap-[10px]'>
                    <Title className='text-body2'>{t('shop.location')}</Title>
                    <div className='flex-1 text-body1 overflow-hidden break-words'>
                        {/* {mainBuilding && <div>{mainBuilding}</div>} */}
                        {location && <div>{location}</div>}
                        {/* {subLocal && <div>{subLocal}</div>} */}
                        {(venueImage || venueLocationUrl) && (
                            <div
                                className='flex items-center cursor-pointer gap-4'
                                onClick={() => setIsExpand(!isExpand)}
                            >
                                <div className='text-sm font-semiboldFamily underline text-[#0000EE]'>
                                    {isExpand ? t('venue.hideMap') : t('venue.showMap')}
                                </div>
                                <div className='text-secondary-midnightBlue'>
                                    {isExpand ? <div className='icon-arrow_up' /> : <div className='icon-arrow_down' />}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {isExpand && (
                    <>
                        {venueImage && <ImageMap venueImage={venueImage} />}
                        {venueLocationUrl && (
                            <MapBtn venueLocationUrl={venueLocationUrl} btnCw={t('shop.goToGoogleMap')} />
                        )}
                    </>
                )}

                {openHours && openHours.length > 0 && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('shop.openingHour')}</Title>
                        <div className='flex-1 text-body1 overflow-hidden break-words flex flex-col'>
                            {openHours.map((i, index) => {
                                return <div key={index}>{i}</div>
                            })}
                        </div>
                    </div>
                )}

                {enquiries && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('restaurant.Enquiries')}</Title>
                        <div className='text-body1'>{enquiries}</div>
                    </div>
                )}
                {website && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('shop.website')}</Title>
                        <div
                            onClick={() => window.open(website)}
                            className='flex-1 pr-2 text-body1 break-words overflow-hidden underline text-[#0000EE] cursor-pointer'
                        >
                            {website}
                        </div>
                    </div>
                )}

                {/* {acceptKDollar === 1 && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('restaurant.accept')}</Title>
                        <div className='text-body1'>K Dollar</div>
                    </div>
                )} */}

                {category && category.length > 0 && (
                    <div className='flex gap-[10px]'>
                        <Title className='text-body2'>{t('restaurant.type')}</Title>
                        <div className='text-body1'>
                            {category.join(bridge)}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default TopIntro
