import { Button, useMediaQuery } from '@mui/material'
import React, { useMemo } from 'react'
import Content from 'components/Content'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

const TourCards = ({ toursData, fetchNextPage }) => {
    const navigate = useNavigate()
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const { pages = [] } = toursData
    const isMobile = useMediaQuery('(max-width:1023px)')

    const tourList = useMemo(() => {
        let list = []
        pages.forEach((item) => {
            item?.data?.forEach((i) => {
                list.push(i)
            })
        })
        return list
    }, [pages])

    const lastData = _.last(pages) ?? {}
    const { allDataSize, hasMoreData } = lastData

    return (
        <div className='w-full my-12'>
            {tourList.map((item, index) => {
                const isFull = item?.actionLink === 'disable' || item?.actionLink === 'disabled'
                return (
                    <div
                        className={`md:w-[80%] md:mx-auto mx-5 lg:flex block my-12 overflow-hidden relative h-auto`}
                        style={{ boxShadow: '0px 2px 12px 0px #00000026' }}
                    >
                        {(index % 2 === 0 || isMobile) && (
                            <img
                                alt=''
                                src={item.cardImage}
                                className={`object-cover block aspect-[16/9] ${isMobile ? 'w-full' : 'w-[60%]'}`}
                            />
                        )}
                        <div className='md:mx-10 mx-6 my-[43px] xl:w-[40%] flex-grow'>
                            {/* <div className='text-primaryRed-ktRed text-body1'>{item.category}</div> */}
                            <div className='xl:text-h3 text-h4 xl:text-[36px] text-[22px] mt-3 line-clamp-2 text-secondary-midnightBlue'>
                                {item.activityName}
                            </div>
                            {/* <div className='text-body1 mt-8'>{item.about}</div> */}
                            <Content className='mt-6 detail-page-text xl:line-clamp-4 line-clamp-3 text-[16px]' data={item.about} />
                            <Button
                                className='font-semiboldFamily w-[180px] shadow-none mt-8 text-[18px] h-[48px]'
                                variant='primaryRed'
                                onClick={() => {
                                    navigate('/precinctTourDetail?id=' + item.vbsProgramId)
                                }}
                            >
                                {t('precinctTour.listing.learnMore')}
                            </Button>
                        </div>
                        {index % 2 !== 0 && !isMobile && (
                            <img
                                alt=''
                                src={item.cardImage}
                                className={`object-cover block aspect-[16/9] ${isMobile ? 'w-full' : 'w-[60%]'}`}
                            />
                        )}

                        {(item.label || isFull) && (
                            <div
                                className={`py-[6px] px-[15px] bg-primaryRed-ktRed text-white absolute top-0 ${
                                    index % 2 === 0 || isMobile ? 'left-0' : 'right-0'
                                } ${isFull ? 'bg-secondary-grey' : 'bg-primaryRed-ktRed'}`}
                            >
                                {isFull ? t('precinctTour.listing.full') : item.label}
                            </div>
                        )}
                    </div>
                )
            })}
            {hasMoreData && (
                <div className='flex justify-center'>
                    <Button
                        type='button'
                        variant='outlined'
                        className='w-[270px] font-semibold text-[18px]'
                        size='large'
                        onClick={fetchNextPage}
                    >
                        {t('precinctTour.listing.showMore')}
                    </Button>
                </div>
            )}
            <div className='text-center text-secondary-darkGery mt-3 text-[14px]'>
                {t('precinctTour.listing.showing', { num: tourList.length, total: allDataSize })}
            </div>
        </div>
    )
}

export default TourCards
