import React, { useEffect, useState } from 'react'
import Banner from 'components/shopDine/Banner'
import { useTranslation } from 'react-i18next'
import ExperienceTourBanner from 'resources/images/ExperienceTourBanner.png'
// import FeaturedTour from './components/FeaturedTour'
import TourCards from './components/TourCards'
// import FAQ from './components/FAQ'
// import Title from 'components/shopDine/Title'
import { precinctTourService } from 'service/precinctTourService'
import { useQuery, useInfiniteQuery } from '@tanstack/react-query'
import BannerSwiper from 'components/shopDine/BannerSwiper'

const ExperienceTour = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const banner = {
        image: ExperienceTourBanner,
        title: t('precinctTour.listing.title'),
        about: t('precinctTour.listing.desc'),
    }

    const { data: bannerSwiperImgs = [] } = useQuery({
        queryKey: ['getHeroBannerList', language],
        queryFn: () => precinctTourService.getHeroBannerList({ lang: language, size: 5 }),
    })

    const {
        data: toursData,
        fetchNextPage,
        refetch,
    } = useInfiniteQuery({
        queryKey: ['getPrecinctToursList', language],
        queryFn: ({ pageParam = 0 }) =>
            precinctTourService.getPrecinctToursList({
                lang: language,
                maxRecord: 5,
                startRow: pageParam,
            }),
        getNextPageParam: (lastPage, pages) => {
            return pages.length * 5
        },
    })

    useEffect(() => {
        refetch()
    }, [])

    return (
        <div className='bg-white'>
            <Banner banner={banner} />

            {bannerSwiperImgs?.length > 0 && <BannerSwiper type='tour' bannerSwiperImgs={bannerSwiperImgs} />}

            <TourCards toursData={toursData ?? {}} fetchNextPage={fetchNextPage} />
        </div>
    )
}

export default ExperienceTour
