import React, { useState } from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { cn } from 'utils/cn'
function BusCommonComponent({ busItems, type = 'bus' }) {
    const { t } = useTranslation()
    return (
        <div className='pt-6 lg:pt-[60px]'>
            {busItems.map((item, index) => {
                return type === 'bus' ? (
                    <BusItem
                        key={index}
                        isBus={item.isBus}
                        topFun={item.topFun}
                        image={item.image}
                        defaultExpand={index === 0}
                        wording={item.wording}
                    />
                    ) : type === 'taxi' ? (
                        <TaxiItem key={index} image={item.image} defaultExpand={index === 0} wording={item.wording} />
                    ) : (
                        <MtrItem key={index} image={item.image} defaultExpand={index === 0} wording={item.wording} />
                    )
            })}
        </div>
    )
}

function MtrItem({ wording, defaultExpand }) {
    const { t } = useTranslation()
    const { title, prefix, bold, suffix} = wording
    const [isExpand, setIsExpand] = useState(defaultExpand)
return(
    <div className='py-4 border-b border-[#B1B1B1]'>
    <div
        className='cursor-pointer flex justify-between items-center gap-2 active:opacity-50'
        onClick={() => setIsExpand(!isExpand)}
    >
        <div className='flex-1'>
            <div className='w-full text-[22px] leading-[32.4px] font-semiboldFamily text-secondary-midnightBlue'>
                {title}
            </div>
        </div>
        <div className={cn('cursor-pointer', isExpand ? 'icon-minus' : 'icon-plus', 'text-secondary-midnightBlue')} />
    </div>
    {isExpand && (

<div>
<div className='flex gap-5 pt-5 flex-col'>
    <div className='space-y-2'>
        <div className="flex items-center">
            <span className='text-base font-medium text-secondary-midnightBlue'>
                {prefix}
                <span className='text-base font-bold'>{bold}</span>
                {suffix}
            </span>
        </div>
    </div>
</div>
</div>
    
    
    )}
</div>
)

}

function BusItem({ isBus, topFun, image, defaultExpand, wording }) {
    const { t } = useTranslation()
    const { routes, busStopTitle, busStopNumber, walkDistDetail } = wording
    const [isExpand, setIsExpand] = useState(defaultExpand)

    return (
        <div className='py-6 border-b border-[#B1B1B1]'>
            <div
                className='cursor-pointer flex justify-between items-center gap-2 active:opacity-50'
                onClick={() => setIsExpand(!isExpand)}
            >
                <div className='flex-1'>
                    <div className='w-full text-[22px] leading-[32.4px] font-semiboldFamily text-secondary-midnightBlue'>
                        {busStopTitle}
                        {/* {isBus ? t('transport.busStatiion') : t('transport.minibusStation')} */}
                        <Button
                            // onClick={topFun}
                            disabled
                            className='bg-white min-h-]26px] ml-3 h-fit text-xs text-secondary-midnightBlue normal-case border-secondary-midnightBlue border-[1px] border-solid'
                            // variant='contained'
                            // size='small'
                        >
                            {busStopNumber}
                        </Button>
                    </div>
                </div>
                <div className={cn('cursor-pointer', isExpand ? 'icon-minus' : 'icon-plus', 'text-secondary-midnightBlue')} />
            </div>
            {isExpand && (
                <div>
                    <div className='flex gap-5 pt-5 flex-col lg:flex-row'>
                        <div className=' aspect-video w-full lg:w-[369px] lg:h-[208px]'>
                            <img alt='' src={image} className='w-full h-full object-cover' />
                        </div>
                        <div className='space-y-2 flex-1 my-auto'>
                            <div className='text-base font-semiboldFamily text-secondary-midnightBlue'>{t('transport.walkingDistance')}</div>
                            <div className='text-base font-medium whitespace-pre-line text-secondary-midnightBlue'>{walkDistDetail}</div>
                        </div>
                    </div>
                    <div className='text-base pt-5 font-semiboldFamily text-secondary-midnightBlue'>{isBus ? t('transport.busNumver') : t('transport.minibusNumber')}</div>
                    {routes.map((route, index) => {
                        return (
                            <div key={index} className='flex gap-2 pt-5 items-center text-base font-medium text-secondary-midnightBlue'>
                                <div className='px-2 py-1 bg-[#E9ECF3]'>{route.busRoute}</div>
                                <div>{route.busRouteAbout}</div>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

const TaxiItem = ({ image, wording, defaultExpand }) => {
    const { t } = useTranslation()
    const { routes, title, station, detail } = wording
    const [isExpand, setIsExpand] = useState(defaultExpand)
    return (
        <div className='py-4 border-b border-[#B1B1B1]'>
            <div
                className='cursor-pointer flex justify-between items-center gap-2 active:opacity-50'
                onClick={() => setIsExpand(!isExpand)}
            >
                <div className='flex-1'>
                    <div className='w-full text-[22px] leading-[32.4px] font-semiboldFamily text-secondary-midnightBlue'>
                        {title}
                        {station && (
                            <Button
                                disabled
                                className='bg-white min-h-]26px] ml-3 h-fit text-xs text-secondary-midnightBlue normal-case border-secondary-midnightBlue border-[1px] border-solid'
                            >
                                {station}
                            </Button>
                        )}
                    </div>
                </div>
                <div className={cn('cursor-pointer', isExpand ? 'icon-minus' : 'icon-plus', 'text-secondary-midnightBlue')} />
            </div>
            {isExpand && (
                <div>
                    <div className='flex gap-5 pt-5 flex-col'>
                        {image && (
                            <div className=' aspect-video w-full lg:w-[369px]'>
                                <img alt='' src={image} className='w-full aspect-[4/3] object-cover' />
                            </div>
                        )}
                        <div className='space-y-2 flex-1 my-auto'>
                            <div className='text-base font-medium whitespace-pre-line text-secondary-midnightBlue'>{detail}</div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default BusCommonComponent
