import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'ramda'

import { venueServices } from 'service/venueService'
import TopIntro from 'page/Venue/components/TopIntro'
import TabsAbout from 'page/Venue/components/TabsAbout'
import BannerSwiper from 'components/detailComponents/BannerSwiper'
import EventType from 'page/Venue/components/EventType'
import Loading from 'components/Loading'
import { TabContainer } from 'components/TabContainer'
import Overview from '../components/Overview'
import useNotAvailableAutoLeave from 'utils/useNotAvailableAutoLeave'
import { notAvailableErrorCode } from 'utils/filter'

export default function Detail() {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id')
    const { data: items } = useQuery({
        queryKey: ['getVenueDetail', language, id],
        queryFn: () => venueServices.getDetail({ lang: language, identityUuid: id }),
        placeholderData: null,
    })
    useNotAvailableAutoLeave(items?.errorCode, notAvailableErrorCode.venue)

    if (!items) {
        return <Loading />
    }
    if (items?.result === null) return null
    return (
        <>
            {items && (
                <>
                    {items?.heroBannerRespDTOList.length > 0 && <BannerSwiper items={items?.heroBannerRespDTOList} />}
                    <section className='bg-container'>
                        <TopIntro items={items} />
                    </section>
                    <TabContainer
                        components={[
                            {
                                key: 'overview',
                                title: t('venue.overview'),
                                component: () => <Overview items={items} />,
                            },
                            {
                                key: 'events',
                                title: t('venue.whatToExpect'),
                                component: () => <EventType items={items} />,
                            },
                        ].filter((i) => i)}
                    />
                </>
            )}
        </>
    )
}
