// Package imports
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// Project imports
import DoublePhotoSwiper from 'components/landingComponents/DoublePhotoSwiper'
import Title from 'components/shopDine/Title'
// Folder imports
import swiperImage001 from 'resources/images/pearl-club/gallery1.jpg'
import swiperImage002 from 'resources/images/pearl-club/gallery2.jpg'
import swiperImage003 from 'resources/images/pearl-club/gallery3.jpg'
import swiperImage004 from 'resources/images/pearl-club/gallery4.jpg'
import swiperImage005 from 'resources/images/pearl-club/gallery5.jpg'

const PhotoGallerySection = () => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const items = [
        {
            image: swiperImage001,
            altText: 'Image 1',
        },
        {
            image: swiperImage002,
            altText: 'Image 2',
        },
        {
            image: swiperImage003,
            altText: 'Image 3',
        },
        {
            image: swiperImage004,
            altText: 'Image 4',
        },
        {
            image: swiperImage005,
            altText: 'Image 5',
        },
    ]

    return (
        <div className='w-full bg-[#E9ECF3] bg-cover bg-center bg-no-repeat overflow-hidden mt-8 py-[24px] md:py-[60px]'>
            <div className='pb-4 pl-5 md:pl-10 md:pb-8 lg:pl-[60px] xl:pl-[150px]'>
                <Title title={t('pearlClub.photoGallery')} />
            </div>
            <div className='mt-[-40px] md:mt-[-80px]'>
                <DoublePhotoSwiper items={items} isDisabledArrowBtn={true} clickAble={false} />
            </div>
        </div>
    )
}

export default PhotoGallerySection
