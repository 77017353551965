import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import friends from 'resources/images/friends.svg'

export default function FriendsComponent({ className = 'mr-6 ml-3' }) {
    const navigate = useNavigate()

    // return null
    return (
        <Button
            className={'normal-case p-0 ' + className}
            size='small'
            onClick={() => {
                navigate('/friends')
            }}
        >
            <img src={friends} alt='friendsofKTSP' />
        </Button>
    )
}
