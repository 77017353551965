import React, { useEffect, useState } from 'react'
import tw from 'twin.macro'
import AccessibleOutlinedIcon from '@mui/icons-material/AccessibleOutlined'
import HeadphonesOutlinedIcon from '@mui/icons-material/HeadphonesOutlined'
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import SeeAlso from 'page/VisitorTips/components/SeeAlso'
import Loading from 'components/Loading'
import { visitorTipApi } from 'service/visitorTipService'
import service from 'resources/images/service.png'
import Card from 'page/VisitorTips/components/Card'

import styles from './AccessibilityServices.module.css'
import Content from 'components/Content'

const MainTitle = tw.div`text-[28px] leading-[28px]`
function AccessibilityServices() {
    const [informationType, setInformationType] = useState([])
    const [btnVal, setBtnVal] = useState(0)
    const [items, setItems] = useState([])
    const [seeMoreItems, setSeeMoreItems] = useState([])
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const services = [
        {
            image: service,
            about: t('accessbilityService.barrierFreeAccess'),
            icon: <div className='icon-Barrier-free-Access-converted font-medium' />,
            id: '5052605a394cf990e7abfc751240d638',
        },
        {
            image: service,
            about: t('accessbilityService.inductive Loops'),
            icon: <div className='icon-Inductive-Loops-converted' />,
            id: 'e7cadb14c0a8eef7e7f9d95efe6b2b87',
        },
        {
            image: service,
            about: t('accessbilityService.brailleTactilePlans'),
            icon: <div className='icon-Braille--Tactile-Plans-converted' />,
            id: '758c34b928b108dece5394e9bbadc82f',
        },
        {
            image: service,
            about: t('accessbilityService.venue Accessibility'),
            icon: <div className='icon-Venue-Accessibility-converted' />,
            id: '198295ad9a477dd91210a538b6d7a314',
        },
        {
            image: service,
            about: t('accessbilityService.ticketing'),
            icon: <div className='icon-Ticketing-converted' />,
            id: '46623e4b5293e126cb093d9b01645240',
        },
    ]

    const getAccessibilityServices = useMutation({
        mutationFn: visitorTipApi.getAccessibilityServices,
        onSuccess: (res) => {
            setItems(res)
        },
        onError: (e) => {
            console.log(e)
        },
    })
    const getSeeMore = useMutation({
        mutationFn: visitorTipApi.getSeeMore,
        onSuccess: setSeeMoreItems,
        onError: (e) => {
            console.log(e)
        },
    })
    // const getAccessibilityServicesMethod = (id) => {
    //     setItems([])

    // }
    // const getAccessibilityInformation = useMutation({
    //     mutationFn: visitorTipApi.getAccessibilityInformation,
    //     onSuccess: (res) => {
    //         setInformationType(res)
    //         res.length > 0 && getAccessibilityServicesMethod(res[0].id)
    //     },
    // })

    useEffect(() => {
        // getAccessibilityInformation.mutate({ identityUuid: '', lang: language })
        getSeeMore.mutate({ lang: language, limit: 10, offset: 0, type: 0 })
        getAccessibilityServices.mutate({
            lang: language,
            limit: 10,
            offset: 0,
            type: 0,
        })
    }, [language])
    return (
        <div className='mb-16'>
            <div className='text-secondary-midnightBlue -mt-4 bg-container'>
                <div className='font-semiboldFamily text-center pb-4 text-[28px] md:text-4xl'>
                    {t('visitorTip.accessibilityServicesTitle')}
                </div>
                <div className='text-base font-mediumtext-center text-center pb-4'>
                    {t('visitorTip.accessibilityServicesAbout')}
                </div>
                <div className='flex justify-center pb-12'>
                    <div className={`w-full lg:w-[79%] ${styles.iconList}`}>
                        {services.map((service, index) => {
                            if (items.length > 0 && items[items.findIndex((item) => item.uuid === service.id)]) {
                                return (
                                    <div
                                        onClick={() => {
                                            setBtnVal(index)
                                            // getAccessibilityServicesMethod(service.id)
                                        }}
                                        key={index}
                                        className={`max-w-[180px] px-[10px] cursor-pointer active:opacity-50 flex-1 py-3 space-y-3 ${styles.iconItem}`}
                                    >
                                    <div
                                        className={
                                            'flex w-full justify-center text-[32px] ' +
                                            (btnVal !== index && 'text-[#B1B1B1]')
                                        }
                                    >
                                        {service.icon}
                                        {/* <img alt='' src={service.image} className='w-8 h-8 object-contain' /> */}
                                    </div>
                                    <div
                                        className={
                                            'text-base font-medium text-center ' +
                                            (btnVal !== index && ' text-[#B1B1B1]')
                                        }
                                    >
                                        {service.about}
                                    </div>
                                </div>
                                )
                            }
                        })}
                    </div>
                </div>
                {items.length > 0 && items[items.findIndex((item) => item.uuid === services[btnVal].id)] && (
                    <Card
                        alt={items[items.findIndex((item) => item.uuid === services[btnVal].id)].altText}
                        image={items[items.findIndex((item) => item.uuid === services[btnVal].id)].image}
                        component={
                            <div className='w-full overflow-hidden'>
                                <MainTitle className='pb-3 font-semiboldFamily'>
                                    {items[items.findIndex((item) => item.uuid === services[btnVal].id)].title}
                                </MainTitle>
                                <Content
                                    data={
                                        items[items.findIndex((item) => item.uuid === services[btnVal].id)]
                                            .description
                                    }
                                    className={`pb-4 break-words`}
                                />
                            </div>
                        }
                    />
                )}
                {getAccessibilityServices.isPending && <Loading />}
            </div>
            {getSeeMore.isPending ? <Loading /> : <>{seeMoreItems?.length > 0 && <SeeAlso imgs={seeMoreItems} />}</>}
        </div>
    )
}

export default AccessibilityServices
