import Content from 'components/Content'
import ViewMore from 'components/detailComponents/ViewMore'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import tw from 'twin.macro'
import DescriptionComponent from 'components/detailComponents/DescriptionComponent'
import { cn } from "utils/cn";
const Tag = tw.div`text-secondary-midnightBlue py-[5px] px-2 text-sm border-solid border-[1px] border-[#0A173D]`

export const About = ({ items, isNeedExpand = true }) => {
    return (
        <div className='bg-container py-8 text-secondary-midnightBlue'>
            <div className='max-w-[750px]'>
                {/* <div className='pb-4 text-h3 font-semiboldFamily'>{t('eventPage.about')}</div> */}
                <DescriptionComponent description={items.description} isNeedExpand={isNeedExpand} />
                <div className='flex flex-wrap gap-2 pt-4'>
                    {items.tagList.length > 0 &&
                        items.tagList.map((tag, index) => {
                            return (
                                <Tag className='font-medium' key={index}>
                                    {tag.name}
                                </Tag>
                            )
                        })}
                </div>
                <div className='flex flex-col mt-12'>
                    {items?.dynamicContents &&
                        items.dynamicContents.length > 0 &&
                        items.dynamicContents.map((item, index) => {
                            return (
                                <a
                                    key={index}
                                    href={item.url}
                                    className={cn(
                                        index !== items.dynamicContents.length - 1 ? 'mb-[11px]' : '',
                                        index === items.dynamicContents.length - 1 ? 'mb-8 md:mb-12' : '',
                                        'text-lg font-boldFamily underline cursor-pointer active:opacity-50',
                                    )}
                                >
                                    {item.name}
                                </a>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}
