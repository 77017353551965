import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Banner from 'components/shopDine/Banner'
import VisitorTipsBanner from 'resources/images/VisitorTipsBanner.jpg'
import GuestServices from 'page/VisitorTips/components/GuestServices'
import AccessibilityServices from 'page/VisitorTips/components/AccessibilityServices/AccessibilityServices'
import OpeningHour from 'page/VisitorTips/components/OpeningHour'
import visitorServices from 'resources/images/visitorServices.svg'
import openingHours from 'resources/images/openingHours.svg'
import accessibility from 'resources/images/accessibility.svg'
import kTSPApp from 'resources/images/kTSPApp.svg'
import visitorServicesWhite from 'resources/images/visitorServicesWhite.svg'
import openingHoursWhite from 'resources/images/openingHoursWhite.svg'
import accessibilityWhite from 'resources/images/accessibilityWhite.svg'
import kTSPAppWhite from 'resources/images/kTSPAppWhite.svg'
import AppIntroduction from 'page/VisitorTips/components/AppIntroduction'
import ButtonsTab from 'components/ButtonsTab'

function VisitorTips() {
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams();
    const val = searchParams.get('val');
    const [btnType, setBtnType] = useState('');
    const banner = {
        image: VisitorTipsBanner,
        title: t('visitorTip.title'),
        about: t('visitorTip.bannerAbout'),
    }
    const btnVals = [
        {
            text: t('visitorTip.guestServices'),
            val: 'guestServices',
            icon: <img src={visitorServices} alt='' />,
            actIcon: <img src={visitorServicesWhite} alt='' />,
        },
        {
            text: t('visitorTip.openingHour'),
            val: 'openingHour',
            icon: <img src={openingHours} alt='' />,
            actIcon: <img src={openingHoursWhite} alt='' />,
        },
        {
            text: t('visitList.accessibilityServices'),
            val: 'accessibilityServices',
            icon: <img src={accessibility} alt='' />,
            actIcon: <img src={accessibilityWhite} alt='' />,
        },
        {
            text: t('visitorTip.KTSPAPPIntroduction'),
            val: 'KTSPAPPIntroduction',
            icon: <img src={kTSPApp} alt='' />,
            actIcon: <img src={kTSPAppWhite} alt='' />,
        },
    ]

    useEffect(()=>{
        setBtnType(val? val : 'guestServices');
    },[val])

    return (
        <div className='bg-white'>
            <Banner banner={banner} />
            <div className='pt-11 pb-16'>
                {/* <div className='pb-6 text-base bg-container font-medium'> {t('visitorTip.whetherAbout')}</div> */}
                <ButtonsTab
                    list={btnVals.map((btnVal) => {
                        return {
                            ...btnVal,
                            text: (
                                <>
                                    {btnType === btnVal.val ? btnVal.actIcon : btnVal.icon}
                                    {btnVal.text}
                                </>
                            ),
                            selected: btnType === btnVal.val,
                        }
                    })}
                    onItemClick={(item) => {
                        setBtnType(item.val)
                    }}
                    itemClassName='w-full min-w-fit max-w-[267px] text-nowrap shrink md:shrink-0 flex gap-2'
                />
                <div className='bg-container'>
                    {btnType === 'guestServices' && <GuestServices />}
                    {btnType === 'openingHour' && <OpeningHour />}
                </div>

                {btnType === 'KTSPAPPIntroduction' && <AppIntroduction />}
            </div>
            {btnType === 'accessibilityServices' && <AccessibilityServices />}
        </div>
    )
}

export default VisitorTips
