import React, { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import Banner from 'components/shopDine/Banner'
import restaurantTop from 'resources/images/restaurant.jpg'
import BannerSwiper from 'components/shopDine/BannerSwiper'
import FeaturedShop from 'components/shopDine/FeaturedShop'
import c from 'resources/images/card2.png'
import b1 from 'resources/images/banner1.jpg'
import b2 from 'resources/images/banner2.jpg'
import AllProduct from './components/AllProduct'
import { useTranslation } from 'react-i18next'
import { shopDineService } from 'service/restaurantService'
function Restaurants() {
    const {
        t,
        i18n: { language },
    } = useTranslation()

    const banner = {
        image: restaurantTop,
        title: t('restaurant.restaurantTitle'),
        about: t('restaurant.restaurantAbout'),
    }

    const { data: bannerFeaturedList } = useQuery({
        queryKey: ['getDineBannerFeaturedList', language],
        queryFn: () =>
            shopDineService.getShopDineBannerFeaturedList({
                lang: language,
                bannerSize: 5,
                featuredSize: 10,
                type: 'dine',
            }),
    })

    return (
        <div className='bg-white'>
            <section>
                <Banner banner={banner} />
            </section>
            <section>
                {bannerFeaturedList && bannerFeaturedList.heroBanner && bannerFeaturedList.heroBanner.length > 0 && (
                    <BannerSwiper bannerSwiperImgs={bannerFeaturedList.heroBanner} type='restaurant' />
                )}
            </section>
            <section>
                {bannerFeaturedList && bannerFeaturedList.featured && bannerFeaturedList.featured.length > 0 && (
                    <FeaturedShop
                        name={t('restaurant.featureRestaurant')}
                        featuredList={bannerFeaturedList.featured}
                        path='/restaurantsDetail'
                    />
                )}
            </section>
            <section>
                <AllProduct
                    // searchVal={searchVal}
                    // setSearchVal={setSearchVal}
                    firstTypeName={t('restaurant.type')}
                    secondTypeName={t('shop.location')}
                    // typesSelected={typesSelected}
                    // setTypesSelected={setTypesSelected}
                    // venuesSelected={venuesSelected}
                    // setVenuesSelected={setVenuesSelected}
                    // types={types}
                    // venues={venues}
                    // cards={cards}
                    // setCards={setCards}
                    isFilter={true}
                    name={t('restaurant.allRestaurant')}
                />
            </section>
        </div>
    )
}

export default Restaurants
