import { useMemo, useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useForm, Controller } from 'react-hook-form'
import { filter, isEmpty } from 'ramda'
import { TextField, MenuItem, Button, ListSubheader, Typography, Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useSimpleBackdropStore } from 'store/authAtom'
import UpdateEmail from 'page/UserSettings/EditMyProfile/components/UpdateEmail'
import UpdatePhone from 'page/UserSettings/EditMyProfile/components/UpdatePhone'
import ChangePassword from 'page/UserSettings/EditMyProfile/components/ChangePassword'
import { myProfile } from 'service/crmService'
import { useModalStore } from 'store/authAtom'
import { languageList } from 'resources/i18n'
import { yearList, monthList, getNameByCode } from 'utils/filter'
import { useValidationI18n } from 'utils/validationI18n'

import Iamsmart from 'resources/images/iamsmart_tag.svg'

const IasmIcon = tw.img``

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
}

const Label = ({ title, about, right, fun }) => {
    return (
        <div className='py-2 flex justify-between items-center'>
            <div className='text-secondary-midnightBlue'>
                <div className='text-base font-semiboldFamily'>{title}</div>
                <div className='text-sm font-medium'>{about ? about : '-'}</div>
            </div>
            {right && (
                <div
                    onClick={fun}
                    className='text-primaryRed-ktRed cursor-pointer text-base font-semiboldFamily underline'
                >
                    {right}
                </div>
            )}
        </div>
    )
}
const Form = tw.form`space-y-6 w-full md:w-[400px]`
const TextLabel = styled.div`
    ${({ disabled }) => (!!disabled ? tw`text-secondary-hint` : tw`text-secondary-midnightBlue`)}
    ${tw`text-base`}
`

const ListSubheaderWrap = styled(ListSubheader)`
    ${tw`px-5`}
`
const MenuItemWrap = styled(MenuItem)`
    ${tw`px-8`}
`

export default function FormUpdate({ profileData = {}, getTitle, countryCodeList }) {
    const { onOpenSimpleBackdrop, onCloseSimpleBackdrop } = useSimpleBackdropStore()
    const { validationSchema } = useValidationI18n()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const queryClient = useQueryClient()
    const { setModalComponent, onClose, onOpen } = useModalStore()

    const {
        register,
        handleSubmit,
        control,
        watch,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(
            yup.object({
                title: validationSchema.title,
                lastname: validationSchema.lastname,
                firstname: validationSchema.firstname,
                nickname: validationSchema.nickname,
                chineseName: validationSchema.chineseName,
                monthOfBirth: validationSchema.monthOfBirth,
                yearOfBirth: validationSchema.yearOfBirth,
                residentialArea: validationSchema.residentialArea,
                province: validationSchema.province,
                district: validationSchema.district,
                languagePreference: validationSchema.languagePreference,
            }),
        ),
        defaultValues: {
            ...profileData,
            openIdUUID: profileData?.openIdUUID ?? '',
            eNewIndicator:
                profileData?.eNewIndicator === 'false' ? false : profileData?.eNewIndicator === 'true' ? true : false,
            province: profileData?.province ?? '',
            district: profileData?.district ?? '',
            monthOfBirth: profileData?.monthOfBirth ?? '',
            yearOfBirth: profileData?.yearOfBirth ?? '',
            campaignCode: profileData?.campaignCode ?? '',
            referralCode: profileData?.referralCode ?? '',
            dayOfBirth: profileData?.dayOfBirth ?? '',
        },
    })

    const residentialAreaWatch = watch('residentialArea')
    const yearOfBirthWatch = watch('yearOfBirth')
    const monthOfBirthWatch = watch('monthOfBirth')

    const updateSSOProfile = useMutation({
        mutationFn: myProfile.updateSSOProfile,
        onSuccess: () => {
            enqueueSnackbar(t('myProfile.updatedTip'), { variant: 'success' })
            queryClient.invalidateQueries({
                queryKey: ['getMyProfile'],
            })
            navigate('/userSettings/myProfile')
            onCloseSimpleBackdrop()
        },
        onError: (e) => {
            enqueueSnackbar(t('myProfile.saveTip'), { variant: 'error' })
            onCloseSimpleBackdrop()
        },
    })

    const onSubmit = (formData) => {
        onOpenSimpleBackdrop()
        updateSSOProfile.mutate({
            ...formData,
            activityInterest: undefined,
            province: formData?.province ?? '',
            district: formData?.district ?? '',
        })
    }

    const provinceList = useMemo(() => {
        if (residentialAreaWatch === 'CHN') {
            return (
                filter((item) => item.isoCode === residentialAreaWatch, countryCodeList?.countryCodeList)?.[0]?.[
                    'area'
                ] || []
            )
        } else {
            return false
        }
    }, [residentialAreaWatch, countryCodeList])

    const districtList = useMemo(() => {
        if (residentialAreaWatch === 'HKG') {
            return (
                filter((item) => item.isoCode === residentialAreaWatch, countryCodeList?.countryCodeList)?.[0]?.[
                    'area'
                ] || []
            )
        } else {
            return false
        }
    }, [residentialAreaWatch, countryCodeList])

    const monthListMemo = useMemo(() => {
        const renderList = monthList.slice(0, new Date().getMonth() + 1)

        return new Date().getFullYear() - yearOfBirthWatch === 15 ? renderList : monthList
    }, [language, t, yearOfBirthWatch])

    useEffect(() => {
        if (yearOfBirthWatch) {
            const minDate = new Date()
            const inputDate = new Date(yearOfBirthWatch, monthOfBirthWatch - 1)
            const fifteenYearsAgo = new Date(minDate.getFullYear() - 15, minDate.getMonth())
            if (inputDate.getTime() > fifteenYearsAgo.getTime()) {
                setValue('monthOfBirth', '')
            }
        }
    }, [yearOfBirthWatch])

    return (
        <>
            {!isEmpty(profileData) && !isEmpty(countryCodeList) && !isEmpty(getTitle) && (
                <>
                    {profileData.openIdUUID && (
                        <div className='w-full md:w-[400px] mb-2 flex items-center gap-1 bg-secondary-blueThin p-3 text-body1'>
                            <IasmIcon src={Iamsmart} />
                            {t('myProfile.iamSmartBindAbout')}
                        </div>
                    )}
                    <Form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                        <Label
                            title={t('myProfile.emailAddress')}
                            about={profileData?.emailId}
                            right={t('myProfile.update')}
                            fun={() => {
                                setModalComponent(<UpdateEmail />)
                                onOpen()
                            }}
                        />
                        <Label
                            title={t('myProfile.phoneNo')}
                            about={`+${profileData?.countryCode} ${profileData?.mobileNo}`}
                            // right={t('myProfile.update')}
                            fun={() => {
                                setModalComponent(<UpdatePhone countryCodeList={countryCodeList.codeList} />)
                                onOpen()
                            }}
                        />
                        <div className={`flex space-x-3 ${profileData?.openIdUUID ? 'items-end' : ''}`}>
                            <Controller
                                name='title'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        select
                                        label={
                                            <TextLabel className='font-semiboldFamily'>
                                                {t('SignUpPage.TitleDisplay')}
                                            </TextLabel>
                                        }
                                        variant='standard'
                                        color='secondary'
                                        className='w-[100px]'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        SelectProps={{
                                            MenuProps,
                                        }}
                                    >
                                        {getTitle.map((option) => (
                                            <MenuItem key={option.code} value={option.code}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                            <TextField
                                disabled={profileData?.openIdUUID}
                                {...register('lastname')}
                                fullWidth
                                label={
                                    <TextLabel disabled={profileData?.openIdUUID} className='font-semiboldFamily'>
                                        {t('SignUpPage.LastDisplay')}
                                    </TextLabel>
                                }
                                variant='standard'
                                color='secondary'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors?.lastname}
                                helperText={errors?.lastname?.message ?? null}
                            />
                            {profileData?.openIdUUID && <IasmIcon src={Iamsmart} />}
                        </div>

                        <Box className={`flex ${profileData?.openIdUUID ? 'items-end' : ''}`}>
                            <TextField
                                disabled={profileData?.openIdUUID}
                                {...register('firstname')}
                                fullWidth
                                label={
                                    <TextLabel disabled={profileData?.openIdUUID} className='font-semiboldFamily'>
                                        {t('SignUpPage.FirstNameDisplay')}
                                    </TextLabel>
                                }
                                variant='standard'
                                color='secondary'
                                placeholder={t('SignUpPage.FirstNameDisplay')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors?.firstname}
                                helperText={errors?.firstname?.message ?? null}
                            />
                            {profileData?.openIdUUID && <IasmIcon src={Iamsmart} />}
                        </Box>

                        <Box
                            className={`flex ${profileData?.openIdUUID && profileData.chineseName ? 'items-end' : ''}`}
                        >
                            <TextField
                                {...register('chineseName')}
                                disabled={profileData?.openIdUUID && profileData.chineseName}
                                className='w-full'
                                label={
                                    <TextLabel
                                        disabled={profileData?.openIdUUID && profileData.chineseName}
                                        className='font-semiboldFamily'
                                    >
                                        {t('SignUpPage.ChineseNameDisplay')}
                                    </TextLabel>
                                }
                                variant='standard'
                                color='secondary'
                                placeholder={t('SignUpPage.ChineseNameDisplay')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!!errors?.chineseName}
                                helperText={errors?.chineseName?.message ?? null}
                            />
                            {profileData?.openIdUUID && profileData.chineseName && <IasmIcon src={Iamsmart} />}
                        </Box>

                        <TextField
                            {...register('nickname')}
                            fullWidth
                            label={
                                <TextLabel className='font-semiboldFamily'>{t('SignUpPage.NicknameDisplay')}</TextLabel>
                            }
                            variant='standard'
                            color='secondary'
                            placeholder={t('SignUpPage.NicknameDisplay')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={!!errors?.nickname}
                            helperText={errors?.nickname?.message ?? null}
                        />

                        <div className={`flex space-x-3 ${profileData?.openIdUUID ? 'items-end' : ''}`}>
                            <Controller
                                name='monthOfBirth'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        disabled={profileData?.openIdUUID}
                                        {...field}
                                        select
                                        label={
                                            <TextLabel
                                                disabled={profileData?.openIdUUID}
                                                className='font-semiboldFamily'
                                            >
                                                {t('SignUpPage.DateOfBirthDisplay')}
                                            </TextLabel>
                                        }
                                        variant='standard'
                                        color='secondary'
                                        className='w-[200px]'
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        SelectProps={{
                                            MenuProps,
                                        }}
                                    >
                                        {monthListMemo.map((v) => (
                                            <MenuItem key={v.value} value={v.value}>
                                                {t(v.label)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                            <Controller
                                name='yearOfBirth'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        disabled={profileData?.openIdUUID}
                                        {...field}
                                        select
                                        label={' '}
                                        variant='standard'
                                        color='secondary'
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        SelectProps={{
                                            MenuProps,
                                        }}
                                    >
                                        {yearList.map((v) => (
                                            <MenuItem key={v} value={v}>
                                                {v}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                            {profileData?.openIdUUID && <IasmIcon src={Iamsmart} />}
                        </div>

                        <Controller
                            name='residentialArea'
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    select
                                    label={
                                        <TextLabel className='font-semiboldFamily'>
                                            {t('SignUpPage.ResidentialAreaDisplay')}
                                        </TextLabel>
                                    }
                                    variant='standard'
                                    color='secondary'
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    SelectProps={{
                                        MenuProps,
                                    }}
                                >
                                    {countryCodeList?.countryCodeList.map((option) => (
                                        <MenuItem key={option.isoCode} value={option.isoCode}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />

                        {/* 中国展示 */}
                        {provinceList && (
                            <Controller
                                name='province'
                                control={control}
                                shouldUnregister={true}
                                defaultValue={''}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        select
                                        label={
                                            <TextLabel className='font-semiboldFamily'>
                                                {t('SignUpPage.ProvinceDisplay')}
                                            </TextLabel>
                                        }
                                        variant='standard'
                                        color='secondary'
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        SelectProps={{
                                            MenuProps,
                                            displayEmpty: true,
                                            renderValue: (selected) => {
                                                if (selected.length === 0) {
                                                    return (
                                                        <Typography
                                                            className='text-secondary-hint text-[16px]'
                                                            variant='subtitle2'
                                                        >
                                                            {t('common.Select')}
                                                        </Typography>
                                                    )
                                                }
                                                return getNameByCode(selected, provinceList)
                                            },
                                        }}
                                    >
                                        {provinceList?.map((option) => (
                                            <MenuItem key={option.code} value={option.code}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                )}
                            />
                        )}

                        {/* 香港展示 */}
                        {districtList && (
                            <Controller
                                name='district'
                                control={control}
                                shouldUnregister={true}
                                defaultValue={''}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        {...field}
                                        select
                                        label={
                                            <TextLabel className='font-semiboldFamily'>
                                                {t('SignUpPage.DistrictDisplay')}
                                            </TextLabel>
                                        }
                                        variant='standard'
                                        color='secondary'
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={!!error}
                                        helperText={error ? error.message : null}
                                        SelectProps={{
                                            MenuProps,
                                            displayEmpty: true,
                                            renderValue: (selected) => {
                                                if (selected.length === 0) {
                                                    return (
                                                        <Typography
                                                            className='text-secondary-hint text-[16px]'
                                                            variant='subtitle2'
                                                        >
                                                            {t('common.Select')}
                                                        </Typography>
                                                    )
                                                }
                                                return getNameByCode(selected, districtList)
                                            },
                                        }}
                                    >
                                        {districtList?.map((option) => {
                                            return option?.level === 0 ? (
                                                <ListSubheaderWrap key={option.code}>{option.name}</ListSubheaderWrap>
                                            ) : (
                                                <MenuItemWrap key={option.code} value={option.code}>
                                                    {option.name}
                                                </MenuItemWrap>
                                            )
                                        })}
                                    </TextField>
                                )}
                            />
                        )}

                        <Label
                            title={t('SignUpPage.PasswordDisplay')}
                            about='*********'
                            right={t('myProfile.changePassword')}
                            fun={() => {
                                setModalComponent(<ChangePassword onClose={onClose} />)
                                onOpen()
                            }}
                        />

                        <Controller
                            name='languagePreference'
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    select
                                    label={
                                        <TextLabel className='font-semiboldFamily'>
                                            {t('myProfile.languagePreference')}
                                        </TextLabel>
                                    }
                                    variant='standard'
                                    color='secondary'
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {languageList.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />

                        <Button
                            disabled={updateSSOProfile.isPending}
                            type='submit'
                            className={
                                'text-white normal-case text-base leading-[19.2px] py-[6.5px] h-fit font-semiboldFamily min-w-[149px] mb-5 ' +
                                (updateSSOProfile.isPending ? ' bg-secondary-grey' : 'bg-website-gradation')
                            }
                            variant='primary'
                        >
                            {t('myProfile.save')}
                        </Button>
                    </Form>
                </>
            )}
        </>
    )
}
