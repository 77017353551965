// Package imports
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
// Project imports
// Folder imports
import { Button } from '@mui/material';
import { onClickSendEmail } from 'utils/email';

const ForMoreEnquirySection = () => {
	const { t, i18n: { language } } = useTranslation()

	const email = 'hospitalitysales@kaitaksportspark.com.hk'

	return (
		<div className="w-full bg-container mt-8 md:py-[80px] py-[32px] flex flex-col items-center">
			<div className='text-h2 uppercase mb-[16px]'>{t('pearlClub.forMoreEnquiry')}</div>
			<div className="text-body2 mb-[16px] flex flex-col items-center md:flex-row md:items-center">
				<span>{t('pearlClub.pleaseContact')}&nbsp;</span>
				<a className='underline cursor-pointer' onClick={() => onClickSendEmail(email)}>
					{email}
				</a>
			</div>
			<div>
				<Button
						onClick={() => window.open('https://ktspl.ungerboeck.net/prod/app85.cshtml?aat=3472444c3645796a386e56595556746f39373443743051377a6c356a7641576c34393676677741674a50733d')}
						className='text-white text-lg px-5 h-fit py-2 min-w-[240px] w-fit'
						variant='primaryRed'
				>
						{t('pearlClub.pearlClubPortal')}
				</Button>
			</div>
		</div>
	);
}

export default ForMoreEnquirySection;