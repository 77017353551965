import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'

import Loading from 'components/Loading'
import { promotion } from 'service/promotionService'
import BannerSwiper from 'components/detailComponents/BannerSwiper'
import NavigationAndIntro from 'page/Promotions/components/NavigationAndIntro'
import AboutTabs from 'page/Promotions/components/AboutTabs'
import RecommendedCards from 'page/Promotions/components/RecommendedCards'
import RelatedCards from 'page/Promotions/components/RelatedCards'
import PromotionRegister from '../components/Register'
import { TabContainer } from 'components/TabContainer'
import { About } from '../components/About'
import useNotAvailableAutoLeave from 'utils/useNotAvailableAutoLeave'
import { notAvailableErrorCode } from 'utils/filter'

export default function Detail() {
    const page = useRef(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const id = searchParams.get('id') + ''
    const relatedRef = useRef(null)
    const recommendedRef = useRef(null)
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const {
        data: items,
        isFetching,
        isPending,
    } = useQuery({
        queryKey: ['promotionDetail', language, id],
        queryFn: () => promotion.detail({ lang: language, identityUuid: id }),
    })
    useNotAvailableAutoLeave(items?.errorCode, notAvailableErrorCode.promotion)
    if (isPending) {
        return <Loading />
    }
    if (items?.result === null) return null
    return (
        <div ref={page} className='bg-white'>
            {items && (
                <>
                    <section>
                        {items.promotionImages.length > 0 && <BannerSwiper items={items.promotionImages} />}
                    </section>
                    <section className='bg-container flex'>
                        <div className='flex-1'>
                            <NavigationAndIntro items={items} />
                        </div>
                        <PromotionRegister items={items} />
                    </section>
                    <section>
                        <TabContainer
                            components={[
                                (items.description || items.tagList.length > 0) && {
                                    key: 'about',
                                    title: t('eventPage.about'),
                                    component: () => <About items={items} isNeedExpand={false} />,
                                },
                                items?.eventRespDTOList?.length > 0 && {
                                    key: 'related',
                                    title: t('visitList.promotions'),
                                    component: () => (
                                        <RelatedCards relatedRef={relatedRef} items={items.eventRespDTOList} />
                                    ),
                                },
                                items?.itemRespDTOList?.length > 0 && {
                                    key: 'recommended',
                                    title: t('eventPage.uMayAlsoLike'),
                                    component: () => (
                                        <RecommendedCards
                                            youMayLikeRef={recommendedRef}
                                            items={items.itemRespDTOList}
                                        />
                                    ),
                                },
                            ].filter((item) => item)}
                        />
                    </section>
                    {/* <section>
                            <AboutTabs
                                relatedClick={() =>
                                    relatedRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
                                }
                                recommendedClick={() =>
                                    recommendedRef.current.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'center',
                                    })
                                }
                                items={items}
                            />
                        </section>
                        {items.eventRespDTOList.length > 0 && (
                            <RelatedCards relatedRef={relatedRef} items={items.eventRespDTOList} />
                        )}
                        {items.itemRespDTOList.length > 0 && (
                            <RecommendedCards youMayLikeRef={recommendedRef} items={items.itemRespDTOList} />
                        )} */}
                </>
            )}
        </div>
    )
}
