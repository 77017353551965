import Banner from 'components/shopDine/Banner'
import { TabContainer } from 'components/TabContainer'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import bannerTop from 'resources/images/parkRule/topBanner.jpg'
import 'page/ParkRules/index.css'
import { Box, Divider, Tab, Tabs } from '@mui/material'
import whetherOne from 'resources/images/parkRule/whether(4).png'
import whetherTwo from 'resources/images/parkRule/whether(5).png'
import whetherThree from 'resources/images/parkRule/whether(6).png'

import whetherFour from 'resources/images/parkRule/whether(7).png'

import whetherFive from 'resources/images/parkRule/whether(8).png'
import whetherSix from 'resources/images/parkRule/whether(9).png'

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    )
}
export default function ParkRules() {
    const { t } = useTranslation()
    const [value, setValue] = React.useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const banner = {
        image: bannerTop,
        title: t('parkRules.title'),
        about: t('parkRules.bannerAbout'),
    }
    const tabs = [
        t('parkRules.parkRules'),
        t('parkRules.securityScreening'),
        // t('parkRules.conditionsOfEntry'),
        // t('parkRules.inclementWeatherArrangement'),
    ]
    const whetherList = [
        {
            img: whetherOne,
            text: 'Thunderstorm – The match will be postponed, canceled, or rescheduled if there are any thunderstorms in the local area.',
        },
        { img: whetherTwo, text: 'Amber Rainstorm Warning – Continue as scheduled.' },
        { img: whetherThree, text: 'Red Rainstorm Warning – Continue as scheduled.' },
        { img: whetherFour, text: 'Black Rainstorm Warning Signal – Postpone, cancel, or reschedule.' },
        { img: whetherFive, text: 'T1 and T3 – Continue as scheduled.' },
        { img: whetherSix, text: 'T8 or above - Cancel or reschedule.' },
    ]
    return (
        <div>
            <Banner banner={banner} />
            <section className='mt-12'>
                <div className='bg-container flex'>
                    <Tabs
                        textColor='secondary'
                        indicatorColor='secondary'
                        scrollButtons={false}
                        variant='scrollable'
                        value={value}
                        onChange={handleChange}
                        className='flex-1 border-solid bg-white border-b-[1px] border-secondary-darkGery w-full'
                        aria-label='basic tabs example'
                    >
                        {tabs.map((i, index) => {
                            return (
                                <Tab
                                    label={i}
                                    {...a11yProps(index)}
                                    className='normal-case'
                                    sx={{
                                        fontWeight: value === index ? 600 : 500,
                                        fontSize: value === index ? 20 : 16,
                                        textTransform: 'none',
                                        alignItems: 'flex-start',
                                        padding: 0,
                                        marginRight: 5,
                                        minWidth: 0,
                                        maxWidth: 1000,
                                    }}
                                />
                            )
                        })}
                    </Tabs>
                </div>
                <div className='bg-container flex'>
                    <CustomTabPanel value={value} index={0}>
                        <div className='bg-white whitespace-pre text-wrap text-secondary-midnightBlue'>
                            <div className='text-h3 mt-6 md:mt-10 mb-6'>{t('parkRules.parkRules')}</div>
                            <div className='mb-[60px] text-[16px] leading-[24px]'>
                                <div className='font-semibold mb-6'>{t('parkRules.parkRulesDesc0')}</div>
                                <div>{t('parkRules.parkRulesDesc1')}</div>
                                <div>{t('parkRules.parkRulesDesc2')}</div>
                                <div>{t('parkRules.parkRulesDesc3')}</div>
                                <div>{t('parkRules.parkRulesDesc4')}</div>
                                <div>{t('parkRules.parkRulesDesc5')}</div>
                                <div>{t('parkRules.parkRulesDesc6')}</div>
                                <div>{t('parkRules.parkRulesDesc7')}</div>
                                <div>{t('parkRules.parkRulesDesc8')}</div>
                                <div>{t('parkRules.parkRulesDesc9')}</div>
                                <div>{t('parkRules.parkRulesDesc10')}</div>
                                <div>{t('parkRules.parkRulesDesc11')}</div>
                                <div>{t('parkRules.parkRulesDesc12')}</div>
                                <div>{t('parkRules.parkRulesDesc13')}</div>
                                <div>{t('parkRules.parkRulesDesc14')}</div>
                                <div className='mt-6'>{t('parkRules.parkRulesDesc15')}</div>
                            </div>
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <div className='bg-white whitespace-pre text-wrap text-secondary-midnightBlue'>
                            <div className='text-h3 mt-6 md:mt-10 mb-6'>{t('parkRules.securityScreening')}</div>
                            <div className='mb-[60px] text-body1'>
                                <div className='text-[20px] font-semibold leading-[24px] pb-1'>
                                    {t('parkRules.securityScreeningTitle1')}
                                </div>
                                <div className='text-[16px] font-medium leading-[24px]'>
                                    {t('parkRules.securityScreeningDesc')}
                                </div>
                                <Divider className='my-6 border-[#D4D6D9]' />
                                <div className='text-[20px] font-semibold leading-[24px] pb-1'>
                                    {t('parkRules.securityScreeningTitle2')}
                                </div>
                                <div className='text-[16px] font-medium leading-[24px]'>
                                    <div className=''>{t('parkRules.securityScreeningDesc1')}</div>
                                    <ul
                                        style={{
                                            display: 'block',
                                            listStyle: 'disc',
                                            marginInlineStart: '0',
                                            marginInlineEnd: '0',
                                            paddingInlineStart: '40px',
                                            marginBlockStart: '1em',
                                            marginBlockEnd: '1em',
                                            unicodeBidi: 'isolate',
                                            margin: '0 auto 0 -20px',
                                        }}
                                    >
                                        <li>
                                            <span className='font-semibold'>
                                                {t('parkRules.securityScreeningSubtitle2')}
                                            </span>
                                            {t('parkRules.securityScreeningDesc2')}
                                        </li>
                                        <li>
                                            <span className='font-semibold'>
                                                {t('parkRules.securityScreeningSubtitle3')}
                                            </span>
                                            {t('parkRules.securityScreeningDesc3')}
                                        </li>
                                        <li>
                                            <span className='font-semibold'>
                                                {t('parkRules.securityScreeningSubtitle4')}
                                            </span>
                                            {t('parkRules.securityScreeningDesc4')}
                                        </li>
                                        <li>
                                            <span className='font-semibold'>
                                                {t('parkRules.securityScreeningSubtitle5')}
                                            </span>
                                            {t('parkRules.securityScreeningDesc5')}
                                        </li>
                                        <li>
                                            <span className='font-semibold'>
                                                {t('parkRules.securityScreeningSubtitle6')}
                                            </span>
                                            {t('parkRules.securityScreeningDesc6')}
                                        </li>
                                        <li>
                                            <span className='font-semibold'>
                                                {t('parkRules.securityScreeningSubtitle7')}
                                            </span>
                                            {t('parkRules.securityScreeningDesc7')}
                                        </li>
                                    </ul>
                                </div>
                                {/* line */}
                                {/* <div>
                                    <div>{t('parkRules.notAllowedInYouthSportsGround')}</div>
                                    The following items are prohibited from being brought into the venue:
                                    <ul>
                                        <li>Any kind of weapon</li>
                                        <li>Alcoholic beverages</li>
                                        <li>Illegal substance</li>
                                        <li>Laser pens or pointers</li>
                                        <li>Inflated lighter-than-air objects (e.g helium balloons)</li>
                                        <li>
                                            Wheeled recreational devices, including but not limited to skateboard,
                                            bicycles, scooters
                                        </li>
                                        <li>Drones, remote-controlled flying devices or kites</li>
                                        <li>
                                            Any other items deemed harmful, disruptive, offensive, or that may hinder
                                            the operation of the venue or its associated facilities
                                        </li>
                                        <li>
                                            Items prohibited by the laws of the Hong Kong Special Administrative Region
                                            of the People’s Republic of China
                                        </li>
                                        <li>Glass, metal containers, cans and bottles</li>
                                        <li>Bags larger than 35cm x 20cm x 30cm (too large to fit under your seat).</li>
                                        <li>Umbrellas and selfie sticks longer than 35cm in length.</li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </CustomTabPanel>
                    {/* <CustomTabPanel value={value} index={2}>
                        <div className='bg-white whitespace-pre text-wrap text-secondary-midnightBlue'>
                            <div className='text-h3 mt-6 md:mt-10 mb-6'>{t('parkRules.conditionsOfEntry')}</div>
                            <div className='mb-[60px] text-body1'>
                                <div>
                                    These terms and conditions shall apply:
                                    <div>
                                        (a)  to the areas within the perimeter boundary and inside all buildings and
                                        structures in the Youth Sports Ground, including the accreditation and screening
                                        facilities, if any; and
                                    </div>
                                    <div>
                                        (b)  during the whole period of staging the local and international events as
                                        designated by Kai Tak Sports Park Limited.
                                    </div>
                                </div>
                                <Divider className='my-6 border-[#D4D6D9]' />
                                <div>
                                    <div>
                                        1. Authorized personnel reserve the right to ask for valid proof of identity.
                                    </div>
                                    <div>
                                        2. One person per ticket regardless of age except children aged under 2 who do
                                        not occupy a seat.
                                    </div>
                                    <div>
                                        3. People under the age of 13 years must be under the supervision of a ticket
                                        holding parent, legal guardian always.
                                    </div>
                                    <div>
                                        4. No entry to restricted areas without express permission or the appropriate
                                        accreditation.
                                    </div>
                                    <div>
                                        5. No smoking or lighting of cigar, pipe, cigarette, naked flame, or
                                        e-cigarettes outside designated smoking areas.
                                    </div>
                                    <div>
                                        6. No unauthorised commercial filming, photography, video, audio recording or
                                        use any form of recording of a visual image or sound is allowed within the Youth
                                        Sports Ground.
                                    </div>
                                    <div>
                                        7. No pet or animal, except guide dogs, is allowed within the Youth Sports
                                        Ground.
                                    </div>
                                    <div>
                                        8. No outside food and beverage, except baby food, medication, and food and
                                        beverage from outlets operated or authorized by Kai Tak Sports Park Limited is
                                        allowed.
                                    </div>
                                    <div>
                                        9. Audiences are required to go through security checking before entering the
                                        event venue.
                                    </div>
                                    <div>
                                        10. To ensure public safety and order, a person must not:
                                        <ul className='list-inside list-disc pl-2'>
                                            <li> Hold any items harmful to public safety.</li>
                                            <li> Cause any disturbance, nuisance, or endangerment to public.</li>
                                            <li> Engage in any unlawful or immoral activity.</li>
                                            <li>
                                                Obstruct, disturb, interrupt, or annoy other persons or the operation.
                                            </li>
                                            <li> Damage or destroy any items in the Youth Sports Ground.</li>
                                            <li> Conduct unauthorised commercial activities or performances.</li>
                                            <li>
                                                Go into any restricted, accredited and authorised areas including the
                                                field of play.
                                            </li>
                                            <li> Any forms of demonstration or acts of protest.</li>
                                            <li>
                                                Collect, distribute or conduct sales or merchandise without the
                                                permission of Kai Tak Sports Park Limited.
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        11.The following items are prohibited from being brought into the venue:
                                        <ul className='list-inside list-disc pl-2'>
                                            <li>Any kind of weapon</li>
                                            <li>Glass, metal containers, cans and bottles</li>
                                            <li>Alcoholic beverages</li>
                                            <li>Illegal substance</li>
                                            <li>Laser pens or pointers</li>
                                            <li>Inflated lighter-than-air objects (e.g. helium balloons)</li>
                                            <li>
                                                Wheeled recreational devices, including but not limited to skateboard,
                                                bicycles, scooters
                                            </li>
                                            <li>Drones, remote-controlled flying devices or kites</li>
                                            <li>
                                                Bags larger than 35cm x 20cm x 30cm (too large to fit under your seat).
                                            </li>
                                            <li>Umbrellas and selfie sticks longer than 35cm in length.</li>
                                            <li>
                                                Any other items deemed harmful, disruptive, offensive, or that may
                                                hinder the operation of the venue or its associated facilities
                                            </li>
                                            <li>
                                                Items prohibited by the laws of the Hong Kong Special Administrative
                                                Region of the People’s Republic of China
                                            </li>
                                        </ul>
                                    </div>
                                    <div>12. Proper attire must always be worn.</div>
                                    <div>
                                        13. Any person who uses the facilities or equipment improperly or causes
                                        obstruction / disturbance to other persons will be stopped or invited to leave
                                        the venue.
                                    </div>
                                    <div>
                                        14. All spectators must observe and comply with all notices to public issued by
                                        Kai Tak Sports Park Limited concerning operational arrangements.
                                    </div>
                                    <div>
                                        15. All persons must comply with the Law of the People’s Republic of China on
                                        Safeguarding National Security in the Hong Kong Special Administrative Region
                                        and other relevant laws of Hong Kong in relation to the safeguarding of national
                                        security (“National Security Law”); and ensure that no act or activity which is
                                        likely to constitute or is likely to cause the occurrence of an offence
                                        endangering national security under the National Security Law or other laws of
                                        Hong Kong, or which would otherwise be contrary to the interests of national
                                        security, is engaged in the Youth Sports Ground.
                                    </div>
                                    <div>
                                        16.If any event involving the display or use or raising of the national flag,
                                        national emblem, the regional flag or regional emblem, the national flag-raising
                                        ceremony, or the playing or singing of the national anthem, any person using the
                                        Youth Sports Ground shall comply with all applicable laws and regulations
                                        including but not limited to the National Flag and National Emblem Ordinance
                                        (Instrument A401), the Regional Flag and Regional Emblem Ordinance (Instrument
                                        A602)and the National Anthem Ordinance (Instrument A405).
                                    </div>
                                    <div>
                                        17.No unauthorized air horns, whistles, cowbells, or other noise- marking
                                        devices.
                                    </div>
                                    <div>
                                        18.Any form of unauthorized publicity, messages or graphic that are provocative
                                        or offensive, as determined solely by Kai Tak Sports Park Limited, appearing on
                                        flags, banners, clothes, accessories, or any items worn, used or possessed are
                                        prohibited.
                                    </div>
                                    <div>
                                        19.Personal belongings should not be left unattended. Kai Tak Sports Park
                                        Limited is not responsible for the safe custody and/ or loss or damage of
                                        personal property brought to and/or left in the Venue.
                                    </div>
                                    <div>
                                        20.The Youth Sports Ground is under closed circuit television surveillance with
                                        a recording facility.
                                    </div>
                                    <div>
                                        21.Kai Tak Sports Park Limited reserves the right to deny entry and or evict
                                        personnel from the venue without refund who in its sole opinion
                                        <br />
                                        a.Breaches the conditions of entry.
                                        <br />
                                        b.Does not produce an authorised ticket.
                                        <br />
                                        c.Refusessecurityscreeningorasecuritysearchinsidethe venue.
                                        <br />
                                        d.Hinderstheexperienceandenjoymentforotherpeople attending the event.
                                        <br />
                                        e.Fails to comply with instructions from security and guest service staff.
                                        <br />
                                        f.Is in possession of any prohibited items as set out in point 11.
                                        <br />
                                        g.Projectilesofanykind.
                                        <br />
                                        h.Enter any restricted area without the appropriate accreditation or
                                        authorisation.
                                        <br />
                                        i.Damages, interferes or tampers with any Kai Tak Sports Park property and
                                        equipment.
                                        <br />
                                        j.Refuses to follow any laws and regulations as set out by the Hong Kong Special
                                        Administrative Region of the People’s Republic of China.
                                    </div>
                                    <div>
                                        22.Personnel attending an Event do so at their own risk and assume all risk and
                                        liability for personal injury, medical condition or death, including that of any
                                        minors in their care. Kai Tak Sports Park Limited bears no liability for any
                                        personal injuries, health problems or death sustained at or after an Event,
                                        including directly or indirectly resulting from any existing medical condition.
                                    </div>
                                    23.Kai Tak Sports Park Limited may hold liable for prosecution or penalties any
                                    person who breaches the conditions of entry.
                                    <div>
                                        24.Kai Tak Sports Park Limited reserves the right and is entitled to change,
                                        alter, add, delete, suspend, revise and update all information in this document
                                        at any time at its absolute discretion without giving any reasons or prior
                                        notice.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <div className='bg-white whitespace-pre text-wrap text-secondary-midnightBlue'>
                            <div className='text-h3 mt-6 md:mt-10 mb-6'>{t('parkRules.inclementWeatherArrangement')}</div>
                            <div className='mb-[60px] text-body1'>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-6'>
                                    <div>
                                        In the event of bad weather, the following general arrangements will apply.
                                        Spectators should pay attention to any announcements from Hong Kong Observatory
                                        (HKO).
                                        <br />
                                        <br />
                                        If Pre-No. 8 special announcement is made or the Tropical Cyclone Warning Signal
                                        No. 8 or above/Black Rainstorm Warning Signal is issued by the HKO. The event
                                        organizer, The Football Association of Hong Kong, China (HKFA) will make a final
                                        decision 2.5 hours prior to the match kick-off time at 14:00 about the match
                                        arrangement with updating regarding game information on HKFA website.
                                        <br />
                                        <br />
                                        This is the HKFA link (English version) – 2024-25 HKFA Second Division League :
                                        Sai Kung vs Wong Tai Sin:
                                        <br />
                                        <a
                                            href='https://www.hkfa.com/en/competitions/result/43905/detail'
                                            className='text-[#0000EE] underline'
                                            target='_blank'
                                            rel='noreferrer'
                                        >
                                            https://www.hkfa.com/en/competitions/result/43905/detail
                                        </a>
                                    </div>
                                    <div className='flex flex-col gap-y-6'>
                                        {whetherList.map((i) => {
                                            return (
                                                <div className='flex gap-x-5 items-center'>
                                                    <img src={i.img} className='w-20 h-20' />
                                                    <div>{i.text}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CustomTabPanel> */}
                </div>
            </section>
        </div>
    )
}
