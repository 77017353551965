import React from 'react'
import tw, { styled } from 'twin.macro'
import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import AboutComponent from 'page/CarPark/components/AboutComponent'
const Wrap = tw.div``
const ListWrap = tw.div``

const ChipWrap = styled(Chip)`
    border-radius: 0;
    border: 1px solid #0a173d;
    color: #283266;
`

const PeakDay = ({ title, weekdayHourly, weeklyHourly }) => {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    return (
        <>
            <div className='text-[16px] pt-5 text-[#0A173D]  pb-2 cursor-pointer underline'>{title}</div>
            <div className='text-[#0A173D] font-semiboldFamily text-lg leading-6'>
                <div className='flex gap-4 md:gap-10'>
                    <li className='w-[271px] font-regular'>{t('facilitiesBooking.weekdayHourly')}</li>
                    <div className=''>{weekdayHourly}</div>
                </div>
                <div className='flex gap-4 md:gap-10'>
                    <li className='w-[271px] font-regular'>{t('facilitiesBooking.weeklyHourly')}</li>
                    <div className=''>{weeklyHourly}</div>
                </div>
            </div>
        </>
    )
}
export default function ParkingFee({ items }) {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    return (
        <Wrap className='bg-container pt-6'>
            {items.parkingFeeDescription && (
                <AboutComponent isNeedCk={false} description={items.parkingFeeDescription} />
            )}
        </Wrap>
    )
}
