import { useState, useMemo } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { IconButton, useMediaQuery } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { cn } from 'utils/cn'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const BgTransparent = ({ children }) => {
    return (
        <div
            className={cn(
                'absolute top-0 left-0 z-10 w-full h-full bg-[#231448] opacity-40 group-hover:bg-website-gradation group-hover:opacity-80',
            )}
        >
            {children}
        </div>
    )
}

/**
 * SimpleSwiper component displays a swiper with a title and a list of items.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.className - The CSS class name for the component.
 * @param {string} props.title - The title of the swiper.
 * @param {string} props.titleClassName - The CSS class name for the title.
 * @param {Array<{image:string; title:string; description:string}>} props.list - The list of items to be displayed in the swiper.
 * @param {Function} props.onClick - The function to be called when an item is clicked.
 * @returns {JSX.Element} The rendered SimpleSwiper component.
 */
const SimpleSwiper = ({ className, title, titleClassName, list, onClick }) => {
    const [swiper, setSwiper] = useState(null)
    const [isBeginning, setIsBeginning] = useState(true)
    const [isEnd, setIsEnd] = useState(false)

    const handleSlideChange = (swiperInstance) => {
        setIsBeginning(swiperInstance.isBeginning)
        setIsEnd(swiperInstance.isEnd)
    }

    const isPad = useMediaQuery('(min-width:1068px)')
    const isLg = useMediaQuery('(min-width:1280px)')
    const slidesPerView = useMemo(() => {
        if (isLg) {
            return list.length > 4 ? 4.2 : list.length > 3 ? 4 : list.length
        } else if (isPad) {
            return Math.min(3, list.length)
        } else {
            return Math.min(2, list.length)
        }
    }, [list, isPad, isLg])

    const isHiddenAction = list.length <= slidesPerView

    return (
        <>
            <div className={cn('mt-24 -translate-y-24 md:block hidden', className)}>
                <Swiper
                    slidesPerView={slidesPerView}
                    spaceBetween={0}
                    modules={[Navigation]}
                    onSwiper={setSwiper}
                    onSlideChange={handleSlideChange}
                    onBreakpoint={setSwiper}
                    className='mySwiper'
                >
                    <div slot='container-start'>
                        <SwiperHeader
                            title={title}
                            swiper={swiper}
                            isBeginning={isBeginning}
                            isEnd={isEnd}
                            isHiddenAction={isHiddenAction}
                        />
                    </div>
                    {list?.map((item, index) => {
                        return (
                            <SwiperSlide
                                onClick={() => {
                                    onClick && onClick(item, index)
                                }}
                                className={onClick ? 'cursor-pointer' : ''}
                                key={index}
                            >
                                <div className='relative w-full h-[300px] lg:h-[440px] group'>
                                    <BgTransparent />
                                    <img alt='' src={item.image} className='h-full w-full object-cover' />
                                    <div className='absolute z-30 top-1/2 translate-y-10 group-hover:translate-y-0 break-words  transition-all delay-150 duration-300 ease-in-out group-hover:top-1/2 left-1/2 -translate-x-1/2 font-semiboldFamily text-white text-xl md:text-[28px] md:leading-[28px] lg:w-[300px]'>
                                        <div>{item.title}</div>
                                        <div className='transition-all delay-150 duration-300 ease-in-out textarea-content opacity-0 group-hover:opacity-100 mt-6 font-mediumFamily w-full break-words text-[16px] leading-[24px] line-clamp-4 lg:line-clamp-6'>
                                            {item.description}
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
            <div className={cn('mt-24 -translate-y-24 md:hidden', className)}>
                <div className={cn(' text-secondary-midnightBlue text-title pb-8 bg-container', titleClassName)}>
                    {title}
                </div>
                <ul>
                    {list.map((item, index) => (
                        <li
                            onClick={() => {
                                onClick && onClick(item, index)
                            }}
                            key={index}
                            className={cn(onClick && 'cursor-pointer')}
                        >
                            <div className='relative w-full h-[456px] group'>
                                <BgTransparent />
                                <img alt='' src={item.image} className='h-full w-full object-cover' />
                                <div className='bg-container absolute z-30 top-1/2 translate-y-10 group-hover:translate-y-0 transition-all delay-150 duration-300 ease-in-out group-hover:top-1/2 left-1/2 -translate-x-1/2 font-semiboldFamily text-white text-xl md:text-[28px] md:leading-[28px] lg:w-[300px]'>
                                    <div>{item.title}</div>
                                    <div className='transition-all delay-150 duration-300 ease-in-out opacity-0 break-words line-clamp-5 group-hover:opacity-100 mt-6 font-mediumFamily text-[16px] leading-[24px]'>
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

const SwiperHeader = ({ title, titleClassName, swiper, isBeginning, isEnd, isHiddenAction }) => {
    const ispad = useMediaQuery('(min-width:768px)')
    const fontSize = ispad ? 'middle' : 'small'

    return (
        <div className='bg-container flex justify-between items-center pb-8'>
            <div className={cn(' text-secondary-midnightBlue text-title', titleClassName)}>{title}</div>
            <div className={cn('flex gap-5', isHiddenAction && 'hidden')}>
                <IconButton
                    className='rounded-full aspect-square border-solid border-[1.5px] w-[30px] h-[30px] md:h-[45px] md:w-[45px] lg:h-[60px] lg:w-[60px]'
                    aria-label='ArrowBack'
                    size='middle'
                    onClick={() => swiper?.slidePrev()}
                    disabled={isBeginning}
                >
                    <ArrowBackIcon fontSize={fontSize} />
                </IconButton>
                <IconButton
                    className='rounded-full aspect-square border-solid border-[1.5px] w-[30px] h-[30px] md:h-[45px] md:w-[45px] lg:h-[60px] lg:w-[60px]'
                    aria-label='ArrowForward'
                    size='middle'
                    onClick={() => swiper?.slideNext()}
                    disabled={isEnd}
                >
                    <ArrowForwardIcon fontSize={fontSize} />
                </IconButton>
            </div>
        </div>
    )
}

export default SimpleSwiper
