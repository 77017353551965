import React from 'react'
import { useTranslation } from 'react-i18next'
import busList from './BusData.js'
import {sortedMinibusList} from './BusData.js'
import TextContainer from 'page/Transportation/components/TextContainer'
import guide from 'resources/images/transportation_minibus_tc.jpg'
import guideSc from 'resources/images/transportation_minibus_sc.jpg'
import WrapBox from 'page/Transportation/components/WrapBox'
import BusCommonComponent from 'page/Transportation/components/BusCommonComponent'
import busIntro from 'resources/images/busIntro.png'

import BusStation1A from 'resources/images/PrinceEdwardRoadEastnearTakKuLingRoadRestGarden.png'
import BusStation1B from 'resources/images/PrinceEdwardRoadEastoppositeKaiTakGovernmentBuilding.png'
import BusStation1C from 'resources/images/NgaTsinWaiRoadnearNamKokRoad.png'
import BusStation2A from 'resources/images/MaTauChungRoadNearSungWongToiPlayground.png'
import BusStation2B from 'resources/images/PrinceEdwardRoadEastoppositeRegalOrientalHotel.png'
import BusStation3A from 'resources/images/MaTauChungRoadoutsideArgyleStreetPlayground.png'
import BusStation3B from 'resources/images/PrinceEdwardRoadEastnearLanYuen.png'
import BusStation4A from 'resources/images/MaTauKokRoadnearTheHongKongandChinaGasCompanyLimitedMaTauKokControlCentre.png'
import BusStation4B from 'resources/images/SanMaTauRoadKapokIndustrialBuilding.png'
import BusStation5 from 'resources/images/KaiTak.png'

function Minibus() {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    
    return (
        <WrapBox
            image={language === "zh_CN" ? guideSc : guide}
            component={
                <TextContainer
                    title={t('transport.byMinibus')}
                    link={'https://www.kmb.hk/'}
                    isWeb={t('transport.busWeb')}
                    endWord={t('transport.busAboutEnd')}
                    about={t('transport.miniBusAbout')}
                />
            }
            bomComponent={
                <BusCommonComponent
                    isBus={true}
                    topFun={() => {}}
                    busItems={[
                        {
                            isBus: false,
                            topFun: () => {},
                            image: BusStation1A,
                            wording: {
                                routes: checkRoutes('Prince Edward Road East, near Tak Ku Ling Road Rest Garden', language),
                                busStopTitle: t('transport.miniBusPrinceEdwardRoadEastnearTakKuLingRoadRestGarden'),
                                busStopNumber: "1A",
                                walkDistDetail: t('transport.miniBusPrinceEdwardRoadEastnearTakKuLingRoadRestGardenDistance'),
                            },
                        },
                        {
                            isBus: false,
                            topFun: () => {},
                            image: BusStation1B,
                            wording: {
                                routes: checkRoutes('Prince Edward Road East, opposite Kai Tak Government Building', language),
                                busStopTitle: t('transport.miniBusPrinceEdwardRoadEastoppositeKaiTakGovernmentBuilding'),
                                busStopNumber: "1B",
                                walkDistDetail: t('transport.miniBusPrinceEdwardRoadEastoppositeKaiTakGovernmentBuildingDistance'),
                            },
                        },
                        {
                            isBus: false,
                            topFun: () => {},
                            image: BusStation1C,
                            wording: {
                                routes: checkRoutes('Nga Tsin Wai Road, near Nam Kok Road', language),
                                busStopTitle: t('transport.miniBusNgaTsinWaiRoadnearNamKokRoad'),
                                busStopNumber: "1C",
                                walkDistDetail: t('transport.miniBusNgaTsinWaiRoadnearNamKokRoadDistance'),
                            },
                        },
                        {
                            isBus: false,
                            topFun: () => {},
                            image: BusStation2A,
                            wording: {
                                routes: checkRoutes('Ma Tau Chung Road, Near Sung Wong Toi Playground', language),
                                busStopTitle: t('transport.miniBusMaTauChungRoadNearSungWongToiPlayground'),
                                busStopNumber: "2A",
                                walkDistDetail: t('transport.miniBusMaTauChungRoadNearSungWongToiPlaygroundDistance'),
                            },
                        },
                        {
                            isBus: false,
                            topFun: () => {},
                            image: BusStation2B,
                            wording: {
                                routes: checkRoutes('Prince Edward Road East, opposite Regal Oriental Hotel', language),
                                busStopTitle: t('transport.miniBusPrinceEdwardRoadEastoppositeRegalOrientalHotel'),
                                busStopNumber: "2B",
                                walkDistDetail: t('transport.miniBusPrinceEdwardRoadEastoppositeRegalOrientalHotelDistance'),
                            },
                        },
                        {
                            isBus: false,
                            topFun: () => {},
                            image: BusStation3A,
                            wording: {
                                routes: checkRoutes('Ma Tau Chung Road, outside Argyle Street Playground', language),
                                busStopTitle: t('transport.miniBusMaTauChungRoadoutsideArgyleStreetPlayground'),
                                busStopNumber: "3A",
                                walkDistDetail: t('transport.miniBusMaTauChungRoadoutsideArgyleStreetPlaygroundDistance'),
                            },
                        },
                        {
                            isBus: false,
                            topFun: () => {},
                            image: BusStation3B,
                            wording: {
                                routes: checkRoutes('Prince Edward Road East, near Lan Yuen', language),
                                busStopTitle: t('transport.miniBusPrinceEdwardRoadEastnearLanYuen'),
                                busStopNumber: "3B",
                                walkDistDetail: t('transport.miniBusPrinceEdwardRoadEastnearLanYuenDistance'),
                            },
                        },
                        {
                            isBus: false,
                            topFun: () => {},
                            image: BusStation4A,
                            wording: {
                                routes: checkRoutes('Ma Tau Kok Road, near The Hong Kong and China Gas Company Limited Ma Tau Kok Control Centre', language),
                                busStopTitle: t('transport.miniBusMaTauKokRoadnearTheHongKongandChinaGasCompanyLimitedMaTauKokControlCentre'),
                                busStopNumber: "4A",
                                walkDistDetail: t('transport.miniBusMaTauKokRoadnearTheHongKongandChinaGasCompanyLimitedMaTauKokControlCentreDistance'),
                            },
                        },
                        {
                            isBus: false,
                            topFun: () => {},
                            image: BusStation4B,
                            wording: {
                                routes: checkRoutes('San Ma Tau Road, Kapok Industrial Building', language),
                                busStopTitle: t('transport.miniBusSanMaTauRoadKapokIndustrialBuilding'),
                                busStopNumber: "4B",
                                walkDistDetail: t('transport.miniBusSanMaTauRoadKapokIndustrialBuildingDistance'),
                            },
                        },
                        {
                            isBus: false,
                            topFun: () => {},
                            image: BusStation5,
                            wording: {
                                routes: checkRoutes('Kai Tak, AIRSIDE', language),
                                busStopTitle: t('transport.miniBusKaiTak'),
                                busStopNumber: "5",
                                walkDistDetail: t('transport.miniBusKaiTakDistance'),
                            },
                        },
                    ]}
                />
            }
        />
    )
}

const checkRoutes = (StationName, language) => { 
    const filteredList = sortedMinibusList.filter(item => item.BusStop1 === StationName || item.BusStop2 === StationName || item.BusStop3 === StationName );
    return filteredList.map(({routeNo, EN, TC, SC}) => ({busRoute: routeNo, busRouteAbout: language === "en_US"? EN : (language === "zh_HK" ? TC : SC)}))
}

// const checkRoutes = (StationName, language) => {
//     const filteredList = busList.filter(
//         (item) => item.BusStop1 === StationName || item.BusStop2 === StationName || item.BusStop3 === StationName,
//     )
//     return filteredList.map(({ routeNo, EN, TC, SC }) => ({
//         busRoute: routeNo,
//         busRouteAbout: language === 'en_US' ? EN : language === 'zh_HK' ? TC : SC,
//     }))
// }

export default Minibus
