import React, { useState, useEffect, useMemo } from 'react'
import { Button, useMediaQuery } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { useMutation, useQuery } from '@tanstack/react-query'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useTranslation } from 'react-i18next'

import TitleGradient from 'components/landingComponents/TitleGradient'
import Seemore from 'components/landingComponents/SeeMore'
import ExploreSwiperCard from 'components/landingComponents/ExploreSwiperCard'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import { landing } from 'service/landingService'
import { shopDineService } from 'service/restaurantService'
import Loading from 'components/Loading'
import { useNavigate } from 'react-router-dom'

function ExploreShopDine() {
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const navigate = useNavigate()
    const isDesktop = useMediaQuery('(min-width:1024px)')
    const isPad = useMediaQuery('(min-width:768px)')
    const [swiper, setSwiper] = useState(null)
    const [type, setType] = useState('shop')
    const [slideIndex, setSlideIndex] = useState(0)

    // const [dine, setDine] = useState([])
    const btnVals = [
        { text: t('landing.shop'), val: 'shop' },
        { text: t('landing.dine'), val: 'dine' },
        { text: t('stadium.stadiumTitle'), val: 'stadium' },
        // { text: t('shop.stadiumBitesBrews'), val: 'stadiumBitesBrews' },
    ]
    // const getAllShopAndDine = useMutation({
    //     mutationFn: landing.getAllShopAndDine,
    //     onSuccess: setDine,
    // })

    // useEffect(() => {
    //     getAllShopAndDine.mutate({
    //         address: null,
    //         name: null,
    //         lang: 'en_US',
    //         size: 5,
    //         type: 'dine',
    //     })
    //     console.log('dine')
    //     console.log(dine)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])
    const { data: shopFeaturedList, shopIsFetching } = useQuery({
        queryKey: ['getShopBannerFeaturedList', language],
        queryFn: () =>
            shopDineService.getShopDineBannerFeaturedList({
                lang: language,
                bannerSize: 5,
                featuredSize: 10,
                type: 'shop',
            }),
    })
    const { data: dineFeaturedList, dineIsFetching } = useQuery({
        queryKey: ['getDineBannerFeaturedList', language],
        queryFn: () =>
            shopDineService.getShopDineBannerFeaturedList({
                lang: language,
                bannerSize: 5,
                featuredSize: 10,
                type: 'dine',
            }),
    })
    const { data: stadiumFeaturedList, stadiumIsFetching } = useQuery({
        queryKey: ['getStadiumBannerFeaturedList', language],
        queryFn: () =>
            shopDineService.getShopDineBannerFeaturedList({
                lang: language,
                bannerSize: 5,
                featuredSize: 10,
                type: 'championbar',
            }),
    })
    const renderList = useMemo(() => {
        if (type === 'shop') {
            return shopFeaturedList
        }
        if (type === 'dine') {
            return dineFeaturedList
        }

        return stadiumFeaturedList
    }, [type, shopFeaturedList, dineFeaturedList, stadiumFeaturedList])

    if (shopIsFetching || dineIsFetching || stadiumIsFetching) return <Loading />
    // console.log(shopDineFeaturedList)

    return (
        <div className='w-full bg-exploreBgSm sm:bg-exploreBgMd lg:bg-exploreBg bg-cover bg-center bg-no-repeat pl-4 overflow-hidden pt-8 mt-8 md:pt-16 md:mt-16 md:pl-[50px] h-auto xl:h-[780px] xl:pl-[80px] pb-8 sm:pb-[60px]'>
            <div className='xl:pl-[70px]'>
                <TitleGradient text={t('landing.exploreShopDine')} className='uppercase' />
                <div className='pt-8 flex justify-between items-center pr-4 md:pr-[50px] pb-[32px] xl:pb-[64px] xl:pr-[150px]'>
                    <div className='flex flex-wrap gap-[10px]'>
                        {btnVals.map((btnVal, index) => {
                            return (
                                <Button
                                    key={index}
                                    onClick={() => {
                                        setType(btnVal.val)
                                        // console.log(btnVal.val)
                                        // getAllShopAndDine.mutate({
                                        //     address: null,
                                        //     name: null,
                                        //     lang: 'en_US',
                                        //     size: 5,
                                        //     type: btnVal.val,
                                        // })
                                    }}
                                    className={
                                        ' text-secondary-midnightBlue text-xs font-medium normal-case py-2 md:py-3 md:text-sm ' +
                                        (type === btnVal.val
                                            ? ' bg-secondary-midnightBlue font-semiboldFamily text-white'
                                            : ' bg-white')
                                    }
                                    variant='contained'
                                    size='large'
                                >
                                    {btnVal.text}
                                </Button>
                            )
                        })}
                    </div>
                    <div className='hidden sm:block'>
                        <Seemore
                            handleClick={() => {
                                type === 'shop' && navigate('/shops')
                                type === 'dine' && navigate('/restaurants')
                                type === 'stadium' && navigate('/stadiumBites')
                            }}
                        />
                    </div>
                </div>
            </div>
            <Swiper
                slidesPerView={1}
                modules={[Navigation]}
                onSwiper={(swiper) => {
                    setSwiper(swiper)
                }}
                onActiveIndexChange={(swiper) => {
                    setSlideIndex(swiper.realIndex)
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    500: {
                        slidesPerView: 2.3,
                        spaceBetween: 40,
                    },
                    768: {
                        slidesPerView: 3.4,
                        spaceBetween: 80,
                    },
                    1024: {
                        slidesPerView: 4.5,
                        spaceBetween: 80,
                    },
                }}
                className='mySwiper xl:pl-[70px]'
            >
                {renderList &&
                    renderList?.featured &&
                    renderList?.featured?.map((item, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <ExploreSwiperCard item={item} />
                            </SwiperSlide>
                        )
                    })}
            </Swiper>
            <div
                className={
                    'w-full flex justify-end items-center pr-[50px] pt-4 sm:pt-[60px] xl:pr-[150px] ' +
                    (renderList?.featured?.length <= 4 && ' lg:hidden ') +
                    (renderList?.featured?.length <= 3 && ' md:hidden ') +
                    (renderList?.featured?.length <= 2 && ' hidden ')
                }
            >
                <IconButtonChange
                    slideIndex={slideIndex}
                    num={isDesktop ? 4 : isPad ? 3 : 2}
                    length={renderList?.featured?.length}
                    goToNextSlide={() => {
                        swiper.slideNext()
                    }}
                    goToPrevSlide={() => {
                        swiper.slidePrev()
                    }}
                    buttonColor={'#231448'}
                    borderColor={'border-secondary-midnightBlue'}
                />
            </div>
            <div className='flex sm:hidden mt-6 justify-center'>
                <Button
                    type='button'
                    variant='outlined'
                    className='w-[270px] font-semibold text-[18px]'
                    size='large'
                    onClick={() => {
                        type === 'shop' && navigate('/shops')
                        type === 'dine' && navigate('/restaurants')
                        type === 'stadium' && navigate('/stadiumBites')
                    }}
                >
                    {t('landing.seeMore')}
                </Button>
            </div>
        </div>
    )
}

export default ExploreShopDine
