import * as React from 'react'
import { IconButton, CardMedia, Card, CardActions } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

import { CARD_BOX, RedLine, DescriptionLabel } from 'constants/constant'
import { cn } from 'utils/cn'
const BookmarkIcon = tw.div`text-secondary-midnightBlue text-xl px-[2px]`
function DetailSportCard({ card }) {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const isFull =
        card.enrlMethod && card.enrlMethod.includes('FCFS') && card.actionLink && card?.actionLink === 'disabled'
    const labelNow = isFull ? t('facilitiesBooking.full') : card.label
    return (
        <Card
            onClick={() => navigate('/facilitiesBookingDetail?id=' + card.vbsFacilityId)}
            className='relative w-full shadow-none cursor-pointer bg-transparent group rounded-none'
            square={true}
        >
            <div className={CARD_BOX + 'mb-2 w-full aspect-[4/3] xl:h-[194px]'}>
                {card.cardImage && (
                    <CardMedia
                        component='img'
                        className='object-cover w-full h-full group-hover:scale-110 transition-all duration-300'
                        image={card.cardImage}
                        alt='loading'
                    />
                )}
                {labelNow && (
                    <div
                        className={cn(
                            'absolute font-semiboldFamily text-white top-0 left-0 py-[6px] px-[15px] bg-primaryRed-ktRed text-xs md:text-sm',
                            isFull ? 'bg-secondary-grey' : 'bg-primaryRed-ktRed',
                        )}
                    >
                        {labelNow}
                    </div>
                )}
                <RedLine />
            </div>
            {card.facilitiesName && (
                <div className='flex justify-between'>
                    <div className='text-secondary-midnightBlue pt-2 text-h5 break-words'>{card.facilitiesName}</div>
                    {/* <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    console.log(3333)
                                }}
                                className={
                                    'p-0 self-start text-secondary-midnightBlue ' +
                                    (listType !== 'grid' && ' absolute top-0 right-0')
                                }
                            >
                                {card.bookMark ? (
                                    <img alt='' src={bookmarkHit} className='w-6 h-6 object-contain' />
                                ) : (
                                    <BookmarkIcon className='icon-bookmark' />
                                )}
                            </IconButton> */}
                </div>
            )}
        </Card>
    )
}

export default DetailSportCard
