import Content from 'components/Content'
import ViewMore from 'components/detailComponents/ViewMore'
import { useState, useRef, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
export default function AboutComponent({ description = '', isNeedCk = true }) {
    const [isExpand, setIsExpand] = useState(false)
    const [isMoreThan, setIsMoreThan] = useState(false)
    const descriptionRef = useRef(null)
    const displayDescriptionRef = useRef(null)
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const [imagesLoaded, setImagesLoaded] = useState(false)
    function getMaxHeight(dom, maxRows) {
        let currentHeight = 0
        let rowsCount = 0
        const skipTag = ['LI', 'TABLE', 'BR', 'STRONG']
        for (let child of dom.children) {
            let computedStyle = getComputedStyle(child)
            let childHeight = child.clientHeight
            let childMargin = parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom)
            let totalHeight = childHeight + childMargin
            let lineHeight = parseFloat(computedStyle.lineHeight)
            let childRows = Math.floor(childHeight / lineHeight)
            if (child.children.length >= 1 && !skipTag.includes(child.children[0].tagName)) {
                currentHeight += totalHeight
            } else {
                if (rowsCount + childRows >= maxRows) {
                    let remainingRows = maxRows - rowsCount
                    currentHeight += remainingRows * lineHeight
                    return currentHeight
                } else {
                    currentHeight += totalHeight
                    rowsCount += childRows
                }
            }
        }
        return currentHeight
    }
    const loadFun = useCallback(() => {
        if (displayDescriptionRef.current && descriptionRef.current) {
            descriptionRef.current.style.display = 'block'
            const maxHeight = getMaxHeight(displayDescriptionRef.current, 4)
            console.log(maxHeight)
            setIsMoreThan(maxHeight < descriptionRef.current.clientHeight)
            displayDescriptionRef.current.style.height = maxHeight + 'px'
            descriptionRef.current.style.display = 'none'
        }
    }, [displayDescriptionRef])

    useEffect(() => {
        if (!displayDescriptionRef.current) return
        const images = displayDescriptionRef.current.querySelectorAll('img')
        let loadCount = 0
        images.forEach((img) => {
            img.onload = () => {
                loadCount += 1
                if (loadCount === images.length) {
                    setImagesLoaded(true)
                }
            }
            img.onerror = () => {
                loadCount += 1
                if (loadCount === images.length) {
                    setImagesLoaded(true)
                }
            }
        })
    }, [displayDescriptionRef.current])

    useEffect(() => {
        if (imagesLoaded) loadFun()
        window.addEventListener('resize', loadFun)
        return () => {
            window.removeEventListener('resize', loadFun)
        }
    }, [imagesLoaded, loadFun])

    useEffect(() => {
        loadFun()
        window.addEventListener('resize', loadFun)
        return () => {
            window.removeEventListener('resize', loadFun)
        }
    }, [language, loadFun])

    return (
        <>
            <Content
                ref={descriptionRef}
                data={description}
                isNeedCk={isNeedCk}
                className={'text-base  font-barlow font-medium max-w-[750px]'}
            />
            <Content
                ref={displayDescriptionRef}
                data={description}
                isNeedCk={isNeedCk}
                className={
                    'text-base  font-barlow font-medium max-w-[750px]' + (isExpand ? ' h-auto ' : ' overflow-hidden')
                }
            />
            {isMoreThan && (
                <div className='pt-4 md:pt-6'>
                    <ViewMore
                        setIsExpand={setIsExpand}
                        isExpand={isExpand}
                        more={t('carPark.seeMore')}
                        less={t('carPark.showLess')}
                    />
                </div>
            )}
        </>
    )
}
