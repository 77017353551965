import { useEffect, useState } from 'react'
import { ScrollRestoration, useMatch } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

import { setDayjsLocale } from 'utils/util'
import { decryptAes } from 'utils/filter'
import { GetCookie, RemoveCookie } from 'utils/cookieLombok'
import { myProfile } from 'service/crmService'
import { user } from 'service/userService'
import { configServices } from 'service/configService'
import { configDataStore, useUserInfoAtom } from 'store/authAtom'
import { useSpacialTrafficNews, useFooterShow } from 'store/authAtom'
import SpacialTrafficNews from 'components/SpacialTrafficNews'
import { event } from 'event/event'
import Header from 'components/Header'
import Footer from 'components/Footer'
import { cn } from 'utils/cn'

export default function Error() {
    const {
        i18n: { language },
        i18n,
        t,
    } = useTranslation()
    const { setConfigDataValue } = configDataStore()
    const { isOpenSpacial } = useSpacialTrafficNews()
    const { isOpen } = useFooterShow()
    const [isBlur, setIsBlur] = useState(false)
    const navigate = useNavigate()
    const userSettingMatch = useMatch('/userSettings/*')
    const heritage = useMatch('/heritageMilestone')
    const landing = useMatch('/landing')
    const transportation = useMatch('/transportation')
    const carPark = useMatch('/carPark')
    const carParkDetail = useMatch('/carParkDetail')
    const queryClient = useQueryClient()
    const [refreshToken, setRefreshToken] = useState(GetCookie('ktspSsoRefreshToken'))
    const setProfileInfo = useUserInfoAtom((state) => state.setProfileInfo)

    const updateRefreshToken = () => {
        setRefreshToken(GetCookie('ktspSsoRefreshToken'))
    }
    const { data: profileData, isPending: profileIsPending } = useQuery({
        queryKey: ['getMyProfile'],
        queryFn: myProfile.getMyProfile,
        enabled: !!refreshToken,
    })
    const { data: configData } = useQuery({
        queryKey: ['getSystemConfig'],
        queryFn: configServices.getSystemConfig,
    })
    const logoutHandle = () => {
        RemoveCookie('ktspSsoAccessToken')
        RemoveCookie('session')
        RemoveCookie('sessionKey')
        RemoveCookie('ktspSsoRefreshToken')
        setProfileInfo()
        setIsBlur(false)
        window.location.pathname.startsWith('/userSettings') && navigate('/')
    }
    const LogoutApi = useMutation({
        mutationFn: user.logout,
        onSuccess: () => {
            console.log('logout success')
            logoutHandle()
        },
        onError: () => {
            console.log('logout failed')
        },
    })

    const logout = () => {
        LogoutApi.mutate(decryptAes(GetCookie('ktspSsoRefreshToken')))
    }
    useEffect(() => {
        event.on('logout', logout)
        event.on('logoutHandle', logoutHandle)
        return () => {
            event.off('logout', logout)
            event.off('logoutHandle', logoutHandle)
        }
    }, [])
    useEffect(() => {
        updateRefreshToken()
        if (GetCookie('ktspSsoRefreshToken') && !profileData) {
            queryClient.invalidateQueries(['getMyProfile'])
        }
    }, [GetCookie('ktspSsoRefreshToken')])
    useEffect(() => {
        if (GetCookie('lang')) {
            setDayjsLocale(GetCookie('lang'))
            i18n.changeLanguage(GetCookie('lang'))
            RemoveCookie('lang')
        }
    }, [GetCookie('lang')])
    useEffect(() => {
        if (configData) {
            setConfigDataValue(configData)
        } else {
            setConfigDataValue('')
        }
    }, [configData])
    console.log(language)

    useEffect(() => {
        setProfileInfo(profileData)
    }, [profileData])

    return (
        <div
            className={cn('flex flex-col text-body1', language === 'en_US' ? 'font-kt-en' : 'font-kt-zh')}
            id='main-container'
        >
            <header className='sticky top-0 z-50' id='main-header'>
                {isOpenSpacial && (landing || transportation || carPark || carParkDetail) && <SpacialTrafficNews />}
                <Header profileData={profileData ?? null} profileIsPending={profileIsPending} setIsBlur={setIsBlur} />
            </header>
            <div className='w-full py-[180px]'>
                <div className='mx-auto text-secondary-midnightBlue text-center w-[303px] md:w-[436px]'>
                    <div className='text-h3 pb-4'>{t('errorPage.title')}</div>
                    <div className='text-body1'>{t('errorPage.about')}</div>
                    <Button
                        onClick={() => navigate('/')}
                        className=' border-[1px] mt-8 text-lg leading-8 px-5 h-fit py-[7px] min-w-[240px] w-fit'
                        variant='secondary'
                    >
                        {t('errorPage.btn')}
                    </Button>
                </div>
            </div>
            <ScrollRestoration
            // getKey={(location, matches) => {
            //     return location.pathname
            // }}
            />
            {((!userSettingMatch && !heritage) || (heritage && isOpen)) && (
                <Footer profileData={profileData ?? null} profileIsPending={profileIsPending} />
            )}
        </div>
    )
}
