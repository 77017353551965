import React, { useState } from 'react'
import tw from 'twin.macro'

const Label = tw.div`py-5 flex gap-2 justify-between cursor-pointer items-baseline active:opacity-50`
function MothodLabel({ item }) {
    const [isExpand, setExpand] = useState(false)
    return (
        <div className='border-solid border-b-[1px] border-secondary-grey text-secondary-darkGery'>
            <Label onClick={() => setExpand(!isExpand)}>
                <div className='text-xl font-semiboldFamily whitespace-pre text-wrap [overflow-wrap:anywhere]'>
                    {item.question}
                </div>
                {!isExpand ? (
                    <div className='icon-plus text-[#283266]' />
                ) : (
                    <div className='icon-minus text-[#283266]' />
                )}
            </Label>
            {isExpand && (
                <div className='pb-5 text-lg font-regular text-[#0A173D] whitespace-pre text-wrap break-words'>
                    {item.answer}
                </div>
            )}
        </div>
    )
}
function ExpandConponent({ itemOut }) {
    const [isExpandOut, setExpandOut] = useState(false)
    return (
        <div>
            <div
                className='flex justify-between items-center gap-2 cursor-pointer py-5 border-solid border-b-[1px] border-secondary-grey'
                onClick={() => setExpandOut(!isExpandOut)}
            >
                <div className='font-semiboldFamily text-2xl'>{itemOut.category}</div>
                {!isExpandOut ? (
                    <div className='icon-arrow_down text-[#283266]' />
                ) : (
                    <div className='icon-arrow_up text-[#283266]' />
                )}
            </div>
            {isExpandOut &&
                itemOut.faqChilderDTOS.length > 0 &&
                itemOut.faqChilderDTOS.map((item, index) => {
                    return <MothodLabel key={index} item={item} />
                })}
        </div>
    )
}

export default ExpandConponent
