import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined'
import { useNavigate } from 'react-router-dom'
import { cn } from 'utils/cn'

const Label = ({ isMap, label }) => {
    return (
        <div className='flex w-full items-start gap-2 text-sm'>
            {isMap ? <FmdGoodOutlinedIcon /> : <MonetizationOnOutlinedIcon />}
            <div className='text-ellipsis whitespace-nowrap overflow-hidden'>{label}</div>
        </div>
    )
}

function ExploreSwiperCard({ item }) {
    const navigate = useNavigate()
    // 判斷設備是否支持hover
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0
    const [isTouch, setIsTouch] = useState(false)

    return (
        <div
            className='flex flex-col gap-[10px] text-secondary-midnightBlue font-medium w-[158px] sm:w-[210px] cursor-pointer'
            onClick={() => {
                item.type === 0 && navigate(`/shopsDetail?id=${item.identityUuid}`)
                item.type === 1 && navigate(`/restaurantsDetail?id=${item.identityUuid}`)
                item.type === 2 && navigate(`/stadiumBitesDetail?id=${item.identityUuid}`)
            }}
            onMouseOver={() => setIsTouch(true)}
            onMouseLeave={() => setIsTouch(false)}
            onTouchStart={() => setIsTouch(true)}
            onTouchEnd={() => setIsTouch(false)}
        >
            <div className='w-full h-[158px] sm:h-[210px] flex items-center justify-center'>
                <img
                    className={cn(
                        'object-fill rounded-full w-full h-full transition-all duration-300',
                        isTouchDevice ? 'active:scale-85' : 'hover:scale-85',
                    )}
                    alt=''
                    src={isTouch ? item.merchantLogo : item.cardImage}
                />
            </div>
            <div className='flex justify-between items-center'>
                <div className='line-clamp-2 [overflow-wrap:anywhere] text-xl font-semiboldFamily md:text-[28px] md:leading-[35px]'>
                    {item.name}
                </div>
                {/* <BookmarkBorderIcon className='text-xl' /> */}
            </div>
            {item.location && <Label isMap={true} label={item.location} />}
            {/*{item.acceptXDollar && <Label isMap={false} label={item.acceptXDollar} />}*/}
        </div>
    )
}

export default ExploreSwiperCard
