import React from 'react'
import { Button, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { cn } from 'utils/cn'
import { dateFormat3 } from 'utils/util'
import dayjs from 'dayjs'
function NumSpace({ items }) {
    const { num, time, isCarParkOn, carParkName } = items
    const currentTime = dayjs()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const isPC = useMediaQuery('(min-width: 1024px)')
    if (!num && !time) {
        return null
    }
    return (
        <div
            className={cn(
                'sticky bottom-0 left-0 right-0 text-secondary-midnightBlue bg-white z-40 pt-4 flex-1 lg:static lg:pt-[96px]',
                isPC ? '' : 'shadow-card',
                isCarParkOn ? '' : 'px-5 pb-5 md:px-8 lg:px-0',
            )}
        >
            {isCarParkOn ? (
                <div className=' px-5 md:px-[60px] lg:gap-6 lg:min-h-[317px] lg:min-w-[335px] lg:flex-col lg:border-[#0A173D] lg:pl-8 lg:pr-0 lg:border-solid lg:border-l-[1px] '>
                    {num && (
                        <div>
                            <span className='text-h3'>{num}</span>
                            <span className='text-h4'>{' ' + t('carPark.spaces')}</span>
                        </div>
                    )}
                    {
                        <div className='font-regular break-words text-xs pb-4'>
                            {t('carPark.lastUpdatedOn')}
                            {dateFormat3(language, time ?? currentTime)}
                        </div>
                    }
                </div>
            ) : (
                <div className='px-5 text-secondary-midnightBlue text-xs leading-[18px] bg-[#E9ECF3] w-full py-4 lg:ml-[26px] lg:min-w-[332px]'>
                    {carParkName + t('carPark.offTipDetail')}
                </div>
            )}
        </div>
    )
}

export default NumSpace
