import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useModalStore } from 'store/authAtom'
import nofound from 'resources/images/nofound.png'

export default function useNotAvailableAutoLeave(errorCode, code) {
    const { t } = useTranslation()
    const { setModalComponent, onOpen, onClose, setCloseFn } = useModalStore()
    const navigate = useNavigate()
    useEffect(() => {
        if (errorCode === code) {
            setModalComponent(
                <div className='flex flex-col items-center justify-center h-[25vh]'>
                    <img src={nofound} alt='notFound' className='w-[120px] h-[120px]' />
                    <div className='text-h6 pb-4 break-words'>{t('shop.itemNoAvailable')}</div>
                </div>,
            )
            onOpen()
            setCloseFn(() => navigate(-1))
        }
        return () => {
            onClose()
            setCloseFn(null)
        }
    }, [errorCode])
}
