import React, { useEffect, useRef, useState, useMemo, useLayoutEffect } from 'react'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import GridOnOutlinedIcon from '@mui/icons-material/GridOnOutlined'
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined'
import dayjs from 'dayjs'
import { useMutation, useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import TitleSearchComponent from 'components/shopDine/allProductsComponents/TitleSearchComponent'
import PaginationButton from 'components/PaginationButton'
import Loading from 'components/Loading'
import { filterName, newIdArray, formatStartDateEndDate, datesSelect } from 'utils/filter'
import { promotion } from 'service/promotionService'
import PromotionCard from 'page/Promotions/components/PromotionCard'
import FilterModal from 'components/shopDine/allProductsComponents/FilterModal'
import Tag from 'components/shopDine/allProductsComponents/Tag'
import TypeMenu from 'components/shopDine/allProductsComponents/TypeMenu'
import TypeDate from 'components/TypeDate'
import { useDisclosure } from 'utils/useDisclosure'
import { useMultiTranslation } from 'utils/i18nHook'
import { useSearchParams, useLocation } from 'react-router-dom'

const getPromotionType = (promotionType, promotionTypeList) => {
    const selected = promotionTypeList.filter((item) => promotionType.includes(item?.id))
    return selected.length > 0 ? selected : []
}

function AllPromotion({ name, isFilter, firstTypeName, secondTypeName, promotionTypeName }) {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const { en_US, zh_CN, zh_HK } = useMultiTranslation();
    const location = useLocation();
    const scrollY = useRef(0);
    console.log(en_US)
    
    const promotionTypeList = useMemo(() => {
        return [
            {
                id: 2,
                nameEn: en_US?.promotion?.precinct,
                nameCn: zh_CN.promotion?.precinct,
                nameZh: zh_HK.promotion?.precinct,
            },
            {
                id: 1,
                nameEn: en_US.promotion?.promotions,
                nameCn: zh_CN.promotion?.promotions,
                nameZh: zh_HK.promotion?.promotions,
            },
        ]
    }, [t, language])
    console.log('promotionTypeList')

    const promotionType = useMemo(() => {
        switch (searchParams.get('promotionType')) {
            case 'KaiTaiMallPromotion':
                return [1]
            case 'PrecinctPromotion':
                return [2]
            case 'KaiTaiMallPromotionAndPrecinctPromotion':
                return [1,2]
            default:
                return []
        }
    }, [searchParams])

    const [typesSelected, setTypesSelected] = useState([])
    const [venuesSelected, setVenuesSelected] = useState([])
    const [promotionTypeSelected, setPromotionTypeSelected] = useState(getPromotionType(promotionType, promotionTypeList))
    const { isOpen, onClose, onOpen } = useDisclosure()
    const [isProductsList, setIsProductsList] = useState(false)
    const [searchVal, setSearchVal] = useState('')
    const [isSearch, setIsSearch] = useState(true)
    const selectedCategories = useMemo(() => {
        return [...promotionTypeSelected, ...typesSelected, ...venuesSelected]
    }, [typesSelected, venuesSelected, promotionTypeSelected])
    const dates = datesSelect()
    const [dateType, setDateType] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const formatStartDate = startDate
        ? dayjs(startDate).format('YYYY-MM-DD')
        : endDate
        ? dayjs(endDate).format('YYYY-MM-DD')
        : null
    const formatEndDate = endDate
        ? dayjs(endDate).format('YYYY-MM-DD')
        : startDate
        ? dayjs(startDate).format('YYYY-MM-DD')
        : null
    const formatDate = useMemo(() => {
        return formatStartDateEndDate(startDate ?? endDate, endDate ?? startDate, language)
    }, [startDate, endDate, language])

    const filterData = useMemo(() => {
        let filterConfig = {
            endDate: formatEndDate ? formatEndDate + ' 23:59:59' : '',
            startDate: formatStartDate ? formatStartDate + ' 00:00:00' : '',
            lang: language,
            range: dateType && dateType?.val !== 'flexibleDate' ? dateType.val : '',
            searchKey: searchVal.trim(),
            filterId: [...newIdArray(typesSelected), ...newIdArray(venuesSelected)],
            maxRecord: 9,
        }
        if (promotionType) {
            filterConfig = {
                ...filterConfig,
                promotionTypes: promotionType,
            }
        }

        return filterConfig
    }, [formatEndDate, formatStartDate, language, dateType, searchVal, typesSelected, venuesSelected, promotionType])

    const { data, fetchNextPage, refetch, isFetching, isPending } = useInfiniteQuery({
        queryKey: ['getPromotionList', startDate, endDate, dateType, promotionTypeSelected, typesSelected, venuesSelected, language], // key
        queryFn: ({ pageParam = 0 }) => promotion.list({ ...filterData, startRow: pageParam }),
        getNextPageParam: (lastPage, pages) => {
            return pages.length * 9
        },
    })
    const { data: types } = useQuery({
        queryKey: ['lookingForFilter'],
        queryFn: () => promotion.filter({ lang: language, type: 'Looking For' }),
    })
    const { data: venues } = useQuery({
        queryKey: ['relatedToFilter'],
        queryFn: () => promotion.filter({ lang: language, type: 'Related To' }),
    })

    const hasFilter = useMemo(() => {
        return promotionTypeSelected.length > 0 || typesSelected.length > 0 || venuesSelected.length > 0 || dateType || startDate || endDate
    }, [promotionTypeSelected, typesSelected, venuesSelected, dateType, startDate, endDate])

    useEffect(() => {
        refetch()
    }, [])

    const handleSetPromotionType = (val) => {
        if (promotionTypeSelected === val) return
        setPromotionTypeSelected(val)
        // 记录当前滚动位置
        scrollY.current = window.scrollY;
        if (val?.length === 2) {
            setSearchParams({ promotionType: 'KaiTaiMallPromotionAndPrecinctPromotion' })
        } else if (val?.length === 1) {
            setSearchParams({ promotionType: val[0].id === 1 ? 'KaiTaiMallPromotion' : 'PrecinctPromotion' })
        } else if (val?.length === 0) {
            setSearchParams({})
        }
    }
    
    useEffect(() => {
        window.scrollTo(0, scrollY.current);
        setPromotionTypeSelected(getPromotionType(promotionType, promotionTypeList));
    }, [location.search]);
    
    return (
        <div className='bg-white bg-container pt-12 md:pt-16'>
            <FilterModal
                promotionTypeName={promotionTypeName}
                firstTypeName={firstTypeName}
                secondTypeName={secondTypeName}
                venues={venues ?? []}
                types={types ?? []}
                promotionTypes={promotionTypeList ?? []}
                typesSelected={typesSelected}
                setTypesSelected={setTypesSelected}
                setPromotionTypeSelected={handleSetPromotionType}
                promotionTypeSelected={promotionTypeSelected}
                venuesSelected={venuesSelected}
                setVenuesSelected={setVenuesSelected}
                open={isOpen}
                handleClose={onClose}
                dateType={dateType}
                setDateType={setDateType}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                dates={dates}
            />
            <TitleSearchComponent
                name={name}
                searchVal={searchVal}
                setIsSearch={setIsSearch}
                setSearchVal={setSearchVal}
                onBlurFun={() => {
                    !isFetching && refetch()
                }}
            />
            {/* <div className='flex justify-between items-center gap-2'> */}
            <div className='pt-6 flex flex-wrap items-center gap-[10px]'>
                <div className='hidden md:flex md:flex-wrap md:gap-[10px] md:items-center'>
                    <TypeMenu
                        name={promotionTypeName}
                        num={promotionTypeSelected.length}
                        labels={promotionTypeList}
                        typesSelected={promotionTypeSelected}
                        setTypesSelected={handleSetPromotionType}
                    />
                    <TypeMenu
                        name={firstTypeName}
                        num={typesSelected.length}
                        labels={types ?? []}
                        typesSelected={typesSelected}
                        setTypesSelected={setTypesSelected}
                    />
                    <TypeMenu
                        name={secondTypeName}
                        num={venuesSelected.length}
                        labels={venues ?? []}
                        typesSelected={venuesSelected}
                        setTypesSelected={setVenuesSelected}
                    />
                    <TypeDate
                        dateType={dateType}
                        setDateType={setDateType}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        dates={dates}
                    />
                </div>
                {isFilter && (
                    <div
                        onClick={() => onOpen()}
                        className='md:border-l-[1px] md:pl-[10px] md:border-secondary-midnightBlue md:border-solid'
                    >
                        <div className='w-[160px] py-[6px] px-[10px] pl-4 flex justify-between items-center cursor-pointer rounded-[20px] text-secondary-midnightBlue border-secondary-midnightBlue border-solid border-[1px]'>
                            <div className='text-base font-medium'>{t('eventPage.allFilter')}</div>
                            <div className='flex items-center'>
                                <div className='icon-filter font-bold' />
                            </div>
                        </div>
                    </div>
                )}
                {hasFilter && (
                    <div
                        onClick={() => {
                            setTypesSelected([])
                            setVenuesSelected([])
                            handleSetPromotionType([])
                            dateType && setDateType(null)
                            startDate && setStartDate(null)
                            endDate && setEndDate(null)
                        }}
                        className='text-base font-semiboldFamily text-[#0A173D] cursor-pointer underline'
                    >
                        {t('eventPage.reset')}
                    </div>
                )}
            </div>
            {/* <div className='text-lg text-secondary-grey flex items-center gap-2 cursor-pointer'>
                    <div
                        onClick={() => setIsProductsList(false)}
                        className={!isProductsList ? 'text-secondary-midnightBlue' : ''}
                    >
                        <GridOnOutlinedIcon />
                    </div>
                    <div
                        onClick={() => setIsProductsList(true)}
                        className={isProductsList ? 'text-secondary-midnightBlue' : ''}
                    >
                        <FormatListBulletedIcon />
                    </div>
                </div> */}
            {/* </div> */}
            {hasFilter && (
                <>
                    <div className='mt-6 flex gap-2 flex-wrap'>
                        {selectedCategories.map((item, index) => {
                            return (
                                <Tag
                                    key={index}
                                    text={filterName(item, language)}
                                    fun={() => {
                                        if (
                                            types.some(
                                                (itemType) =>
                                                    itemType.id === item.id &&
                                                    itemType.nameEn === item.nameEn &&
                                                    itemType.nameZh === item.nameZh &&
                                                    itemType.nameCn === item.nameCn,
                                            )
                                        ) {
                                            const arr = typesSelected.filter((val) => val !== item)
                                            setTypesSelected([...arr])
                                        } else if (
                                            promotionTypeList.some(
                                                (itemType) =>
                                                    itemType.id === item.id &&
                                                    itemType.nameEn === item.nameEn &&
                                                    itemType.nameZh === item.nameZh &&
                                                    itemType.nameCn === item.nameCn,
                                            )
                                        ) {
                                            const arr = promotionTypeSelected.filter((val) => val !== item)
                                            handleSetPromotionType([...arr])
                                        } else {
                                            const arr = venuesSelected.filter((val) => val !== item)
                                            setVenuesSelected([...arr])
                                        }
                                    }}
                                />
                            )
                        })}
                        {dateType && dateType.val !== 'flexibleDate' && (
                            <Tag text={t(dateType.about)} fun={() => setDateType('')} />
                        )}
                        {formatDate && (
                            <Tag
                                text={formatDate}
                                fun={() => {
                                    setStartDate(null)
                                    setEndDate(null)
                                    setDateType('')
                                }}
                            />
                        )}
                    </div>
                    <div className='text-h4 text-secondary-midnightBlue pt-6'>
                        {isSearch ? data?.pages[0]?.allDataSize ?? 0 : 0}&nbsp;
                        {t('eventPage.resultsFound')}
                    </div>
                </>
            )}
            {isPending && <Loading />}
            <div
                className={
                    'mt-8 lg:mt-[60px] ' +
                    (isProductsList
                        ? ''
                        : 'grid gap-x-6 gap-y-8 grid-cols-2 md:gap-x-[20px] lg:grid-cols-3 lg:gap-y-16')
                }
            >
                {isSearch &&
                    data &&
                    data?.pages.map((page) => {
                        return page.data.map((card, index) => {
                            return (
                                <PromotionCard index={index} key={index} isProductsList={isProductsList} card={card} />
                            )
                        })
                    })}
            </div>
            {isSearch && (
                <PaginationButton
                    isDisabled={
                        data?.pages.reduce((acc, page) => acc + page.data.length, 0) === data?.pages[0]?.allDataSize
                    }
                    fun={() => {
                        fetchNextPage()
                    }}
                    nowCardsLength={data?.pages?.reduce((acc, page) => acc + page.data.length, 0) ?? 0}
                    allCardsLength={data?.pages?.[0]?.allDataSize ?? 0}
                />
            )}
        </div>
    )
}

export default AllPromotion
