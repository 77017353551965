import React, { useState, useMemo, useEffect } from 'react'
import { useMediaQuery } from '@mui/material'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useMeasure } from 'react-use'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { configDataStore } from 'store/authAtom'
import IconButtonChange from 'components/swiperComponent/IconButtonChange'
import tw from 'twin.macro'
import { cn } from 'utils/cn'
import Steps from './swiperComponent/Steps'
import FooterSteps from './swiperComponent/FooterSteps'

const Title = tw.div`text-secondary-midnightBlue text-base lg:hidden`
const Wrap = tw.div`overflow-x-hidden rounded-[20px] bg-white p-3 md:px-10 md:py-6 lg:px-10 lg:py-6 px-5 py-[24px] mx-auto`
function BrandSwiper({ menu }) {
    const { configDataValue } = configDataStore()
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const navigate = useNavigate()
    const [ref, { width }] = useMeasure()
    const [swiper, setSwiper] = useState(null)
    const [slideIndex, setSlideIndex] = useState(0)
    const ispad = useMediaQuery('(min-width:768px)')
    const isXl = useMediaQuery('(min-width:1440px)')
    const num = isXl ? 8 : ispad ? 6 : 4

    const goToNextSlide = () => {
        setSlideIndex(Math.min(slideIndex + 1, 2))
    }

    const goToPrevSlide = () => {
        setSlideIndex(Math.max(slideIndex - 1, 0))
    }

    const foundingPartner = useMemo(() => menu.foundingPartner.foundingPartnerList, [menu.foundingPartner])
    const officialPartner = useMemo(() => menu.officialPartner.foundingPartnerList, [menu.officialPartner])
    const officialSupplier = useMemo(() => menu.officialSupplier.foundingPartnerList, [menu.officialSupplier])

    const partnerList = useMemo(() => {
        return [foundingPartner, officialPartner, officialSupplier].filter((item) => item.length > 0)
    }, [foundingPartner, officialPartner, officialSupplier])

    const titleHelper = ({index}) => {
        if (partnerList[index] === foundingPartner) {
            return t('landing.foundingPanter')
        } else if (partnerList[index] === officialPartner) {
            return t('landing.officialPartner')
        } else if (partnerList[index] === officialSupplier) {
            return t('landing.officialSupplier')
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setSlideIndex((prevIndex) => (prevIndex + 1) % partnerList.length);
        }, 5000);
        return () => clearInterval(interval);
    }, [partnerList]);


    if (configDataValue && !configDataValue?.ourPartnersSwitch) {
        return null
    }

    return (
        <div className='bg-[#F5F5F5]'>
            <Wrap>
                <div className='text-[20px] text-h1 text-secondary-midnightBlue pb-[12px]'>
                    {t('landing.ktspTitle')}
                </div>
                <div className='hidden lg:flex lg:justify-between lg:items-center lg:pb-[8px] md:pb-[12px]'>
                    <div className='flex text-body2 text-secondary-midnightBlue'>
                        <div className='text-[16px] xl:min-w-[216px] min-w-[206px]'>
                            {t('landing.premierFoundingPartner')}
                        </div>
                        {partnerList?.length > 0 && (
                            <div
                                className='xl:pl-[40px] lg:pl-[16px]'
                                style={{
                                    display: !!partnerList?.length ? '' : 'none',
                                }}
                            >
                            {titleHelper({index: slideIndex})}
                            </div>
                        )}
                    </div>
                </div>
                <div className='xl:gap-[40px] gap-[24px] lg:flex lg:items-center'>
                    <Title className='font-semiboldFamily mb-[8px] text-[16px]'>{t('landing.premierFoundingPartner')}</Title>
                    <div
                        className={cn(
                            'border-[#B1B1B1] lg:border-r-[1px] flex-shrink-0',
                            'xl:pr-[39px] pr-[19px]',
                            !!partnerList?.length ? 'border-solid' : 'border-none',
                        )}
                    >
                        {menu.premierFoundingPartner && (
                            <img
                                onClick={() => {
                                    menu.premierFoundingPartner.isExternal
                                        ? (window.location.href = menu.premierFoundingPartner.link)
                                        : window.open(menu.premierFoundingPartner.link)
                                }}
                                ref={ref}
                                alt='load'
                                className='cursor-pointer w-[176px] h-[176px] object-contain'
                                src={menu.premierFoundingPartner.iconUrl}
                            />
                        )}
                    </div>
                    <div
                        className={cn(
                            'flex justify-between items-center mb-[8px] xs:mb-[12px] mt-[20px] lg:hidden ',
                            !!partnerList?.length ? '' : 'hidden',
                        )}
                    >
                        {partnerList?.length > 0 && (
                            <Title className='font-semiboldFamily'>
                                {titleHelper({index: slideIndex})}
                            </Title>
                        )}
                    </div>
                    <div className='flex-1 lg:pt-[45px] '>
                        {partnerList?.length > 0 && (
                            <div>
                                <div
                                    className={
                                        cn(
                                            'flex xl:flex-nowrap lg:flex-nowrap flex-wrap gap-[20px] xl:gap-[60px]',
                                            partnerList.at(slideIndex)?.length === 6 && 'justify-between ms:partner-box'
                                        )
                                    }
                                >
                                    {partnerList.at(slideIndex)?.map((img, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className={'lg:h-[84px] lg:w-[84px] xs:w-[84px] xs:h-[84px] w-[84px] h-[84px]'}
                                            >
                                                {
                                                    <img
                                                        onClick={() => {
                                                            img.isExternal
                                                                ? (window.location.href = img.link)
                                                                : window.open(img.link)
                                                        }}
                                                        alt='load'
                                                        src={img.iconUrl}
                                                        className='cursor-pointer object-contain w-full h-full'
                                                    />
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='flex justify-between items-end pt-[12px]'>
                                    <FooterSteps
                                        swiperLength={partnerList}
                                        slideIndex={slideIndex}
                                        stepTextColor={'text-secondary-midnightBlue'}
                                        stepActiveColor={'bg-secondary-midnightBlue'}
                                        // stepTextPadding={'pb-0'}
                                        // stepTextSize={'text-12'}
                                    />
                                    <IconButtonChange
                                        goToNextSlide={goToNextSlide}
                                        goToPrevSlide={goToPrevSlide}
                                        buttonColor={'#231448'}
                                        borderColor={'border-secondary-midnightBlue'}
                                        slideIndex={slideIndex}
                                        length={partnerList.length}
                                        num={1}
                                        // width='md:h-[42px] md:w-[42px] '
                                        width='h-[32px] w-[32px] '
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Wrap>
        </div>
    )
}

export default BrandSwiper
