import React, { useRef } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import BannerSwiper from 'components/detailComponents/BannerSwiper'
import PromotionCards from 'page/EventsTickets/components/PromotionCards'
import RecommendedCards from 'page/EventsTickets/components/RecommendedCards'
import { TabContainer } from 'components/TabContainer'
import RecommendProducts from 'page/ShopDine/components/RecommendProducts'
import TopIntro from '../components/TopIntro'
import { restaurant, shopDineService } from 'service/restaurantService'
import About from '../components/About'
import RecommendedRestaurantCards from '../components/RecommendedRestaurantCards'
import Loading from 'components/Loading'
import useNotAvailableAutoLeave from 'utils/useNotAvailableAutoLeave'
import { notAvailableErrorCode } from 'utils/filter'

export default function Detail() {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const promotionRef = useRef(null)
    const youMayLikeRef = useRef(null)
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id') + ''
    const { data: items, isPending } = useQuery({
        queryKey: ['getChampionBarDetail', language, id],
        queryFn: () =>
            shopDineService.getShopDineDetail({
                lang: language,
                alsoLikeSize: 4,
                identityUuid: id,
                promoSize: 5,
                type: 'championBar',
            }),
    })
    useNotAvailableAutoLeave(items?.errorCode,notAvailableErrorCode.dine)
    if (isPending) return <Loading />
    if (items?.result === null) return null
    return (
        <div>
            {items && items?.bannerImageList?.length > 0 && (
                <BannerSwiper
                    items={items.bannerImageList.map((i) => ({ ...i, image: i?.content, altText: i?.description }))}
                />
            )}
            {items && (
                <div>
                    <div className='bg-container'>
                        <div className='text-secondary-midnightBlue'>
                            <TopIntro items={items} />
                        </div>
                    </div>
                    <section>
                        <TabContainer
                            components={[
                                {
                                    key: 'about',
                                    title: t('eventPage.about'),
                                    component: () => <About items={items} />,
                                },
                                items?.recommended &&
                                    items?.recommended.length > 0 && {
                                        key: 'featureDish',
                                        title: t('stadium.featureDish'),
                                        component: () => (
                                            <RecommendProducts
                                                items={items.recommended}
                                                title={t('stadium.featureDish')}
                                            />
                                        ),
                                    },
                                items?.promo &&
                                    items?.promo?.length > 0 && {
                                        key: 'promotion',
                                        title: t('shop.promotion'),
                                        component: () => (
                                            <PromotionCards items={items.promo} title={t('shop.promotion')} />
                                        ),
                                    },
                                items?.alsoLike?.length > 0 && {
                                    key: 'recommend',
                                    title: t('shop.YouMayAlsoLike'),
                                    component: () => (
                                        <div className='text-secondary-midnightBlue'>
                                            <RecommendedRestaurantCards items={items.alsoLike} />
                                        </div>
                                    ),
                                },
                            ].filter((i) => i)}
                        />
                    </section>
                </div>
            )}
        </div>
    )
}
