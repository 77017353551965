import { useMutation, useQuery } from '@tanstack/react-query'
import BannerSwiper from 'components/detailComponents/BannerSwiper'
import Loading from 'components/Loading'
import Banner from 'components/shopDine/Banner'
import { TabContainer } from 'components/TabContainer'
import React, { useEffect } from 'react'
import tw, { styled } from 'twin.macro'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import bannerTop from 'resources/images/friends1.jpg'
import friends2 from 'resources/images/friends2.png'
import friends3 from 'resources/images/friends3.png'
import friends4 from 'resources/images/friends4.png'
import friends_icon1 from 'resources/images/friends_icon1.png'
import friends_icon2 from 'resources/images/friends_icon2.png'
import { information } from 'service/informationService'
import { shopDineService } from 'service/restaurantService'
import { useModalStore } from 'store/authAtom'
import { Box, DialogContent, DialogTitle } from '@mui/material'
import Content from 'components/Content'
import 'page/Friends/index.css'
const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
    borderTop: '1px solid ' + theme.palette.divider, // 上边的分割线
}))

export default function Friends() {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const { setModalComponent, onOpen, setColorCloseIcon } = useModalStore()

    const banner = {
        image: bannerTop,
        title: t('friends.title'),
        about: t('friends.bannerAbout'),
    }
    const { data: infoData, isFetching } = useQuery({
        queryKey: ['getAdditionalInformation', language],
        queryFn: () => information.getAdditionalInformation({ lang: language, type: [5] }),
        placeholderData: [],
        enabled: !!language,
    })
    const TermsOfCondition = () => {
        return (
            <div className='flex flex-col'>
                <div className='text-h6 text-secondary-midnightBlue break-words'>{t('friends.tncTitle')}</div>
                <div className='flex-1'>
                    <div className='text-body4 text-secondary-darkGery break-words mt-8 max-h-[50vh] overflow-y-auto thinScrollBar'>
                        <Content className='pt-4' data={infoData?.[0]?.content || ''}></Content>
                    </div>
                </div>
            </div>
        )
    }

    if (isFetching) return <Loading />
    //         "parkRulesDesc":"Kai Tak Sports Park (“KTSP”) welcomes you all. It is our sincere wish that all guests will have a fantastic time at the park. You can help us make this happen by observing the following Park Rules during your visit.\n
// 1.Please follow the instructions provided by our staff members, participate in any security screening that may be required from time to time, and adhere to any posted or announced rules.
// \n2.KTSP (or the “Precinct”) is governed by the laws of Hong Kong, be a law-abiding visitor.
// \n3.For the safe and peaceful enjoyment of all guests, be 10. considerate and safety conscious.
// \n4.To maintain a harmonious environment, no unauthorised commercial, busking, and political activities.
// \n5.For the comfort of all guests, please smoke only in designated areas.
// \n6.KTSP adopts a pet-friendly policy. You are welcome to walk or stroll your pets in areas specially designated for them.
// \n7.Please help us maintain a clean and inviting environment by respecting our property and using our facilities with care. We may remove or dispose of any waster matter, rubbish, refuse or 13. unwanted articles in any part of the Precinct in such manner and at such time as we see fit without notice.
// \n8.Safety is our utmost priority. Please seek our prior approval for the use of motorized vehicles, drones, remote control items, tents, kites, inflatable items, or any other devices/structures that may cause potential harm or damage to other guests, staff members or the Precinct.
// \n9.While we strive to provide a secure environment, please take proper care of your own belongings. We will not be held liable for any loss, damage, or theft of personal property. If you come into possession of any property in any part of the Precinct which appears to have been abandoned, lost or misplaced, you must, as soon as practicable, hand over the property to our staff.
// \n10.You are most welcome to capture your delightful memories at the Precinct for your own use, but please remember to obtain prior consent from us before engaging in any commercial filming, recording, or photography.
// \n11.KTSP is committed to inclusivity. If you have a disability or any special need, please let us know. We will gladly accommodate to ensure your visit is enjoyable and hassle-free.
// \n12.Be considerate when driving/cycling into the Precinct. Please follow specified traffic flow/signage, avoid obstructing driveways/pathways, and use designated parking spaces/cycling areas only.
// \n13.These Park Rules should be read in conjunction with any additional Conditions of Entry or Rules and Regulations.
// \n14.In the event of any discrepancy between the English and Chinese versions of these rules, the English version shall prevail.
// \n\nOur staff stand ready to provide advice and assistance to facilitate your enjoyment and safe visit, and we hope that everyone will have a fantastic time. In the unlikely event that these Park Rules are not complied with, we may take necessary action, including removal from the Precinct and/or taking legal action.",


    return (
        <div>
            <Banner banner={banner} />
            <section className='mt-12'>
                <TabContainer
                    fullWidth={true}
                    components={[
                        {
                            key: 'Overview',
                            title: t('friends.overview'),
                            component: () => (
                                <div className='bg-white bg-container whitespace-pre text-wrap text-secondary-midnightBlue'>
                                    <div className='text-h2 mb-6 mt-10'>{t('friends.overview')}</div>
                                    <div className='mb-[60px] text-body1'>
                                        <div>{t('friends.desc')}</div>
                                        <br />
                                        <div>{t('friends.sub1')}</div>
                                        <br />
                                        <div>{t('friends.sub2')}</div>
                                        <br />
                                        <Trans
                                            i18nKey='friends.desc2'
                                            components={[
                                                <span
                                                    className='text-primaryRed-ktRed cursor-pointer'
                                                    onClick={() => {
                                                        setModalComponent(<TermsOfCondition />)
                                                        setColorCloseIcon('white')
                                                        onOpen()
                                                    }}
                                                />,
                                            ]}
                                        />
                                    </div>
                                    <div className='h-[1px] w-full bg-[#b1b1b1] mb-10' />
                                </div>
                            ),
                        },
                        {
                            key: 'points',
                            title: t('friends.membersExclusiveBenefits'),
                            component: () => (
                                <div className='bg-white bg-container  text-secondary-midnightBlue'>
                                    <div className='text-h2 mb-6'>{t('friends.membersExclusiveBenefits')}</div>
                                    {/* <img src={friends4} alt='loading' className='mb-6' /> */}
                                    <div className='text-body1 mb-10 lg:mb-[60px]'>
                                        <div className='mb-6'>{t('friends.desc3')}</div>
                                    </div>

                                    {/* <div className='flex lg:flex-row flex-col mb-20 gap-8 text-secondary-midnightBlue'>
                                        <div className='flex items-center gap-x-6'>
                                            <img src={friends_icon1} alt='' className='w-20 sm:w-[120px]' />
                                            <div>
                                                <div className='text-h4 mb-3'>Redeem as Cash</div>
                                                <div className='text-body1'>
                                                    Earned KTSP Points can be used for future purchases
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex items-center gap-x-6'>
                                            <img src={friends_icon2} alt='' className='w-20 sm:w-[120px]' />
                                            <div>
                                                <div className='text-h4 mb-3'>Redeem a Reward</div>
                                                <div className='text-body1'>
                                                    Redeem your earned points for exclusive rewards, discounts or
                                                    experiences.
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            ),
                        },
                    ].filter((i) => i)}
                />
            </section>
        </div>
    )
}
