import React from 'react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'

import CarParkCard from 'page/CarPark/components/CarParkCard'

function CarParkSwiper({ items = [], setSwiper, setSlideIndex }) {
    return (
        <Swiper
            spaceBetween={26}
            modules={[Navigation]}
            onActiveIndexChange={(swiper) => {
                setSlideIndex(swiper.realIndex)
            }}
            onSwiper={(swiper) => {
                setSwiper(swiper)
            }}
            breakpoints={{
                0: { slidesPerView: 2 },
                1024: {
                    slidesPerView: 3,
                },
                1440: {
                    slidesPerView: 3,
                },
            }}
            className='mySwiper'
        >
            {items.map((card, index) => {
                return (
                    <SwiperSlide key={index}>
                        <CarParkCard card={card} />
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}

export default CarParkSwiper
