import React, { useRef } from 'react'
import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { programmesService } from 'service/vbsService'
import BannerSwiper from 'components/detailComponents/BannerSwiper'
import TopIntro from 'page/Programmes/componets/TopIntro'
import About from 'page/Programmes/componets/About'
import PromotionCards from 'page/EventsTickets/components/PromotionCards'
import UMayAlsoLikeProgramme from 'page/Programmes/componets/UMayAlsoLikeProgramme'
import { TabContainer } from 'components/TabContainer'
import Loading from 'components/Loading'
import useNotAvailableAutoLeave from 'utils/useNotAvailableAutoLeave'
import { notAvailableErrorCode } from 'utils/filter'

export default function Detail() {
    const {
        i18n: { language },
        t,
    } = useTranslation()
    const promotionRef = useRef(null)
    const [searchParams] = useSearchParams()
    const id = searchParams.get('id') + ''

    const { data: items, isPending } = useQuery({
        queryKey: ['getProgrammeDetail', language, id],
        queryFn: () =>
            programmesService.getProgrammeDetail({
                lang: language,
                programId: id,
                alsoLikeSize: 4,
                promoSize: 5,
                bannerSize: 5,
            }),
        placeholderData: keepPreviousData,
    })
    const { data: btnDatas } = useQuery({
        queryKey: ['getProgrammesStatus', language, id],
        queryFn: () => programmesService.getActivitiesStatus({ ids: id, lang: language }),
        placeholderData: keepPreviousData,
    })
    useNotAvailableAutoLeave(items?.errorCode, notAvailableErrorCode.programme)
    if (isPending) {
        return <Loading />
    }
    if (items?.result === null) return null
    return (
        <div>
            {items && items?.banner?.length > 0 && <BannerSwiper items={items.banner} />}
            {items && (
                <div>
                    <div className='bg-container'>
                        <div className='text-secondary-midnightBlue'>
                            <TopIntro
                                btnDatas={
                                    btnDatas && btnDatas.list && btnDatas.list.length > 0 ? btnDatas.list[0] : null
                                }
                                items={items}
                            />
                        </div>
                    </div>
                    <section>
                        <TabContainer
                            components={[
                                (items.description || items.tags.length > 0) && {
                                    key: 'about',
                                    title: t('eventPage.about'),
                                    component: () => <About items={items} />,
                                },
                                items?.promo &&
                                    items?.promo?.length > 0 && {
                                        key: 'promotion',
                                        title: t('visitList.promotions'),
                                        component: () => (
                                            <PromotionCards promotionRef={promotionRef} items={items.promo} />
                                        ),
                                    },
                                items?.alsoLike?.length > 0 && {
                                    key: 'recommend',
                                    title: t('eventPage.uMayAlsoLike'),
                                    component: () => (
                                        <div className='text-secondary-midnightBlue'>
                                            <UMayAlsoLikeProgramme items={items.alsoLike} />
                                        </div>
                                    ),
                                },
                            ].filter((i) => i)}
                        />
                    </section>
                </div>
            )}
        </div>
    )
}
