import React, { useMemo } from 'react'

import CommonLabels from 'page/ConnectWithKTSP/FAQ/conponents/CommonLabels'

const filter = (data, tabsList) => {
    let result = {}
    tabsList.forEach((item) => {
        if (!result[item.name]) {
            result[item.name] = {
                tagName: item.name,
                faqList: [],
            }
        }
    })
    
    data.forEach((item) => {
        try {
            const faqData = result[item.tagName]
            faqData.faqList = faqData?.faqList.concat(item?.faqList)
        } catch (error) {}
    })

    return Object.values(result)
}
const TypeComponent = ({ data }) => {
    return data && data?.faqList.length > 0 ? (
        <CommonLabels isAll={true} items={data.faqList} title={data.tagName ?? ''} />
    ) : null
}

export default function All({ searchVal, items, tabsList }) {
    const data = useMemo(() => filter(items, tabsList), [items, tabsList])
    return (
        <div>
            {data.length > 0 &&
                data.map((item, index) => {
                    return <TypeComponent key={index} data={item} />
                })}
        </div>
    )
}
