import { useRef, useEffect, useState } from 'react'
import Content from 'components/Content'
import { useTranslation } from 'react-i18next'
import { useModalStore } from 'store/authAtom'
import tw from 'twin.macro'
import { cn } from 'utils/cn'

const Wrap = tw.div`p-5 mb-8 bg-primaryRed-lightRed space-y-2 max-w-[750px] text-secondary-midnightBlue`

export default function ExclusiveOffer({ title, about, text }) {
    const { t } = useTranslation()
    const descriptionRef = useRef(null)
    const displayDescriptionRef = useRef(null)
    const { setModalComponent, onOpen } = useModalStore()
    const [isMoreThan, setIsMoreThan] = useState(false)
    const checkDescriptionHeight = () => {
        if (descriptionRef.current) {
            descriptionRef.current.style.display = 'block'
            const descriptionHeight = descriptionRef.current.offsetHeight
            const lineHeight = parseInt(window.getComputedStyle(descriptionRef.current).lineHeight, 10)
            const maxLines = 2
            setIsMoreThan(descriptionHeight > lineHeight * maxLines)
            displayDescriptionRef.current.style.height = lineHeight * maxLines + 'px'
            descriptionRef.current.style.display = 'none'
        }
    }

    useEffect(() => {
        checkDescriptionHeight()
        window.addEventListener('resize', checkDescriptionHeight)
        return () => {
            window.removeEventListener('resize', checkDescriptionHeight)
        }
    }, [about])
    return (
        <Wrap>
            {title && <div className='text-base font-boldFamily break-words'> {title}</div>}
            {about && (
                <>
                    <Content
                        ref={displayDescriptionRef}
                        data={about}
                        className='text-sm font-normal line-clamp-2 overflow-hidden'
                    />
                    <Content ref={descriptionRef} data={about} className='text-sm font-normal' />
                </>
            )}
            {isMoreThan && (
                <div
                    onClick={() => {
                        setModalComponent(
                            <div>
                                {title && <div className='text-h4 pb-4 break-words'>{title}</div>}
                                {about && <Content data={about} className={cn('text-sm font-normal')} />}
                            </div>,
                        )
                        onOpen()
                    }}
                    className='text-sm font-semiboldFamily underline cursor-pointer'
                >
                    {text ?? t('landing.seeMore')}
                </div>
            )}
        </Wrap>
    )
}
