import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useTranslation } from 'react-i18next'
import { filterName } from 'utils/filter'

function CheckBoxFiled({ className, label, typesSelected, setTypesSelected, isModal, isNoFilter }) {
    const [checked, setChecked] = useState(
        isNoFilter ? typesSelected.some((obj) => obj === label) : typesSelected.some((obj) => obj.id === label.id),
    )
    const {
        i18n: { language },
    } = useTranslation()
    useEffect(() => {
        setChecked(
            isNoFilter ? typesSelected.some((obj) => obj === label) : typesSelected.some((obj) => obj.id === label.id),
        )
    }, [typesSelected])
    return (
        <Box
            className={className}
            sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
        >
            <FormControlLabel
                labelPlacement={isModal ? 'end' : 'start'}
                sx={{
                    width: '100%',
                    py: 0,
                    justifyContent: isModal ? 'flex-start' : 'space-between',
                    marginLeft: 0,
                }}
                label={
                    <Box
                        className='font-semiboldFamily'
                        sx={{
                            fontSize: 14,
                            fontWeight: 500,
                            marginLeft: isModal ? 1 : 0,
                            overflowWrap: 'break-word',
                            whiteSpace: 'normal',
                            pr: 1,
                            maxWidth: isModal ? { xs: '100px', md: '160px' } : '230px',
                        }}
                    >
                        {isNoFilter ? label : filterName(label, language)}
                    </Box>
                }
                control={
                    <div>
                        <Checkbox
                            color='secondary'
                            checked={checked}
                            sx={{}}
                            onChange={() => {
                                if (!checked) {
                                    setTypesSelected([...typesSelected, label])
                                } else {
                                    const arr = typesSelected.filter((val) => val !== label)
                                    setTypesSelected(arr)
                                }
                                setChecked((checked) => !checked)
                            }}
                        />
                    </div>
                }
            />
        </Box>
    )
}

export default CheckBoxFiled
